import {
  FormField,
  Grid,
  Input,
} from '@cloudscape-design/components';
import { PropTypes } from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  usePLCReady,
  usePLCValue,
  usePLCWrite,
} from '../../../features/plc/context';

function PLCTimeInput({ label, handle, secondsOnly = false }) {
  const ready = usePLCReady();

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const initialTime = usePLCValue(handle);
  const [initialMinutes, initialSeconds] = useMemo(() => {
    if (!initialTime) {
      return [0, 0];
    }

    if (secondsOnly) {
      return [0, (initialTime / 1000)];
    }

    const initialSek = (initialTime / 1000) % 60;
    const initialMin = Math.floor((initialTime / 60000) % 60);
    const initialHour = Math.floor((initialTime / 3600000) % 24);
    const initialDay = Math.floor((initialTime / 86400000) % 365);

    return [initialMin + initialHour * 60 + initialDay * 1440, initialSek];
  }, [initialTime]);
  useEffect(() => {
    setMinutes(initialMinutes);
    setSeconds(initialSeconds);
  }, [initialMinutes, initialSeconds]);

  const write = usePLCWrite();
  const updateTime = useCallback((newMinutes, newSeconds) => {
    const newTime = (newMinutes * 60 + newSeconds) * 1000;
    write({ handle, value: newTime });
  }, [write, handle]);

  return (
    <Grid gridDefinition={secondsOnly ? [{ colspan: 12 }] : [{ colspan: 6 }, { colspan: 6 }]}>
      {!secondsOnly ? (
        <FormField
          label={label}
          description="Minutes"
          errorText={minutes !== initialMinutes ? 'Unsaved changes (click away to save)' : null}
        >
          <Input
            step={1}
            inputMode="numeric"
            type="number"
            value={minutes}
            onChange={({ detail }) => { setMinutes(detail.value); }}
            onBlur={() => { updateTime(minutes, seconds); }}
            disabled={!ready}
          />
        </FormField>
      ) : null}
      <FormField
        label={secondsOnly ? label : <br />}
        description="Seconds"
        errorText={seconds !== initialSeconds ? 'Unsaved changes (click away to save)' : null}
      >
        <Input
          step={1}
          inputMode="numeric"
          type="number"
          value={seconds}
          onChange={({ detail }) => { setSeconds(parseFloat(detail.value)); }}
          onBlur={() => { updateTime(minutes, seconds); }}
          disabled={!ready}
        />
      </FormField>
    </Grid>
  );
}

PLCTimeInput.propTypes = {
  label: PropTypes.string.isRequired,
  handle: PropTypes.string.isRequired,
  secondsOnly: PropTypes.bool,
};

export default PLCTimeInput;
