import {
  Alert,
  Button,
  ContentLayout,
  Grid,
  Header,
  SpaceBetween,
  Table,
} from '@cloudscape-design/components';
import {
  doc,
  getFirestore,
  updateDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import {
  BASE_BREAKPOINTS,
  BASE_LEADTIMES,
  BASE_MATERIALS,
  BreakpointsTable,
  EditPricingTable,
  LeadtimesTable,
  MaterialsTable,
  usePricingSchema,
} from '../../components/Pricing';
import { usePartAndQuote } from '../../features/firebase';
import QuoteValidation from './quoteValidation';

function EditPricingPage() {
  const { partId } = useParams();
  const navigate = useNavigate();
  const { part, quote, status } = usePartAndQuote(partId);
  const { pricingSchema } = usePricingSchema();
  const { id: quoteId } = quote;
  const { material: originalMaterial } = part || {};
  const { attributesVersion, values: pricingValues } = quote?.pricingV3 || {};

  const [submittingError, setSubmittingError] = useState();

  const initialValues = useMemo(() => ({
    attributes: pricingValues?.attributes || {},
    notes: pricingValues?.notes || '',
    materials: pricingValues?.materials || BASE_MATERIALS.map((material) => ({
      ...material,
      isDefault: originalMaterial === material.id,
      cappingStyle: material.cappingStyle.map((cappingStyle) => ({
        ...cappingStyle,
        isDefault: (material.id === 'cop' ? (cappingStyle.id === '175um-coc-x1') : true),
      })),
    })),
    breakpoints: pricingValues?.breakpoints || BASE_BREAKPOINTS,
    leadtimes: pricingValues?.leadtimes || BASE_LEADTIMES.map((leadtime) => ({
      id: leadtime.id,
      multiplier: leadtime.multiplier,
      leadtimeDays: leadtime.leadtimeDays,
      minQuantity: leadtime.minQuantity,
      maxQuantity: leadtime.maxQuantity,
    })),
    multiplier: pricingValues?.multiplier || 1.0,
  }), [pricingValues, originalMaterial]);

  const validationSchema = useMemo(() => Yup.object().shape(pricingSchema), [pricingSchema]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmittingError(null);
        const castValues = validationSchema.cast(values);
        const quoteRef = doc(getFirestore(), 'quotes', quoteId);
        try {
          await updateDoc(quoteRef, { 'pricingV3.values': castValues });
          navigate(`/part/${partId}`);
        } catch (e) {
          setSubmittingError(e.message);
        }
        setSubmitting(false);
      }}
    >
      {({
        handleSubmit, isSubmitting, touched,
      }) => (
        <ContentLayout
          header={(
            <Header
              variant="h1"
              description="Pricing"
              actions={(
                <Button
                  variant="normal"
                  onClick={handleSubmit}
                  loading={isSubmitting}
                  disabled={(
                    !touched.attributes
                      && !touched.multiplier
                      && !touched.notes
                      && !touched.materials
                      && !touched.breakpoints
                      && !touched.leadtimes
                  ) || isSubmitting}
                >
                  Save
                </Button>
                  )}
            >
              {`Quote #${partId.substring(0, 6).toUpperCase()}`}
            </Header>
              )}
        >
          {status === 'success' && <QuoteValidation clonedQuote={cloneDeep(quote)} validationSchema={validationSchema} />}
          <SpaceBetween size="xl">
            {submittingError ? (
              <Alert type="error">{submittingError}</Alert>
            ) : null}
            <Grid gridDefinition={[
              { colspan: 12 },
              { colspan: 6 }, { colspan: 6 },
              { colspan: 12 },
            ]}
            >
              {!attributesVersion ? (
                <Table
                  loading
                  loadingText="Loading part info..."
                  columnDefinitions={[
                    {
                      id: 'title',
                      header: 'Attribute',
                      width: '40%',
                    },
                    {
                      id: 'value',
                      header: 'Value',
                      width: '30%',
                      editConfig: {},
                    },
                    {
                      id: 'setup',
                      header: 'Setup cost',
                      width: '20%',
                      editConfig: {},
                    },
                    {
                      id: 'unit',
                      header: 'Base unit cost',
                      width: '20%',
                      editConfig: {},
                    },
                  ]}
                />
              ) : (
                <EditPricingTable attributesVersion={attributesVersion} loading={status === 'loading'} />
              )}
              <MaterialsTable loading={status === 'loading'} />
              <BreakpointsTable loading={status === 'loading'} />
              <LeadtimesTable loading={status === 'loading'} />
            </Grid>
          </SpaceBetween>
        </ContentLayout>
      )}
    </Formik>
  );
}

export default EditPricingPage;
