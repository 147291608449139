import { Header } from '@cloudscape-design/components';
import {
  collection,
  getFirestore,
  limit,
  orderBy,
  query,
} from 'firebase/firestore';
import React, { useMemo } from 'react';

import PartsList from '../../components/PartsList';

function PartsListPage() {
  const recentPartsQuery = useMemo(
    () => query(collection(getFirestore(), 'parts'), orderBy('uploadedAt', 'desc'), limit(100)),
    [],
  );

  return (
    <PartsList
      header={<Header variant="awsui-h1-sticky">Parts</Header>}
      variant="full-page"
      partsQuery={recentPartsQuery}
    />
  );
}

export default PartsListPage;
