import {
  ColumnLayout,
  Container,
  ContentLayout,
  ExpandableSection,
  Grid,
  Header,
  SpaceBetween,
} from '@cloudscape-design/components';
import React, { useMemo } from 'react';

import HeaterStatus from '../../../components/HMI/components/heaterStatus';
import HeatPipeStatus from '../../../components/HMI/components/heatPipeStatus';
import HFTS from '../../../components/HMI/components/hfts';
import OverrideControls from '../../../components/HMI/components/overrideControls';
import PIDValues from '../../../components/HMI/components/pidControls';
import PLCError from '../../../components/HMI/components/plcError';
import Warnings from '../../../components/HMI/components/warnings';
import AutomationControls from '../../../components/HMI/Forming/automation';
import AutomationStage from '../../../components/HMI/Forming/automationStage';
import SystemStatus from '../../../components/HMI/Forming/systemStatus';
import { usePLCAddress, usePressHandle, useProgramHandle } from '../../../components/HMI/hooks/context';
import PLCContextProvider from '../../../features/plc/context';

const getHandles = (pressHandle, programHandle) => [
  { name: `MAIN.${pressHandle}_Mode`, type: 'INT' },

  { name: `${programHandle}.InstronAudio`, type: 'BOOL' },
  { name: `${programHandle}.AirStatus`, type: 'BOOL' },
  { name: `${programHandle}.ChillerStatus`, type: 'BOOL' },
  { name: `${programHandle}.bCheckThermocouple`, type: 'BOOL' },

  { name: `${programHandle}.UpperHotHeater`, type: 'BOOL' },
  { name: `${programHandle}.UpperFrontHeater`, type: 'BOOL' },
  { name: `${programHandle}.UpperRearHeater`, type: 'BOOL' },
  { name: `${programHandle}.LowerHotHeater`, type: 'BOOL' },
  { name: `${programHandle}.LowerFrontHeater`, type: 'BOOL' },
  { name: `${programHandle}.LowerRearHeater`, type: 'BOOL' },
  { name: `${programHandle}.Strain`, type: 'BOOL' },
  { name: `${programHandle}.Vacuum`, type: 'BOOL' },
  { name: `${programHandle}.bInstronManualOverride`, type: 'BOOL' },

  { name: `${programHandle}.fbLowerFormingHFTS.bTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbLowerFormingHFTS.bHP1TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbLowerFormingHFTS.bHP2TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbLowerFormingHFTS.bHP3TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbLowerFormingHFTS.bHP4TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbLowerFormingHFTS.bHP5TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbLowerFormingHFTS.bHP6TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbLowerFormingHFTS.bToolTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbLowerFormingHFTS.bHotBlockTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbLowerFormingHFTS.bFrontBlockTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbLowerFormingHFTS.bRearBlockTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbUpperFormingHFTS.bTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbUpperFormingHFTS.bHP1TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbUpperFormingHFTS.bHP2TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbUpperFormingHFTS.bHP3TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbUpperFormingHFTS.bHP4TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbUpperFormingHFTS.bHP5TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbUpperFormingHFTS.bHP6TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbUpperFormingHFTS.bToolTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbUpperFormingHFTS.bHotBlockTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbUpperFormingHFTS.bFrontBlockTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbUpperFormingHFTS.bRearBlockTooHot`, type: 'BOOL' },

  // safety values
  { name: `${programHandle}.fbLowerFormingHFTS.fToolTempMax`, type: 'LREAL' },
  { name: `${programHandle}.fbLowerFormingHFTS.fHotBlockTempShutoff`, type: 'LREAL' },
  { name: `${programHandle}.fbLowerFormingHFTS.fHotBlockTempMax`, type: 'LREAL' },
  { name: `${programHandle}.fbLowerFormingHFTS.fFrontBlockTempShutoff`, type: 'LREAL' },
  { name: `${programHandle}.fbLowerFormingHFTS.fFrontBlockTempMax`, type: 'LREAL' },
  { name: `${programHandle}.fbLowerFormingHFTS.fRearBlockTempShutoff`, type: 'LREAL' },
  { name: `${programHandle}.fbLowerFormingHFTS.fRearBlockTempMax`, type: 'LREAL' },
  { name: `${programHandle}.fbUpperFormingHFTS.fToolTempMax`, type: 'LREAL' },
  { name: `${programHandle}.fbUpperFormingHFTS.fHotBlockTempShutoff`, type: 'LREAL' },
  { name: `${programHandle}.fbUpperFormingHFTS.fHotBlockTempMax`, type: 'LREAL' },
  { name: `${programHandle}.fbUpperFormingHFTS.fFrontBlockTempShutoff`, type: 'LREAL' },
  { name: `${programHandle}.fbUpperFormingHFTS.fFrontBlockTempMax`, type: 'LREAL' },
  { name: `${programHandle}.fbUpperFormingHFTS.fRearBlockTempShutoff`, type: 'LREAL' },
  { name: `${programHandle}.fbUpperFormingHFTS.fRearBlockTempMax`, type: 'LREAL' },

  { name: `${programHandle}.UpperHotCylinder`, type: 'BOOL' },
  { name: `${programHandle}.UpperColdCylinder`, type: 'BOOL' },
  { name: `${programHandle}.UpperFrontCylinder`, type: 'BOOL' },
  { name: `${programHandle}.UpperRearCylinder`, type: 'BOOL' },
  { name: `${programHandle}.LowerHotCylinder`, type: 'BOOL' },
  { name: `${programHandle}.LowerColdCylinder`, type: 'BOOL' },
  { name: `${programHandle}.LowerFrontCylinder`, type: 'BOOL' },
  { name: `${programHandle}.LowerRearCylinder`, type: 'BOOL' },

  { name: `${programHandle}.UpperToolRTD`, type: 'LREAL' },
  { name: `${programHandle}.UpperHotRTD`, type: 'LREAL' },
  { name: `${programHandle}.UpperFrontRTD`, type: 'LREAL' },
  { name: `${programHandle}.UpperRearRTD`, type: 'LREAL' },
  { name: `${programHandle}.LowerToolRTD`, type: 'LREAL' },
  { name: `${programHandle}.LowerHotRTD`, type: 'LREAL' },
  { name: `${programHandle}.LowerFrontRTD`, type: 'LREAL' },
  { name: `${programHandle}.LowerRearRTD`, type: 'LREAL' },

  { name: `${programHandle}.LowerHP1`, type: 'LREAL' },
  { name: `${programHandle}.LowerHP2`, type: 'LREAL' },
  { name: `${programHandle}.LowerHP3`, type: 'LREAL' },
  { name: `${programHandle}.LowerHP4`, type: 'LREAL' },
  { name: `${programHandle}.LowerHP5`, type: 'LREAL' },
  { name: `${programHandle}.LowerHP6`, type: 'LREAL' },
  { name: `${programHandle}.UpperHP1`, type: 'LREAL' },
  { name: `${programHandle}.UpperHP2`, type: 'LREAL' },
  { name: `${programHandle}.UpperHP3`, type: 'LREAL' },
  { name: `${programHandle}.UpperHP4`, type: 'LREAL' },
  { name: `${programHandle}.UpperHP5`, type: 'LREAL' },
  { name: `${programHandle}.UpperHP6`, type: 'LREAL' },

  { name: `${programHandle}.bProcessAutomationEnabled`, type: 'BOOL' },
  { name: `${programHandle}.iProcessState`, type: 'INT' },
  { name: `${programHandle}.sFirebaseSessionID`, type: 'STRING', size: 20 },
  { name: `${programHandle}.bShowObservationModal`, type: 'BOOL' },
  { name: `${programHandle}.bShowObservationModal`, type: 'BOOL' },

  { name: `${programHandle}.bLowerHotBlockHeaterSystemOn`, type: 'BOOL' },
  { name: `${programHandle}.bLowerRearHeaterSystemOn`, type: 'BOOL' },
  { name: `${programHandle}.bLowerFrontHeaterSystemOn`, type: 'BOOL' },

  { name: `${programHandle}.fbLowerFormingHFTS.fHotBlockSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbLowerFormingHFTS.fRearHeaterEngagedSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbLowerFormingHFTS.fRearHeaterDisengagedSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbLowerFormingHFTS.fFrontHeaterDisengagedSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbLowerFormingHFTS.fFrontHeaterEngagedSetpoint`, type: 'LREAL' },

  { name: `${programHandle}.bUpperHotBlockHeaterSystemOn`, type: 'BOOL' },
  { name: `${programHandle}.bUpperRearHeaterSystemOn`, type: 'BOOL' },
  { name: `${programHandle}.bUpperFrontHeaterSystemOn`, type: 'BOOL' },

  { name: `${programHandle}.fbUpperFormingHFTS.fHotBlockSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbUpperFormingHFTS.fRearHeaterEngagedSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbUpperFormingHFTS.fRearHeaterDisengagedSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbUpperFormingHFTS.fFrontHeaterDisengagedSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbUpperFormingHFTS.fFrontHeaterEngagedSetpoint`, type: 'LREAL' },

  { name: `${programHandle}.InstronAudio`, type: 'BOOL' },

  { name: `${programHandle}.fbLowerFormingHFTS.fSetpointLowerBand`, type: 'LREAL' },
  { name: `${programHandle}.fbLowerFormingHFTS.fSetpointUpperBand`, type: 'LREAL' },
  { name: `${programHandle}.fbLowerFormingHFTS.fStandbyLowerBand`, type: 'LREAL' },
  { name: `${programHandle}.fbLowerFormingHFTS.fStandbyUpperBand`, type: 'LREAL' },
  { name: `${programHandle}.fbUpperFormingHFTS.fSetpointLowerBand`, type: 'LREAL' },
  { name: `${programHandle}.fbUpperFormingHFTS.fSetpointUpperBand`, type: 'LREAL' },
  { name: `${programHandle}.fbUpperFormingHFTS.fStandbyLowerBand`, type: 'LREAL' },
  { name: `${programHandle}.fbUpperFormingHFTS.fStandbyUpperBand`, type: 'LREAL' },

  { name: `${programHandle}.fbLowerFormingHFTS.eAutomationState`, type: 'INT' },
  { name: `${programHandle}.fbUpperFormingHFTS.eAutomationState`, type: 'INT' },
  { name: `${programHandle}.bOverrideCylinders`, type: 'BOOL' },
  { name: `${programHandle}.bAutoCool`, type: 'BOOL' },

  { name: `${programHandle}.tPresoakDelayTime`, type: 'DWORD' },
  { name: `${programHandle}.tPresoakDelayTimer.ET`, type: 'DWORD' },
  { name: `${programHandle}.tCoolsoakDelayTime`, type: 'DWORD' },
  { name: `${programHandle}.tCoolsoakDelayTimer.ET`, type: 'DWORD' },

  // PID configurations
  { name: `${programHandle}.stLowerFrontHeaterEngagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stLowerFrontHeaterEngagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stLowerFrontHeaterEngagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stLowerFrontHeaterEngagedParaControllerExternal.TdHeat`, type: 'DWORD' },
  { name: `${programHandle}.stUpperFrontHeaterEngagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stUpperFrontHeaterEngagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stUpperFrontHeaterEngagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stUpperFrontHeaterEngagedParaControllerExternal.TdHeat`, type: 'DWORD' },

  { name: `${programHandle}.stLowerRearHeaterEngagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stLowerRearHeaterEngagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stLowerRearHeaterEngagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stLowerRearHeaterEngagedParaControllerExternal.TdHeat`, type: 'DWORD' },
  { name: `${programHandle}.stUpperRearHeaterEngagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stUpperRearHeaterEngagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stUpperRearHeaterEngagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stUpperRearHeaterEngagedParaControllerExternal.TdHeat`, type: 'DWORD' },

  { name: `${programHandle}.stLowerFrontHeaterDisengagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stLowerFrontHeaterDisengagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stLowerFrontHeaterDisengagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stLowerFrontHeaterDisengagedParaControllerExternal.TdHeat`, type: 'DWORD' },
  { name: `${programHandle}.stUpperFrontHeaterDisengagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stUpperFrontHeaterDisengagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stUpperFrontHeaterDisengagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stUpperFrontHeaterDisengagedParaControllerExternal.TdHeat`, type: 'DWORD' },

  { name: `${programHandle}.stLowerRearHeaterDisengagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stLowerRearHeaterDisengagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stLowerRearHeaterDisengagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stLowerRearHeaterDisengagedParaControllerExternal.TdHeat`, type: 'DWORD' },
  { name: `${programHandle}.stUpperRearHeaterDisengagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stUpperRearHeaterDisengagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stUpperRearHeaterDisengagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stUpperRearHeaterDisengagedParaControllerExternal.TdHeat`, type: 'DWORD' },

  // Shutoff timer
  { name: `${programHandle}.tShutdownTimer.ET`, type: 'DWORD' },
  { name: `${programHandle}.tShutdownTimer.PT`, type: 'DWORD' },

  { name: `${programHandle}.bAutomateVacuum`, type: 'BOOL' },
  { name: `${programHandle}.Vacuum`, type: 'BOOL' },
  { name: `${programHandle}.bAutomateIonizer`, type: 'BOOL' },
  { name: `${programHandle}.Ionizer`, type: 'BOOL' },
];

function FormingHMI() {
  const pressHandle = usePressHandle();
  const programHandle = useProgramHandle();
  const plcAddress = usePLCAddress(pressHandle);

  const handles = useMemo(
    () => getHandles(pressHandle, programHandle),
    [pressHandle, programHandle],
  );

  return (
    <PLCContextProvider url={plcAddress} handles={handles} test={process.env.REACT_APP_ENV !== 'production'}>
      <Warnings />
      <PLCError />
      <ContentLayout header={<AutomationStage />}>
        <SpaceBetween direction="vertical" size="xl">
          <Grid gridDefinition={[
            { colspan: 12 },
            { colspan: 8 }, { colspan: 4 },
          ]}
          >
            <AutomationControls />
            <Container header={<Header>HFTS heater blocks</Header>} fitHeight>
              <SpaceBetween size="xs">
                <ColumnLayout columns={3}>
                  <HeaterStatus sideHandle="Upper" heaterHandle="Hot" />
                  <HeaterStatus sideHandle="Upper" heaterHandle="Front" />
                  <HeaterStatus sideHandle="Upper" heaterHandle="Rear" />
                </ColumnLayout>
                <HeatPipeStatus sideHandle="Upper" count={6} />
                <ColumnLayout columns={3}>
                  <HeaterStatus sideHandle="Lower" heaterHandle="Hot" />
                  <HeaterStatus sideHandle="Lower" heaterHandle="Front" />
                  <HeaterStatus sideHandle="Lower" heaterHandle="Rear" />
                </ColumnLayout>
                <HeatPipeStatus sideHandle="Lower" count={6} />
              </SpaceBetween>
            </Container>
            <SystemStatus />
          </Grid>
          <Container header={<Header>Additional settings</Header>}>
            <SpaceBetween direction="vertical" size="xl">
              <ExpandableSection headerText="Override controls">
                <OverrideControls side1="Upper" side2="Lower" />
              </ExpandableSection>
              <ExpandableSection headerText="PID controls">
                <Grid gridDefinition={[
                  { colspan: 6 }, { colspan: 6 },
                  { colspan: 6 }, { colspan: 6 },
                  { colspan: 6 }, { colspan: 6 },
                  { colspan: 6 }, { colspan: 6 },
                ]}
                >
                  <PIDValues sideHandle="Lower" heaterHandle="Front" engaged />
                  <PIDValues sideHandle="Upper" heaterHandle="Front" engaged />
                  <PIDValues sideHandle="Lower" heaterHandle="Rear" engaged />
                  <PIDValues sideHandle="Upper" heaterHandle="Rear" engaged />
                  <PIDValues sideHandle="Lower" heaterHandle="Front" engaged={false} />
                  <PIDValues sideHandle="Upper" heaterHandle="Front" engaged={false} />
                  <PIDValues sideHandle="Lower" heaterHandle="Rear" engaged={false} />
                  <PIDValues sideHandle="Upper" heaterHandle="Rear" engaged={false} />
                </Grid>
              </ExpandableSection>
              <ExpandableSection headerText="Upper Controls">
                <HFTS side="Upper" />
              </ExpandableSection>
              <ExpandableSection headerText="Lower Controls">
                <HFTS side="Lower" />
              </ExpandableSection>
            </SpaceBetween>
          </Container>
        </SpaceBetween>
      </ContentLayout>
    </PLCContextProvider>
  );
}

export default FormingHMI;
