import {
  Flashbar,
} from '@cloudscape-design/components';
import { Html5QrcodeScanner } from 'html5-qrcode';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props) => {
  const config = {};
  if (props.fps) {
    config.fps = props.fps;
  }
  if (props.qrbox) {
    config.qrbox = props.qrbox;
  }
  if (props.aspectRatio) {
    config.aspectRatio = props.aspectRatio;
  }
  if (props.disableFlip !== null) {
    config.disableFlip = props.disableFlip;
  }
  return config;
};

function QRCodeScanner({
  id,
  qrCodeSuccessCallback,
  qrCodeErrorCallback = null,
  verbose = false,
  fps = null,
  qrbox = null,
  aspectRatio = null,
  disableFlip = null,
}) {
  const ref = useRef(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Suceess callback is required.
    if (!qrCodeSuccessCallback) {
      setError('qrCodeSuccessCallback is required callback.');
    }
    // when component mounts
    // use ref as a workaround for Video duplication on page when using react strict mode
    // https://github.com/mebjas/html5-qrcode/issues/500#issuecomment-1712304668
    if (ref.current === null) {
      const config = createConfig({
        fps, qrbox, aspectRatio, disableFlip,
      });
      ref.current = new Html5QrcodeScanner(id, config, verbose === true);
    }
    const html5QrcodeScanner = ref.current;
    // Timeout to allow the clean-up function to finish in case of double render.
    setTimeout(() => {
      const container = document.getElementById(id);
      if (html5QrcodeScanner && container?.innerHTML === '') {
        html5QrcodeScanner.render(qrCodeSuccessCallback, qrCodeErrorCallback);
      }
    }, 0);

    // cleanup function when component will unmount
    return () => {
      if (html5QrcodeScanner) {
        html5QrcodeScanner.clear().catch((err) => {
          console.error('Failed to clear html5QrcodeScanner. ', err);
        });
      }
    };
  }, [
    aspectRatio, disableFlip, fps, id, qrCodeErrorCallback, qrCodeSuccessCallback, qrbox, verbose,
  ]);

  if (error) {
    return (
      <Flashbar
        items={[{
          header: `Could not load QR scanner: ${error}`,
          dismissible: false,
          type: 'error',
        }]}
      />
    );
  }

  return (
    <div id={id} />
  );
}

QRCodeScanner.propTypes = {
  id: PropTypes.string.isRequired,
  qrCodeSuccessCallback: PropTypes.func.isRequired,
  qrCodeErrorCallback: PropTypes.func,
  verbose: PropTypes.bool,
  fps: PropTypes.number,
  qrbox: PropTypes.number,
  aspectRatio: PropTypes.number,
  disableFlip: PropTypes.bool,
};

export default QRCodeScanner;
