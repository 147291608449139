import PropTypes from 'prop-types';
import React from 'react';

import { usePLCReady, usePLCValue } from '../../../features/plc/context';

function PLCValue({
  handle,
  style = {},
  format = (value) => value,
}) {
  const ready = usePLCReady();
  const value = usePLCValue(handle);
  if (!ready) {
    return <span style={style}>Connecting...</span>;
  }
  return <span style={style}>{format(value)}</span>;
}

PLCValue.propTypes = {
  handle: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  format: PropTypes.func,
};

export default PLCValue;
