import {
  Button,
  Container,
  ContentLayout,
  Grid,
  Header,
} from '@cloudscape-design/components';
import {
  collection,
  getFirestore,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFirestoreCollectionData } from 'reactfire';

import { ActiveOrdersSummary } from '../../components/ActiveOrders';
import {
  OrderCalendar,
  OrderList,
} from '../../components/Orders';
import OrderDetailProvider from '../../components/Orders/orderDetailProvider';
import PartsList from '../../components/PartsList';
import OrderStatus from '../../constants/orderStatus';

function Home() {
  const recentPartsQuery = useMemo(
    () => query(collection(getFirestore(), 'parts'), orderBy('uploadedAt', 'desc'), limit(10)),
    [],
  );

  const recentOrdersQuery = useMemo(() => query(
    collection(getFirestore(), 'orders'),
    orderBy('orderDate', 'desc'),
    limit(10),
  ), []);

  const { data: activeOrders } = useFirestoreCollectionData(query(
    collection(getFirestore(), 'orders'),
    where('status', '==', OrderStatus.IN_PROGRESS),
  ));
  const hasActiveOrders = useMemo(() => {
    if (activeOrders && activeOrders.length > 0) {
      return true;
    }
    return false;
  }, [activeOrders]);

  const navigate = useNavigate();

  return (
    <OrderDetailProvider>
      <ContentLayout>
        <Grid gridDefinition={[
          ...(hasActiveOrders
            ? [{ colspan: { s: 8, default: 12 } }, { colspan: { s: 4, default: 12 } }]
            : [{ colspan: 12 }]
          ),
          ...[{ colspan: { s: 6, default: 12 } }, { colspan: { s: 6, default: 12 } }],
        ]}
        >
          <Container
            fitHeight
            header={<Header>Upcoming ship dates</Header>}
          >
            <OrderCalendar />
          </Container>
          {hasActiveOrders ? (
            <ActiveOrdersSummary />
          ) : null}
          <Container
            fitHeight
            header={(
              <Header actions={<Button variant="normal" onClick={() => { navigate('/orders'); }}>See all orders</Button>}>
                Orders
              </Header>
            )}
          >
            <OrderList
              variant="embedded"
              ordersQuery={recentOrdersQuery}
            />
          </Container>
          <Container
            fitHeight
            header={(
              <Header actions={<Button variant="normal" onClick={() => { navigate('/parts'); }}>See all parts</Button>}>
                Recent uploads
              </Header>
            )}
          >
            <PartsList
              variant="embedded"
              partsQuery={recentPartsQuery}
            />
          </Container>
        </Grid>
      </ContentLayout>
    </OrderDetailProvider>
  );
}

export default Home;
