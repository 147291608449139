import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo } from 'react';

const usePLCAddress = (pressName) => useMemo(() => ({
  press1: 'https://cx-505ad0.live.parallelfluidics.com/tcadswebservice/tcadswebservice.dll',
  press2: 'https://cx-505ad0.live.parallelfluidics.com/tcadswebservice/tcadswebservice.dll',
  press3: 'https://cx-729f70.live.parallelfluidics.com/tcadswebservice/tcadswebservice.dll',
  press4: 'https://cx-729f70.live.parallelfluidics.com/tcadswebservice/tcadswebservice.dll',
}[pressName.toLowerCase()]), [pressName]);

const handlify = (str) => {
  const handle = {
    capside: 'CapSide',
    chipside: 'ChipSide',
  }[str.toLowerCase()];
  if (handle) {
    return handle;
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const titleify = (str) => {
  const title = {
    capside: 'Cap Side',
    chipside: 'Chip Side',
  }[str.toLowerCase()];
  if (title) {
    return title;
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const HMIContext = createContext({});

function HMIContextProvider({ children, press, process }) {
  const pressHandle = useMemo(() => handlify(press), [press]);
  const processHandle = useMemo(() => handlify(process), [process]);
  const programHandle = useMemo(() => `MAIN.FB_${pressHandle}_${processHandle}`, [pressHandle, processHandle]);
  const pressTitle = useMemo(() => ({
    press1: 'Press 1',
    press2: 'Press 2',
    press3: 'Press 3',
    press4: 'Press 4',
  }[press]), [press]);

  const value = useMemo(() => ({
    pressHandle,
    processHandle,
    programHandle,
    pressTitle,
  }), [pressHandle, processHandle, programHandle, pressTitle]);

  return (
    <HMIContext.Provider value={value}>
      {children}
    </HMIContext.Provider>
  );
}

HMIContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  press: PropTypes.string.isRequired,
  process: PropTypes.string.isRequired,
};

const usePressHandle = () => {
  const { pressHandle } = useContext(HMIContext);
  return pressHandle;
};

const usePressTitle = () => {
  const { pressTitle } = useContext(HMIContext);
  return pressTitle;
};

const useProcessHandle = () => {
  const { processHandle } = useContext(HMIContext);
  return processHandle;
};

const useProgramHandle = () => {
  const { programHandle } = useContext(HMIContext);
  return programHandle;
};

const useHFTSHandle = (side) => {
  const sideHandle = useMemo(() => handlify(side), [side]);
  const { programHandle, processHandle } = useContext(HMIContext);
  const hftsHandle = useMemo(() => `${programHandle}.fb${sideHandle}${processHandle}HFTS`, [programHandle, sideHandle]);
  return hftsHandle;
};

export default HMIContextProvider;

export {
  handlify,
  titleify,
  usePressHandle,
  usePressTitle,
  useProcessHandle,
  useProgramHandle,
  useHFTSHandle,
  usePLCAddress,
};
