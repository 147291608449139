const StepTypes = Object.freeze({
  MOLDING: 'Molding',
  POSTMACHINING: 'Postmachining',
  BONDING: 'Bonding',
  CONNECTORIZING: 'Connectorizing',
});

const InspectionValues = Object.freeze({
  NOT_INSPECTED: 'Not inspected',
  PASSED: 'Passed',
  REJECT_MAJOR: 'Reject: Major',
  REJECT_COSMETIC: 'Reject: Cosmetic',
  TUNING: 'Tuning',
});

const FailureTags = Object.freeze({
  VOID_ON: 'Void on channel',
  VOID_NEAR: 'Void near channel',
  COSMETIC_VOID: 'Cosmetic voids that are too high in number',
  FIBER: 'Fiber',
  UNDER_FILLED: 'Under Filled',
  METAL_C: 'Metal contamination',
  UNKNOWN_C: 'Unknown contamination',
  STICKING: 'Sticking',
  OTHER: 'Other',
});

const ProcessNames = Object.freeze({
  FORMING: 'forming',
  BONDING: 'bonding',
});

const ProcessStepMap = Object.freeze({
  [ProcessNames.FORMING]: StepTypes.MOLDING,
  [ProcessNames.BONDING]: StepTypes.BONDING,
});

const StepProcessMap = Object.freeze({
  [StepTypes.MOLDING]: ProcessNames.FORMING,
  [StepTypes.BONDING]: ProcessNames.BONDING,
});

const Materials = Object.freeze([
  'COP',
  'PMMA',
  'PC',
]);

module.exports = {
  StepTypes,
  InspectionValues,
  FailureTags,
  ProcessNames,
  ProcessStepMap,
  StepProcessMap,
  Materials,
};
