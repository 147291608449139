import './dark-theme-override.scss';

import { applyTheme } from '@cloudscape-design/components/theming';
import { applyMode, Mode } from '@cloudscape-design/global-styles';
import PropTypes from 'prop-types';
import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';

const ThemeContext = createContext();

const theme = {
  tokens: {
    fontFamilyBase: 'Roboto',
    colorTextAccent: '#4FAFC3',
  },
  contexts: {
    'top-navigation': {
      tokens: {
        colorBackgroundContainerContent: '#000000',
        colorTextAccent: '#4FAFC3',
      },
    },
    header: {
      tokens: {
        colorBackgroundLayoutMain: '#000000',
      },
    },
  },
};
applyTheme({ theme });

export default function ThemeProvider({ children }) {
  const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('theme') === Mode.Dark);

  useEffect(() => {
    if (isDarkMode) {
      applyMode(Mode.Dark);
      localStorage.setItem('theme', Mode.Dark);
    } else {
      applyMode(Mode.Light);
      localStorage.setItem('theme', Mode.Light);
    }
  }, [isDarkMode]);

  const value = useMemo(() => ({
    isDarkMode,
    setIsDarkMode,
  }), [isDarkMode]);

  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
