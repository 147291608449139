const calculate = ({ quote, options }) => {
  const {
    cappingStyle,
    quantity,
    delivery,
  } = options;

  const dynamicPrice = ({
    minPrice, maxPrice, minQty, maxQty, qty,
  }) => {
    const slope = (minPrice - maxPrice) / (maxQty - minQty);
    return maxPrice + (qty - minQty) * slope;
  };

  const {
    multiplier,
    leadtimeText,
    leadtimeDays,
    minQuantity,
    maxQuantity,
  } = (quote?.pricingV3?.values?.leadtimes || []).find((l) => l.id === delivery) || {};
  if (!multiplier || (!leadtimeText && !leadtimeDays)) {
    return {};
  }
  if (quantity > maxQuantity || quantity < minQuantity) {
    return {};
  }
  if (cappingStyle === 'placeholder-item') {
    return {};
  }

  const isCapped = !(cappingStyle === 'uncapped');
  const setupPrice = (isCapped
    ? quote.pricingV3.values.calculatedValues.cappedSetupPrice * multiplier
    : quote.pricingV3.values.calculatedValues.uncappedSetupPrice * multiplier
  );

  // make sure pricing tiers are sorted
  const pricingLevels = [
    ...quote.pricingV3.values.breakpoints,
  ].sort((a, b) => a.quantity - b.quantity);
  let baseUnitPrice;
  for (let i = 0; i < pricingLevels.length - 1; i += 1) {
    const lowerBound = pricingLevels[i];
    const upperBound = pricingLevels[i + 1];

    if (quantity >= lowerBound.quantity && quantity <= upperBound.quantity) {
      baseUnitPrice = dynamicPrice({
        maxPrice: lowerBound.multiplier,
        minPrice: upperBound.multiplier,
        minQty: lowerBound.quantity,
        maxQty: upperBound.quantity,
        qty: quantity,
      }) * (isCapped
        ? quote.pricingV3.values.calculatedValues.cappedUnitPrice
        : quote.pricingV3.values.calculatedValues.uncappedUnitPrice);
    }
  }

  const unitPrice = multiplier * baseUnitPrice + (isCapped
    ? quote.pricingV3.values.calculatedValues.cappedUnscaledUnitPrice || 0
    : quote.pricingV3.values.calculatedValues.uncappedUnscaledUnitPrice || 0);

  // find any apply any discounts
  let discount = 0;
  const optionalFeedback = quote.optionalFeedback || [];
  const feedbackResponses = quote.feedbackResponses || {};
  for (let i = 0; i < optionalFeedback.length; i += 1) {
    const feedback = optionalFeedback[i];
    const response = feedbackResponses[feedback.title];
    if (response) {
      const option = feedback.options.find((o) => o.value === response);
      if (option) {
        discount += option.discount || 0;
      }
    }
  }
  discount *= multiplier;

  if (leadtimeText) {
    return {
      leadtimeText, setupPrice, unitPrice, discount,
    };
  }
  return {
    leadtimeDays, setupPrice, unitPrice, discount,
  };
};

module.exports = { calculate };
