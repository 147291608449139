/* eslint-disable import/prefer-default-export */
// https://github.com/webpack/webpack/discussions/14648#discussioncomment-7844340
/**
 * A Worker that can be run on a different origin by respecting CORS
 * By default, a Worker constructor that uses an url as its parameters ignores CORS
 * and fails on a different origin.
 * This way we can run web worker on scripts served by a CDN
 */
export class CorsWorker {
  /**
   * @param {string | URL} url - worker script URL
   * @param {WorkerOptions} [options] - worker options
   */
  constructor(url, options) {
    const absoluteUrl = new URL(url, window.location.href).toString();
    const workerSource = `\
  const urlString = ${JSON.stringify(absoluteUrl)}
  const originURL = new URL(urlString)
  const isValidUrl = (urlString) => {
    try { return Boolean(new URL(urlString, originURL)) } catch(e){ return false }
  }
  const originalImportScripts = self.importScripts
  self.importScripts = (url) => {
    /* see note 1 below */
    if(url.startsWith("blob:") && isValidUrl(url.replace("blob:", ""))){
      const urlWithoutBlob = url.replace("blob:", "")
      const { pathname } = new URL(urlWithoutBlob, originURL)
      url = pathname && pathname.substring(1) /* see note 2 below */
    }
    originalImportScripts.call(self, new URL(url, originURL).toString())
  }
  importScripts(urlString);
`;
    const blob = new Blob([workerSource], { type: 'application/javascript' });
    const objectURL = URL.createObjectURL(blob);
    this.worker = new Worker(objectURL, options);
    URL.revokeObjectURL(objectURL);
  }

  getWorker() {
    return this.worker;
  }

  /*
      The notes here are outside workerSource to not increase the ObjectURL size with
    long explanations as comments

    Note 1
    ======
      Sometimes Webpack will try to import url with "blob:" prefixed and with relative
    path as absolute path.

      Not only this will cause a security error due to different url, such as a "blob:"
    protocol (meaning a CORS error), the path will be wrong. Any of those 2 problems
    fails the importScripts. Since we can import JS with blobs, lets just remove "blob:"
    prefix and fix the URL its content is a valid URL

    Note 2
    ======
       URL#pathname always starts with "/", we want to remove the / to be a relative path
     to the Worker file URL

   */
}
