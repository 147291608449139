import React from 'react';

import ProcessDataProvider from '../../components/ProcessData/processDataProvider';
import ProcessData from './processData';
import SummaryPage from './summary';

export default SummaryPage;

function ProcessDataPage() {
  return (
    <ProcessDataProvider>
      <ProcessData />
    </ProcessDataProvider>
  );
}
export { ProcessDataPage };
