import './activeOrder.scss';

import {
  Alert,
  Badge,
  Container,
  StatusIndicator,
  TextContent,
} from '@cloudscape-design/components';
import PropTypes from 'prop-types';
import React from 'react';

import OrgName from '../Organization/orgName';
import {
  useProjectStatus,
  useShipDate,
} from './hooks';
import OrderStep, { DatumWithLabel } from './orderStep';

// OrderHeader is only used in ActiveOrderStatus
function OrderHeader({
  projectNumber,
  organization,
  shipDateText,
  totalYield = undefined,
}) {
  return (
    <div className="orderHeader">
      <div className="start">
        <h3>
          <Badge color={projectNumber[0] === 'C' ? 'blue' : 'green'}>{projectNumber}</Badge>
          <OrgName orgId={organization} />
        </h3>
        <p>{shipDateText}</p>
      </div>
      {totalYield && (
        <DatumWithLabel
          label="Yield"
          value={totalYield}
        />
      )}
    </div>
  );
}
OrderHeader.propTypes = {
  projectNumber: PropTypes.string.isRequired,
  organization: PropTypes.string.isRequired,
  shipDateText: PropTypes.string.isRequired,
  totalYield: PropTypes.string,
};

function ActiveOrderStatus({
  organization,
  lineItemId,
  projectNumber,
  shipDate,
  shipDateActual = null,
  steps,
  notes = null,
}) {
  const { projectStatus, queryStatus } = useProjectStatus(steps, lineItemId);
  const shipDateText = useShipDate(shipDate, shipDateActual);

  if (queryStatus === 'loading') {
    return (
      <Container
        header={(
          <OrderHeader
            projectNumber={projectNumber}
            organization={organization}
            shipDateText={shipDateText}
          />
        )}
        disableHeaderPaddings
      >
        <StatusIndicator type="loading">Loading...</StatusIndicator>
      </Container>
    );
  }
  if (queryStatus === 'error') {
    return (
      <Container
        header={(
          <OrderHeader
            projectNumber={projectNumber}
            organization={organization}
            shipDateText={shipDateText}
          />
        )}
        disableHeaderPaddings
      >
        <StatusIndicator type="error">Error</StatusIndicator>
      </Container>
    );
  }
  return (
    <Container
      header={(
        <OrderHeader
          projectNumber={projectNumber}
          organization={organization}
          shipDateText={shipDateText}
          totalYield={`${projectStatus.totalYield || 0}%`}
        />
      )}
      disableHeaderPaddings
      disableContentPaddings
    >
      {notes ? (
        <Alert statusIconAriaLabel="Warning" type="warning">
          <TextContent>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: notes }} />
          </TextContent>
        </Alert>
      ) : null}
      <ol className={`orderSteps ${notes ? 'hasNote' : ''}`}>
        {steps?.map((step, index) => (
          step.goal > 0 && (
            <OrderStep
              // eslint-disable-next-line react/no-array-index-key
              key={`${step.name}_${index}`}
              number={index + 1}
              title={step.name}
              goal={step.goal}
              statusData={projectStatus[index]}
            />
          )
        ))}
      </ol>
    </Container>
  );
}

ActiveOrderStatus.propTypes = {
  organization: PropTypes.string.isRequired,
  lineItemId: PropTypes.string.isRequired,
  projectNumber: PropTypes.string.isRequired,
  shipDate: PropTypes.string.isRequired,
  shipDateActual: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    goal: PropTypes.number.isRequired,
  })).isRequired,
  notes: PropTypes.string,
};

export default ActiveOrderStatus;
