import {
  doc,
  getDoc,
  getFirestore,
} from 'firebase/firestore';
import {
  useEffect,
  useState,
} from 'react';

export default function useCurrentConfig(process) {
  const [config, setConfig] = useState({ rows: [] });
  const [configIsLoading, setConfigIsLoading] = useState(true);
  const [configError, setConfigError] = useState(null);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const firestore = getFirestore();
        const configIdRef = doc(firestore, 'memstore', 'process_config_id');
        const configIdSnapshot = await getDoc(configIdRef);
        const configId = configIdSnapshot.data();

        const configRef = doc(firestore, 'process_configs', configId[process]);
        const configSnapshot = await getDoc(configRef);
        const configData = configSnapshot.data();

        const sections = [];
        const sectionIndecies = new Map();
        configData.handles.forEach((handle) => {
          if (!sectionIndecies.has(handle.section)) {
            sectionIndecies.set(handle.section, sections.length);
            sections.push({ header: handle.section, values: [] });
          }
        });
        configData.handles.forEach((handle) => {
          const sectionIndex = sectionIndecies.get(handle.section);
          sections[sectionIndex].values.push(handle);
        });

        setConfig({ ...configData, rows: sections });
        setConfigIsLoading(false);
      } catch (err) {
        setConfigError(err);
        setConfigIsLoading(false);
      }
    };

    fetchConfig();
  }, [process]);

  return { config, configIsLoading, configError };
}
