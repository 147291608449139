/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { InspectionValues, StepTypes } from '@parallel-fluidics/constants';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';

const chipWidth = 8; // passed to CSS as --_chipWidth
const chipHeight = 80; // passed to CSS as --_chipHeight

function formatMinutesToHours(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.round(minutes % 60);
  if (hours === 0) {
    return `${remainingMinutes} mins`;
  }
  return `${hours} hrs ${remainingMinutes} mins`;
}

// DatumWithLabel is only used in OrderHeader and OrderStep
function DatumWithLabel({
  additionalClassName = undefined,
  label,
  value,
}) {
  return (
    <p className={`datum ${additionalClassName ?? ''}`}>
      <span className="label">{label}</span>
      {value}
    </p>
  );
}
DatumWithLabel.propTypes = {
  additionalClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

// Chip is only used in OrderStep
function Chip({
  chipDatum,
  onPointerDown = null,
  isHighlighted = false,
  isRangeStart = false,
  isRangeEnd = false,
  isFirst = false,
  isLast = false,
  date = null,
}) {
  const getClassName = (inspection) => {
    switch (inspection) {
      case InspectionValues.NOT_INSPECTED:
        return ' toBeInspected';
      case InspectionValues.PASSED:
        return ' passed completed';
      case InspectionValues.REJECT_MAJOR:
        return ' rejectMajor completed';
      case InspectionValues.REJECT_COSMETIC:
        return ' rejectCosmetic completed';
      case InspectionValues.TUNING:
        return ' tuning completed';
      case 'On deck':
        return ' onDeck';
      case 'Nonexistent':
        return ' nonexistent';
      default:
        return '';
    }
  };
  return (
    <li
      className={`
        chip
        ${isHighlighted ? ' highlighted' : ''}
        ${isRangeStart ? ' start' : ''}
        ${isRangeEnd ? ' end' : ''}
        ${isFirst ? ' first' : ''}
        ${isLast ? ' last' : ''}
        ${date ? ' hasDate' : ''}
      `}
    >
      <div className="rangeIndicator" onPointerDown={onPointerDown} />
      {chipDatum.travelerId ? (
        <>
          <Link to={`/travelers/${chipDatum.travelerId}`} target="_blank">
            <div className={`prevInspection${getClassName(chipDatum.prevInspection)}`} />
            <div className={`curInspection${getClassName(chipDatum.curInspection)}`}>
              <p className="visuallyHidden">{chipDatum.travelerId}</p>
            </div>
          </Link>
          { isFirst && date && (
            <p className="date" title={date}>{date}</p>
          )}
          <div className="overlay" />
        </>
      ) : (
        <div className="chipWrapper">
          <div className="prevInspection nonexistent" />
          <div className="curInspection nonexistent">
            <p className="visuallyHidden">Nonexistent chip</p>
          </div>
        </div>
      )}
    </li>
  );
}
Chip.propTypes = {
  chipDatum: PropTypes.shape({
    travelerId: PropTypes.string,
    prevInspection: PropTypes.string,
    curInspection: PropTypes.string,
  }).isRequired,
  onPointerDown: PropTypes.func,
  isHighlighted: PropTypes.bool,
  isRangeStart: PropTypes.bool,
  isRangeEnd: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  date: PropTypes.string,
};
function OrderStep({
  number,
  title,
  goal,
  statusData,
}) {
  const remainCounts = useMemo(
    () => Math.max(0, goal - statusData.passed),
    [goal, statusData.passed],
  );
  const containerRef = useRef(null);
  const [rangeIdx, setRangeIdx] = useState({ start: 0, end: 0 });
  const [averageProcessTime, setAverageProcessTime] = useState(0);
  const [estimate, setEstimate] = useState(0);

  // -1 means no pointer is currently captured
  const [draggingPointer, setDraggingPointer] = useState(-1);
  const [draggingStart, setDraggingStart] = useState(false);
  const [draggingEnd, setDraggingEnd] = useState(false);
  const [draggingInvalid, setDraggingInvalid] = useState(false);

  const groupByDate = useMemo(() => {
    const groupedData = statusData.chipData.reduce((acc, chip, index) => {
      const date = chip.sortDate.toDate();
      const key = `${date.getMonth() + 1}/${date.getDate()}`;
      if (!acc[key]) {
        acc[key] = { startIdx: index, endIdx: index };
      } else {
        acc[key].endIdx = index;
      }
      return acc;
    }, {});

    const groupedArray = Object.keys(groupedData).map((key) => ({
      date: key,
      ...groupedData[key],
    }));

    if ([StepTypes.MOLDING, StepTypes.BONDING].includes(statusData.type)) {
      let endGroupIndex = groupedArray.length - 1;
      let rangeEnd = groupedArray[endGroupIndex]?.endIdx || 0;
      let endFound = false;

      // Find endIdx by looking for the last non-"On deck" chip
      while (!endFound && endGroupIndex >= 0) {
        const group = groupedArray[endGroupIndex];
        for (let j = group.endIdx; j >= group.startIdx; j -= 1) {
          if (statusData.chipData[j].curInspection !== 'On deck') {
            rangeEnd = j;
            endFound = true;
            break;
          }
        }
        if (!endFound) {
          endGroupIndex -= 1;
        }
      }

      // After finding rangeEnd, determine rangeStart to meet minimum length
      let rangeStart = groupedArray[endGroupIndex]?.startIdx || 0;
      const minGroupLength = 5; // Minimum length for the range
      let totalLength = rangeEnd - rangeStart;
      endGroupIndex -= 1;

      // Expand start range backwards to meet minimum length if needed
      while (totalLength < minGroupLength && endGroupIndex >= 0) {
        rangeStart = groupedArray[endGroupIndex].startIdx;
        totalLength = rangeEnd - rangeStart;
        endGroupIndex -= 1;
      }
      setRangeIdx({ start: rangeStart, end: rangeEnd });
    }
    return groupedArray;
  }, [statusData.chipData, statusData.type]);

  useEffect(() => {
    if (rangeIdx.end - rangeIdx.start < 1) return;
    // calculate average process time by start to start
    const pressData = statusData.chipData.slice(rangeIdx.start, rangeIdx.end + 1)
      .reduce((acc, curChip) => {
        if (!curChip.runStartDate) return acc; // Skip if runStartDate is undefined

        const { press } = curChip;
        // eslint-disable-next-line max-len
        const prevData = acc[press] || { minTime: curChip.runStartDate, maxTime: curChip.runStartDate, count: 0 };

        // Update the min and max runStartDate for the press
        prevData.minTime = Math.min(prevData.minTime, curChip.runStartDate);
        prevData.maxTime = Math.max(prevData.maxTime, curChip.runStartDate);
        prevData.count += 1;

        acc[press] = prevData;
        return acc;
      }, {});
    const { sum, pressCount } = Object.values(pressData).reduce((
      acc,
      { minTime, maxTime, count },
    ) => {
      if (count > 1) {
        const pressAvg = (maxTime - minTime) / (count - 1);
        acc.sum += pressAvg;
        acc.pressCount += 1;
      }
      return acc;
    }, { sum: 0, pressCount: 0 });

    if (pressCount > 0) {
      const averageMinutes = (sum / pressCount) / 1000 / 60;
      setAverageProcessTime(Math.round(averageMinutes));
      setEstimate(averageMinutes * (remainCounts / ((statusData.stepYield / 100) || 1)));
    }
  }, [rangeIdx.start, rangeIdx.end, statusData, remainCounts]);

  const handleUpdateRange = useCallback((value, type) => {
    setRangeIdx((prev) => ({
      ...prev,
      [type]: value,
    }));
  }, []);

  const startDragging = useCallback((event, index) => {
    if (index !== rangeIdx.start && index !== rangeIdx.end) return;

    if (draggingPointer !== -1 && draggingPointer !== event.pointerId) {
      // there is a pointer that is already dragging
      // release its capture first
      containerRef.current.releasePointerCapture(draggingPointer);
    }

    // capture pointer
    containerRef.current.setPointerCapture(event.pointerId);
    setDraggingPointer(event.pointerId);

    // deselect current selection
    const selection = document.getSelection();
    selection.empty();

    if (index === rangeIdx.start) {
      setDraggingStart(true);
      setDraggingEnd(false);
    } else if (index === rangeIdx.end) {
      setDraggingStart(false);
      setDraggingEnd(true);
    }
  }, [rangeIdx.start, rangeIdx.end, draggingPointer]);

  const handlePointerMove = useCallback((event) => {
    if (
      (!draggingStart && !draggingEnd)
      || draggingPointer !== event.pointerId
    ) return;

    // get chip index
    const chipsInRow = Math.floor(containerRef.current.getBoundingClientRect().width / chipWidth);
    const columnIndex = Math.floor(event.nativeEvent.offsetX / chipWidth);
    const rowIndex = Math.floor(event.nativeEvent.offsetY / chipHeight);
    const chipIndex = rowIndex * chipsInRow + columnIndex;

    // validate chip index
    if (
      chipIndex < 0
      || chipIndex > statusData.chipData.length - 1
      || (draggingStart && chipIndex === rangeIdx.end)
      || (draggingEnd && chipIndex === rangeIdx.start)
    ) {
      setDraggingInvalid(true);
      return;
    }
    setDraggingInvalid(false);

    if (draggingStart && chipIndex > rangeIdx.end) {
      // flip start and end
      handleUpdateRange(rangeIdx.end, 'start');
      handleUpdateRange(chipIndex, 'end');
      setDraggingStart(false);
      setDraggingEnd(true);
    } else if (draggingStart) {
      handleUpdateRange(chipIndex, 'start');
    } else if (draggingEnd && chipIndex < rangeIdx.start) {
      // flip start and end
      handleUpdateRange(rangeIdx.start, 'end');
      handleUpdateRange(chipIndex, 'start');
      setDraggingEnd(false);
      setDraggingStart(true);
    } else if (draggingEnd) {
      handleUpdateRange(chipIndex, 'end');
    }
  }, [
    draggingStart,
    draggingEnd,
    draggingPointer,
    rangeIdx.start,
    rangeIdx.end,
    statusData.chipData.length,
    handleUpdateRange,
  ]);

  const handlePointerUp = useCallback((event) => {
    if (
      (!draggingStart && !draggingEnd)
      || draggingPointer !== event.pointerId
    ) return;

    // release pointer
    containerRef.current.releasePointerCapture(event.pointerId);
    setDraggingPointer(-1);

    setDraggingStart(false);
    setDraggingEnd(false);
    setDraggingInvalid(false);
  }, [draggingStart, draggingEnd, draggingPointer]);

  return (
    <li className="orderStep">
      <div className="topRow">
        <h4>
          <span className="number">{number}</span>
          {title}
        </h4>

        <div className="stats">
          {[StepTypes.MOLDING, StepTypes.BONDING].includes(statusData.type) && (
            <>
              <DatumWithLabel
                additionalClassName="range"
                label="Average press time"
                value={`${averageProcessTime} min`}
              />
              {estimate > 0 && (
                <DatumWithLabel
                  additionalClassName="range"
                  label="Estimate"
                  value={formatMinutesToHours(estimate)}
                />
              )}
            </>
          )}
          <DatumWithLabel label="Yield" value={`${statusData.stepYield}%`} />
        </div>
      </div>

      {/* data */}
      <ul className="status">
        <li>
          <p className="datum passed">
            <span className="largeNumber">
              <span className="passed">{statusData.passed}</span>
              <span className="goal">{goal}</span>
            </span>
            <span className="label">Passed</span>
          </p>
        </li>
        <li>
          <DatumWithLabel
            additionalClassName="rejectCosmetic"
            label="Reject (Cosmetic)"
            value={statusData.rejectCosmetic}
          />
        </li>
        <li>
          <DatumWithLabel
            additionalClassName="rejectMajor"
            label="Reject (Major)"
            value={statusData.rejectMajor}
          />
        </li>
        <li>
          <DatumWithLabel
            additionalClassName="tuning"
            label="Tuning"
            value={statusData.tuning}
          />
        </li>
        {statusData.toBeInspected > 0 && (
          <li>
            <DatumWithLabel
              additionalClassName="toBeInspected"
              label="To-be inspected"
              value={statusData.toBeInspected}
            />
          </li>
        )}
        {statusData.onDeck > 0 && (
          <li>
            <DatumWithLabel
              additionalClassName="onDeck"
              label="On deck"
              value={statusData.onDeck}
            />
          </li>
        )}
        {statusData.unknown > 0 && (
          <li>
            <DatumWithLabel
              additionalClassName="nonexistent"
              label="Unknown"
              value={statusData.onDeck}
            />
          </li>
        )}
      </ul>

      {/* chips */}
      <div
        className={`
          chips-container
          ${[StepTypes.MOLDING, StepTypes.BONDING].includes(statusData.type) ? ' rangeSlider' : ''}
          ${draggingStart ? ' draggingStart' : ''}
          ${draggingEnd ? ' draggingEnd' : ''}
          ${draggingInvalid ? ' invalid' : ''}
        `}
        ref={containerRef}
        style={{
          '--_chipWidth': `${chipWidth}px`,
          '--_chipHeight': `${chipHeight}px`,
        }}
        onPointerMove={(e) => handlePointerMove(e)}
        onPointerUp={(e) => handlePointerUp(e)}
        onPointerCancel={(e) => handlePointerUp(e)}
      >
        <ol className="chips">
          {groupByDate.map(({ startIdx, endIdx, date }, olIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={`${title}_${olIndex}`}>
              {statusData.chipData.slice(startIdx, endIdx + 1).map((chipDatum, index) => (
                <Chip
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${title}_${index}`}
                  chipDatum={chipDatum}
                  onPointerDown={(e) => startDragging(e, startIdx + index)}
                  isHighlighted={
                    rangeIdx.start <= (startIdx + index)
                    && (startIdx + index) <= rangeIdx.end
                  }
                  isRangeStart={(startIdx + index) === rangeIdx.start}
                  isRangeEnd={(startIdx + index) === rangeIdx.end}
                  isFirst={index === 0}
                  isLast={index === endIdx - startIdx}
                  date={date}
                />
              ))}
            </React.Fragment>
          ))}
          {(new Array(remainCounts).fill({})).map((chipDatum, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Chip key={`${title}_${index}`} chipDatum={chipDatum} />
          ))}
        </ol>
      </div>
    </li>
  );
}
OrderStep.propTypes = {
  number: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  goal: PropTypes.number.isRequired,
  statusData: PropTypes.shape({
    passed: PropTypes.number.isRequired,
    toBeInspected: PropTypes.number.isRequired,
    rejectCosmetic: PropTypes.number.isRequired,
    rejectMajor: PropTypes.number.isRequired,
    tuning: PropTypes.number.isRequired,
    onDeck: PropTypes.number,
    unknown: PropTypes.number,
    stepYield: PropTypes.number.isRequired,
    chipData: PropTypes.arrayOf(PropTypes.shape({
      travelerId: PropTypes.string,
      prevInspection: PropTypes.string.isRequired,
      curInspection: PropTypes.string.isRequired,
      runStartDate: PropTypes.instanceOf(Date),
    })).isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default OrderStep;
export { DatumWithLabel };
