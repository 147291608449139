import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { usePLCValues } from '../../../features/plc/context';
import { useProgramHandle } from '../hooks/context';

export default function HeatPipeStatus({ sideHandle, count = 6 }) {
  const programHandle = useProgramHandle();

  const { handles, titleMap } = useMemo(() => {
    const handleNames = [];
    const titleMapObject = {};
    for (let i = 0; i < count; i += 1) {
      const title = `HP${i + 1}`;
      const handle = `${programHandle}.${sideHandle}${title}`;
      handleNames.push(handle);
      titleMapObject[handle] = title;
    }
    return { handles: handleNames, titleMap: titleMapObject };
  }, [count, programHandle, sideHandle]);
  const hpValues = usePLCValues(handles);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      {handles.map((handle) => (
        <div key={handle} style={{ minWidth: 85 }}>
          {`${titleMap[handle]}: `}
          <b>
            {hpValues[handle] / 10}
            °C
          </b>
        </div>
      ))}
    </div>
  );
}

HeatPipeStatus.propTypes = {
  sideHandle: PropTypes.string.isRequired,
  count: PropTypes.number,
};
