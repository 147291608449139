import PropTypes from 'prop-types';
import React from 'react';

import {
  usePLCValue,
} from '../../../features/plc/context';
import PLCButton from './plcButton';

function PLCToggleButton({
  handle,
  label,
  disabled = false,
  fullWidth = false,
}) {
  const value = usePLCValue(handle);

  return (
    <PLCButton
      disabled={disabled}
      onClick={(write) => { write({ handle, value: !value }); }}
      variant={value ? 'primary' : 'normal'}
      fullWidth={fullWidth}
    >
      {value ? 'Disable' : 'Enable'}
      {' '}
      {label}
    </PLCButton>
  );
}

PLCToggleButton.propTypes = {
  handle: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default PLCToggleButton;
