const OrderStatus = Object.freeze({
  NOT_STARTED: 'Not started',
  IN_PROGRESS: 'In progress',
  COMPLETED: 'Completed',
  ARCHIVED: 'Archived',
});

const StatusColorMap = Object.freeze({
  [OrderStatus.NOT_STARTED]: 'grey',
  [OrderStatus.IN_PROGRESS]: 'blue',
  [OrderStatus.COMPLETED]: 'green',
  [OrderStatus.ARCHIVED]: 'grey',
});

export default OrderStatus;
export { StatusColorMap };
