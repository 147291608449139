import {
  Box,
  Button,
  ColumnLayout,
  ExpandableSection,
  FormField,
  Grid,
  Input,
  Modal,
  SpaceBetween,
} from '@cloudscape-design/components';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import * as Yup from 'yup';

import { usePLCValues, usePLCWrite } from '../../../features/plc/context';
import { useProgramHandle } from '../hooks/context';

function SetupConfigModal({ isOpen, close }) {
  const programHandle = useProgramHandle();
  const write = usePLCWrite();

  const handleBases = [
    'tPresoakTime',
    'tBondTime',
    'tSuperheatWaitTime',
    'tAnnealingWaitTime',

    'fCapSideLoadingTemp',
    'fChipSideLoadingTemp',

    'fCapSideSetpointTolerance',
    'fChipSideSetpointTolerance',

    'fCapSideBondingTemp',
    'fChipSideBondingTemp',

    'fChipSideAnnealingTemp',
    'fCapSideAnnealingTemp',

    'fChipSideSuperheatTemp',
    'fChipSideSuperheatTolerance',
    'fCapSideSuperheatTemp',
    'fCapSideSuperheatTolerance',

    'fChipSideFinalTemp',
    'fCapSideFinalTemp',
  ];

  const {
    handles, validationSchema, transforms, timeHandles,
  } = useMemo(() => {
    if (!programHandle) {
      return {
        handles: [],
        validationSchema: Yup.object().shape({}),
        transforms: [],
        timeHandles: [],
      };
    }

    const handleNames = [];
    const yupSchema = {};
    handleBases.forEach((handleBase) => {
      const handle = `${programHandle}.${handleBase}`;
      handleNames.push(handle);
      yupSchema[handle] = Yup
        .number()
        .typeError(`${handleBase} must be valid number`)
        .required(`${handleBase} is required`);
    });

    return {
      handles: handleNames,
      validationSchema: Yup.object().shape(yupSchema),
      transforms: [
        {
          inHandle: `${programHandle}.fCapSideLoadingTemp`,
          outHandle: `${programHandle}.fCapSideLoadingTempDisengaged`,
          transform: (v) => v - 5,
        },
        {
          inHandle: `${programHandle}.fChipSideLoadingTemp`,
          outHandle: `${programHandle}.fChipSideLoadingTempDisengaged`,
          transform: (v) => v - 5,
        },
        {
          inHandle: `${programHandle}.fCapSideBondingTemp`,
          outHandle: `${programHandle}.fCapSideBondingTempDisengaged`,
          transform: (v) => (1.1628 * v) + 3.2558,
        },
        {
          inHandle: `${programHandle}.fChipSideBondingTemp`,
          outHandle: `${programHandle}.fChipSideBondingTempDisengaged`,
          transform: (v) => v - 5,
        },
        {
          inHandle: `${programHandle}.fChipSideAnnealingTemp`,
          outHandle: `${programHandle}.fChipSideAnnealingTempDisengaged`,
          transform: (v) => v - 10,
        },
        {
          inHandle: `${programHandle}.fCapSideAnnealingTemp`,
          outHandle: `${programHandle}.fCapSideAnnealingTempDisengaged`,
          transform: (v) => (1.1628 * v) + 3.2558,
        },
        {
          inHandle: `${programHandle}.fChipSideSuperheatTemp`,
          outHandle: `${programHandle}.fChipSideSuperheatTempDisengaged`,
          transform: (v) => v - 5,
        },
        {
          inHandle: `${programHandle}.fCapSideSuperheatTemp`,
          outHandle: `${programHandle}.fCapSideSuperheatTempDisengaged`,
          transform: (v) => (1.1628 * v) + 3.2558,
        },
        {
          inHandle: `${programHandle}.fCapSideFinalTemp`,
          outHandle: `${programHandle}.fCapSideFinalTempDisengaged`,
          transform: (v) => v - 5,
        },
        {
          inHandle: `${programHandle}.fChipSideFinalTemp`,
          outHandle: `${programHandle}.fChipSideFinalTempDisengaged`,
          transform: (v) => v - 5,
        },
      ],
      timeHandles: [
        `${programHandle}.tPresoakTime`,
        `${programHandle}.tBondTime`,
        `${programHandle}.tSuperheatWaitTime`,
        `${programHandle}.tAnnealingWaitTime`,
      ],
    };
  }, [programHandle]);

  const plcValues = usePLCValues(handles);

  const initialValues = useMemo(() => {
    const values = handles.reduce(
      (acc, handle) => ({ ...acc, [handle]: plcValues[handle] || 0 }),
      {},
    );
    if (!isOpen) {
      return values;
    }
    transforms.forEach(({ inHandle, outHandle, transform }) => {
      if (plcValues[inHandle] !== undefined) {
        values[outHandle] = Math.round(transform(plcValues[inHandle]) * 10) / 10;
      } else {
        values[outHandle] = 0;
      }
    });
    timeHandles.forEach((handle) => {
      if (plcValues[handle] !== undefined) {
        values[handle] = plcValues[handle] / 1000;
      } else {
        values[handle] = 0;
      }
    });
    return values;
  }, [isOpen, handles, transforms, plcValues]);

  if (typeof document === 'undefined') {
    return null;
  }

  return ReactDOM.createPortal(
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(values) => {
        const newValues = { ...values };
        const newHandles = [...handles];
        transforms.forEach(({ inHandle, outHandle, transform }) => {
          newValues[outHandle] = Math.round(transform(values[inHandle]) * 10) / 10;
          newHandles.push(outHandle);
        });
        timeHandles.forEach((handle) => {
          newValues[handle] = values[handle] * 1000;
        });
        newHandles.forEach((handle) => {
          write({ handle, value: newValues[handle] });
        });

        close();
      }}
    >
      {({
        values, handleSubmit, setFieldValue, errors,
      }) => {
        for (let i = 0; i < transforms.length; i += 1) {
          const { inHandle, outHandle, transform } = transforms[i];
          useEffect(() => {
            if (Number.isNaN(parseFloat(values[inHandle]))) {
              setFieldValue(`['${outHandle}']`, 0);
            } else {
              setFieldValue(`['${outHandle}']`, Math.round(transform(parseFloat(values[inHandle])) * 10) / 10);
            }
          }, [values[inHandle]]);
        }

        return (
          <Modal
            size="max"
            visible={isOpen}
            header="Initialize config"
            footer={(
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button variant="link" onClick={close}>Cancel</Button>
                  <Button variant="primary" disabled={!!Object.keys(errors).length} onClick={handleSubmit}>Save</Button>
                </SpaceBetween>
              </Box>
            )}
            onDismiss={() => { close(); }}
          >
            <SpaceBetween size="m">
              <Grid
                gridDefinition={[
                  { colspan: { default: 12, s: 2 } },
                  { colspan: { default: 6, s: 5 } },
                  { colspan: { default: 6, s: 5 } },
                ]}
              >
                <SpaceBetween size="xs">
                  <FormField
                    label="Presoak Time (s)"
                    errorText={errors[`${programHandle}.tPresoakTime`]}
                  >
                    <Input
                      type="number"
                      value={values[`${programHandle}.tPresoakTime`]}
                      onChange={({ detail }) => setFieldValue(`['${programHandle}.tPresoakTime']`, detail.value)}
                    />
                  </FormField>
                  <FormField
                    label="Bond Time (s)"
                    errorText={errors[`${programHandle}.tBondTime`]}
                  >
                    <Input
                      type="number"
                      value={values[`${programHandle}.tBondTime`]}
                      onChange={({ detail }) => setFieldValue(`['${programHandle}.tBondTime']`, detail.value)}
                    />
                  </FormField>
                  <FormField
                    label="Superheat Wait Time (s)"
                    errorText={errors[`${programHandle}.tSuperheatWaitTime`]}
                  >
                    <Input
                      type="number"
                      value={values[`${programHandle}.tSuperheatWaitTime`]}
                      onChange={({ detail }) => setFieldValue(`['${programHandle}.tSuperheatWaitTime']`, detail.value)}
                    />
                  </FormField>
                  <FormField
                    label="Annealing Wait Time (s)"
                    errorText={errors[`${programHandle}.tAnnealingWaitTime`]}
                  >
                    <Input
                      type="number"
                      value={values[`${programHandle}.tAnnealingWaitTime`]}
                      onChange={({ detail }) => setFieldValue(`['${programHandle}.tAnnealingWaitTime']`, detail.value)}
                    />
                  </FormField>
                </SpaceBetween>
                <Grid
                  gridDefinition={[
                    { colspan: 6 },
                    { colspan: 6 },
                  ]}
                >
                  <SpaceBetween size="xs">
                    <FormField
                      label="Cap Side Loading Temperature"
                      errorText={errors[`${programHandle}.fCapSideLoadingTemp`]}
                    >
                      <Input
                        type="number"
                        value={values[`${programHandle}.fCapSideLoadingTemp`]}
                        onChange={({ detail }) => setFieldValue(`['${programHandle}.fCapSideLoadingTemp']`, detail.value)}
                      />
                    </FormField>
                    <FormField
                      label="Cap Side Bonding Temperature"
                      errorText={errors[`${programHandle}.fCapSideBondingTemp`]}
                    >
                      <Input
                        type="number"
                        value={values[`${programHandle}.fCapSideBondingTemp`]}
                        onChange={({ detail }) => setFieldValue(`['${programHandle}.fCapSideBondingTemp']`, detail.value)}
                      />
                    </FormField>
                    <FormField
                      label="Cap Side Bonding Setpoint Tolerance Band"
                      errorText={errors[`${programHandle}.fCapSideSetpointTolerance`]}
                    >
                      <Input
                        type="number"
                        value={values[`${programHandle}.fCapSideSetpointTolerance`]}
                        onChange={({ detail }) => setFieldValue(`['${programHandle}.fCapSideSetpointTolerance']`, detail.value)}
                      />
                    </FormField>
                  </SpaceBetween>
                  <SpaceBetween size="xs">
                    <FormField
                      label="Chip Side Loading Temperature"
                      errorText={errors[`${programHandle}.fChipSideLoadingTemp`]}
                    >
                      <Input
                        type="number"
                        value={values[`${programHandle}.fChipSideLoadingTemp`]}
                        onChange={({ detail }) => setFieldValue(`['${programHandle}.fChipSideLoadingTemp']`, detail.value)}
                      />
                    </FormField>
                    <FormField
                      label="Chip Side Bonding Temperature"
                      errorText={errors[`${programHandle}.fChipSideBondingTemp`]}
                    >
                      <Input
                        type="number"
                        value={values[`${programHandle}.fChipSideBondingTemp`]}
                        onChange={({ detail }) => setFieldValue(`['${programHandle}.fChipSideBondingTemp']`, detail.value)}
                      />
                    </FormField>
                    <FormField
                      label="Chip Side Heater Setpoint Tolerance Band"
                      errorText={errors[`${programHandle}.fChipSideSetpointTolerance`]}
                    >
                      <Input
                        type="number"
                        value={values[`${programHandle}.fChipSideSetpointTolerance`]}
                        onChange={({ detail }) => setFieldValue(`['${programHandle}.fChipSideSetpointTolerance']`, detail.value)}
                      />
                    </FormField>
                  </SpaceBetween>
                </Grid>
                <Grid
                  gridDefinition={[
                    { colspan: 6 },
                    { colspan: 6 },
                  ]}
                >
                  <SpaceBetween size="xs">
                    <FormField
                      label="Cap Side Superheat Temperature"
                      errorText={errors[`${programHandle}.fCapSideSuperheatTemp`]}
                    >
                      <Input
                        type="number"
                        value={values[`${programHandle}.fCapSideSuperheatTemp`]}
                        onChange={({ detail }) => setFieldValue(`['${programHandle}.fCapSideSuperheatTemp']`, detail.value)}
                      />
                    </FormField>
                    <FormField
                      label="Cap Side Superheat Tolerance Band"
                      errorText={errors[`${programHandle}.fCapSideSuperheatTolerance`]}
                    >
                      <Input
                        type="number"
                        value={values[`${programHandle}.fCapSideSuperheatTolerance`]}
                        onChange={({ detail }) => setFieldValue(`['${programHandle}.fCapSideSuperheatTolerance']`, detail.value)}
                      />
                    </FormField>
                    <FormField
                      label="Cap Side Annealing Temperature"
                      errorText={errors[`${programHandle}.fCapSideAnnealingTemp`]}
                    >
                      <Input
                        type="number"
                        value={values[`${programHandle}.fCapSideAnnealingTemp`]}
                        onChange={({ detail }) => setFieldValue(`['${programHandle}.fCapSideAnnealingTemp']`, detail.value)}
                      />
                    </FormField>
                    <FormField
                      label="Cap Side Final Temperature"
                      errorText={errors[`${programHandle}.fCapSideFinalTemp`]}
                    >
                      <Input
                        type="number"
                        value={values[`${programHandle}.fCapSideFinalTemp`]}
                        onChange={({ detail }) => setFieldValue(`['${programHandle}.fCapSideFinalTemp']`, detail.value)}
                      />
                    </FormField>
                  </SpaceBetween>
                  <SpaceBetween size="xs">
                    <FormField
                      label="Chip Side Superheat Temperature"
                      errorText={errors[`${programHandle}.fChipSideSuperheatTemp`]}
                    >
                      <Input
                        type="number"
                        value={values[`${programHandle}.fChipSideSuperheatTemp`]}
                        onChange={({ detail }) => setFieldValue(`['${programHandle}.fChipSideSuperheatTemp']`, detail.value)}
                      />
                    </FormField>
                    <FormField
                      label="Chip Side Superheat Tolerance Band"
                      errorText={errors[`${programHandle}.fChipSideSuperheatTolerance`]}
                    >
                      <Input
                        type="number"
                        value={values[`${programHandle}.fChipSideSuperheatTolerance`]}
                        onChange={({ detail }) => setFieldValue(`['${programHandle}.fChipSideSuperheatTolerance']`, detail.value)}
                      />
                    </FormField>
                    <FormField
                      label="Chip Side Annealing Temperature"
                      errorText={errors[`${programHandle}.fChipSideAnnealingTemp`]}
                    >
                      <Input
                        type="number"
                        value={values[`${programHandle}.fChipSideAnnealingTemp`]}
                        onChange={({ detail }) => setFieldValue(`['${programHandle}.fChipSideAnnealingTemp']`, detail.value)}
                      />
                    </FormField>
                    <FormField
                      label="Chip Side Final Temperature"
                      errorText={errors[`${programHandle}.fChipSideFinalTemp`]}
                    >
                      <Input
                        type="number"
                        value={values[`${programHandle}.fChipSideFinalTemp`]}
                        onChange={({ detail }) => setFieldValue(`['${programHandle}.fChipSideFinalTemp']`, detail.value)}
                      />
                    </FormField>
                  </SpaceBetween>
                </Grid>
              </Grid>
              <ExpandableSection headerText="Auto-calculated values">
                <ColumnLayout columns={2}>
                  <FormField label="Chip Side Loading Temperature Disengaged Temp">
                    <Input readOnly value={values[`${programHandle}.fChipSideLoadingTempDisengaged`] || 0} />
                  </FormField>
                  <FormField label="Cap Side Loading Temperature Disengaged Temp">
                    <Input readOnly value={values[`${programHandle}.fCapSideLoadingTempDisengaged`] || 0} />
                  </FormField>
                  <FormField label="Bonding Disengaged Temp">
                    <Input readOnly value={values[`${programHandle}.fCapSideBondingTempDisengaged`] || 0} />
                  </FormField>
                  <FormField label="Cold Heater Disengaged Temp">
                    <Input readOnly value={values[`${programHandle}.fChipSideBondingTempDisengaged`] || 0} />
                  </FormField>
                  <FormField label="Chip Side Superheat Disengaged Temp">
                    <Input readOnly value={values[`${programHandle}.fChipSideSuperheatTempDisengaged`] || 0} />
                  </FormField>
                  <FormField label="Cap Side Superheat Disengaged Temp">
                    <Input readOnly value={values[`${programHandle}.fCapSideSuperheatTempDisengaged`] || 0} />
                  </FormField>
                  <FormField label="Chip Side Annealing Disengaged Temp">
                    <Input readOnly value={values[`${programHandle}.fChipSideAnnealingTempDisengaged`] || 0} />
                  </FormField>
                  <FormField label="Cap Side Annealing Disengaged Temp">
                    <Input readOnly value={values[`${programHandle}.fCapSideAnnealingTempDisengaged`] || 0} />
                  </FormField>
                  <FormField label="Chip Side Final Disengaged Temp">
                    <Input readOnly value={values[`${programHandle}.fChipSideFinalTempDisengaged`] || 0} />
                  </FormField>
                  <FormField label="Cap Side Final Disengaged Temp">
                    <Input readOnly value={values[`${programHandle}.fCapSideFinalTempDisengaged`] || 0} />
                  </FormField>
                </ColumnLayout>
              </ExpandableSection>
            </SpaceBetween>
          </Modal>
        );
      }}
    </Formik>,
    document.body,
  );
}

SetupConfigModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default SetupConfigModal;
