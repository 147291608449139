import Tabs from '@cloudscape-design/components/tabs';
import React from 'react';

import CustomerProjectsList from './customerProjectsList';
import InternalProjectsList from './internalProjectsList';

export default function ProjectsPage() {
  return (
    <Tabs
      tabs={[
        {
          label: 'Customer Projects',
          id: 'first',
          content: <CustomerProjectsList />,
        },
        {
          label: 'Internal Projects',
          id: 'second',
          content: <InternalProjectsList />,
        },
      ]}
    />
  );
}
