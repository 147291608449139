import {
  collection,
  getFirestore,
  orderBy,
  query,
} from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, {
  createContext, useContext, useMemo, useState,
} from 'react';
import { useFirestoreCollectionData } from 'reactfire';

import useInternalProjects from '../../../hooks/use-internalProjects';
import AddProjectModal from '../../Projects/addProjectModal';

const ManageOrderContext = createContext();
export default function ManageOrderProvider({ children }) {
  const { internalProjectOptions } = useInternalProjects();
  const { data: customerProjects = [] } = useFirestoreCollectionData(
    query(collection(getFirestore(), 'projects'), orderBy('name', 'desc')),
  );

  const customerProjectOptions = useMemo(
    () => customerProjects.map(({ name, adminLink, filename }) => {
      const partId = adminLink?.match(/.*\/part\/([^/]+)/);
      if (partId && partId.length > 1 && partId[1].length === 20) {
        const quoteId = partId[1].substring(0, 6).toUpperCase();
        return {
          value: name,
          label: `${name} - #${quoteId} (${filename})`,
        };
      }
      return {
        value: name,
        label: `${name} (${filename})`,
      };
    }),
    [customerProjects],
  );

  const [openAddProjectModal, setOpenAddProjectModal] = useState(false);

  const projectOptions = useMemo(() => {
    const opts = [
      { label: 'Customer Projects', options: customerProjectOptions },
      { label: 'Internal Projects', options: internalProjectOptions },
    ];
    return opts;
  }, [customerProjectOptions, internalProjectOptions]);

  const value = useMemo(() => ({
    projectOptions,
    setOpenAddProjectModal,
  }), [projectOptions]);

  return (
    <ManageOrderContext.Provider value={value}>
      {children}
      {openAddProjectModal && (
        <AddProjectModal
          projects={customerProjects}
          open={openAddProjectModal}
          onClose={() => setOpenAddProjectModal(false)}
        />
      )}
    </ManageOrderContext.Provider>
  );
}
ManageOrderProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useManageOrder = () => {
  const context = useContext(ManageOrderContext);
  if (context === undefined) {
    throw new Error('useManageOrder must be used within a ManageOrderProvider');
  }
  return context;
};
