import {
  ContentLayout,
  Header,
} from '@cloudscape-design/components';
import React from 'react';
import { useParams } from 'react-router-dom';

import CADStudio from '../../components/CADStudio';

function EditCADPage() {
  const { partId } = useParams();

  return (
    <ContentLayout
      header={(
        <Header
          variant="h1"
          description="CAD Studio"
        >
          {`Quote #${partId.substring(0, 6).toUpperCase()}`}
        </Header>
      )}
    >
      <CADStudio partId={partId} />
    </ContentLayout>
  );
}

export default EditCADPage;
