/* eslint-disable react/no-unstable-nested-components */
import {
  Alert,
  AttributeEditor,
  Box,
  Button,
  Container,
  FormField,
  Header,
  Input,
  Modal,
  SpaceBetween,
  Table,
} from '@cloudscape-design/components';
import { useFormikContext } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import toPath from 'lodash/toPath';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useState,
} from 'react';
import * as Yup from 'yup';

const BASE_BREAKPOINTS = [
  { quantity: 5, multiplier: 2 },
  { quantity: 20, multiplier: 1.2 },
  { quantity: 100, multiplier: 0.8 },
];

function EditBreakpointsModal({ visible = false, close }) {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const currentBreakpoints = values.breakpoints;
  const [breakpoints, setBreakpoints] = useState([]);
  useEffect(() => {
    setBreakpoints(cloneDeep(currentBreakpoints));
  }, [currentBreakpoints, visible]);
  const [bannerError, setBannerError] = useState();
  const [fieldErrors, setFieldErrors] = useState();
  useEffect(() => {
    setFieldErrors(breakpoints.map(() => ({})));
    setBannerError(undefined);
  }, [breakpoints]);

  return (
    <Modal
      header={<Header>Edit breakpoints</Header>}
      visible={visible}
      onDismiss={close}
      size="large"
      footer={(
        <Box float="right">
          <SpaceBetween size="xs" direction="horizontal">
            <Button onClick={close}>
              Cancel
            </Button>
            <Button onClick={() => {
              // Validate there are at least two breakpoints
              if (breakpoints.length < 2) {
                setBannerError('At least 2 breakpoints are required');
                return;
              }

              // Validate breakpoints
              try {
                const schema = Yup.array().of(Yup.object().shape({
                  quantity: Yup.number().typeError('Quantity is required').required('Quantity is required'),
                  multiplier: Yup.number().typeError('Multiplier is required').required('Multiplier is required'),
                })).required();
                schema.validateSync(breakpoints, { abortEarly: false });
              } catch ({ inner }) {
                const newErrors = breakpoints.map(() => ({}));
                inner.forEach(({ path, message }) => {
                  const pathArray = toPath(path);
                  newErrors[pathArray[0]][pathArray[1]] = message;
                });
                setFieldErrors(newErrors);
                return;
              }

              // Update formik values and close modal
              const sortedBreakpoints = breakpoints.sort((a, b) => a.quantity - b.quantity);
              setFieldValue('breakpoints', sortedBreakpoints);
              setFieldTouched('breakpoints', true);
              close();
            }}
            >
              Update
            </Button>
          </SpaceBetween>
        </Box>
      )}
    >
      <SpaceBetween size="xl" direction="vertical">
        {bannerError ? (
          <Alert type="error">{bannerError}</Alert>
        ) : null}
        <AttributeEditor
          onAddButtonClick={() => setBreakpoints((oldBreakpoints) => [...oldBreakpoints, { quantity: '', multiplier: '' }])}
          onRemoveButtonClick={({
            detail: { itemIndex },
          }) => {
            setBreakpoints((oldBreakpoints) => {
              const tmpCurrentBreakpoints = [...oldBreakpoints];
              tmpCurrentBreakpoints.splice(itemIndex, 1);
              return tmpCurrentBreakpoints;
            });
          }}
          items={breakpoints}
          addButtonText="Add new breakpoint"
          definition={[
            {
              label: 'Quantity',
              control: (item, index) => (
                <FormField errorText={fieldErrors[index]?.quantity}>
                  <Input
                    value={item.quantity}
                    type="number"
                    inputMode="numeric"
                    placeholder="Enter quantity"
                    onChange={({ detail }) => {
                      setBreakpoints((oldBreakpoints) => {
                        const newBreakpoints = [...oldBreakpoints];
                        newBreakpoints[index].quantity = detail.value;
                        return newBreakpoints;
                      });
                    }}
                  />
                </FormField>
              ),
            },
            {
              label: 'Multiplier',
              control: (item, index) => (
                <FormField errorText={fieldErrors[index]?.multiplier}>
                  <Input
                    value={item.multiplier}
                    type="number"
                    inputMode="numeric"
                    placeholder="Enter multiplier"
                    onChange={({ detail }) => {
                      setBreakpoints((oldBreakpoints) => {
                        const newBreakpoints = [...oldBreakpoints];
                        newBreakpoints[index].multiplier = detail.value;
                        return newBreakpoints;
                      });
                    }}
                  />
                </FormField>
              ),
            },
          ]}
        />
      </SpaceBetween>
    </Modal>
  );
}

EditBreakpointsModal.propTypes = {
  visible: PropTypes.bool,
  close: PropTypes.func.isRequired,
};

function BreakpointsTable({ loading = false }) {
  const { values } = useFormikContext();
  const { breakpoints, calculatedValues } = values;
  const {
    uncappedUnitPrice,
    uncappedUnscaledUnitPrice,
    cappedUnitPrice,
    cappedUnscaledUnitPrice,
  } = calculatedValues || {};

  const [editBreakpointsModalOpen, setEditBreakpointsModalOpen] = useState(false);

  return (
    <>
      <EditBreakpointsModal
        visible={editBreakpointsModalOpen}
        close={() => { setEditBreakpointsModalOpen(false); }}
      />
      <Container fitHeight>
        <Table
          variant="embedded"
          header={(
            <Header
              variant="h2"
              actions={(
                <Button
                  onClick={() => { setEditBreakpointsModalOpen(true); }}
                >
                  Edit
                </Button>
              )}
            >
              Breakpoints
            </Header>
          )}
          loading={loading}
          loadingText="Loading breakpoint info..."
          columnDefinitions={[
            {
              id: 'quantity',
              header: 'Quantity',
              cell: (row) => row.quantity,
            },
            {
              id: 'multiplier',
              header: 'Multiplier',
              cell: (row) => row.multiplier,
            },
            {
              id: 'uncappedUnitPrice',
              header: 'Uncapped Unit Price',
              cell: (row) => Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                row.multiplier * (uncappedUnitPrice || 0)
            + (uncappedUnscaledUnitPrice || 0),
              ),
            },
            {
              id: 'cappedUnitPrice',
              header: 'Capped Unit Price',
              cell: (row) => Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                row.multiplier * (cappedUnitPrice || 0)
            + (cappedUnscaledUnitPrice || 0),
              ),
            },
          ]}
          items={breakpoints}
        />
      </Container>
    </>
  );
}

BreakpointsTable.propTypes = {
  loading: PropTypes.bool,
};

export default BreakpointsTable;

export { EditBreakpointsModal, BASE_BREAKPOINTS };
