import { doc, getFirestore } from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestoreDocData } from 'reactfire';

const NONEXISTENT = 'nonexistent';

const usePart = (partId) => {
  const { data: part, status } = useFirestoreDocData(doc(getFirestore(), 'parts', partId || NONEXISTENT), { idField: 'id' });
  return useMemo(() => ({
    part: part || {},
    status,
  }), [part, status]);
};

const usePartAndQuote = (partId) => {
  const { part, status: partQueryStatus } = usePart(partId);
  const quoteId = useMemo(() => part?.quote || NONEXISTENT, [part]);
  const { data: quote, status: quoteQueryStatus } = useFirestoreDocData(doc(getFirestore(), 'quotes', quoteId || NONEXISTENT), { idField: 'id' });
  const { data: partUser, status: partUserStatus } = useFirestoreDocData(doc(getFirestore(), 'users', part?.uid || NONEXISTENT), { idField: 'id' });

  const status = useMemo(() => {
    if (partQueryStatus === 'loading' || quoteQueryStatus === 'loading' || partUserStatus === 'loading') {
      return 'loading';
    }
    if (partQueryStatus === 'error' || quoteQueryStatus === 'error' || partUserStatus === 'error') {
      return 'error';
    }
    return 'success';
  }, [partQueryStatus, quoteQueryStatus, partUserStatus]);

  return useMemo(() => {
    if (!partId) {
      return {
        part: {},
        partUser: {},
        quote: {},
        status: NONEXISTENT,
      };
    }
    return {
      part: part || {},
      partUser: partUser || {},
      quote: quote || {},
      status,
    };
  }, [part, quote, status, partId, partUser]);
};

export default usePartAndQuote;

export { usePart };
