import {
  Button,
  Header,
  Table,
} from '@cloudscape-design/components';
import {
  collection,
  getFirestore,
  orderBy,
  query,
} from 'firebase/firestore';
import React, { useMemo, useState } from 'react';
import { useFirestoreCollectionData } from 'reactfire';

import AddProjectModal from '../../components/Projects/addProjectModal';

export default function CustomerProjectsList() {
  const [openAddProjectModal, setOpenAddProjectModal] = useState(false);

  const { status, data: customerProjects = [] } = useFirestoreCollectionData(
    query(collection(getFirestore(), 'projects'), orderBy('name', 'desc')),
  );

  const projectRows = useMemo(() => customerProjects.map((project) => ({
    ...project,
    filename: (
      <span style={{ whiteSpace: 'normal' }}>{project.filename}</span>
    ),
    notes: (
      <span style={{ whiteSpace: 'normal' }}>{project.notes}</span>
    ),
    linkButton: (
      <Button
        variant="inline-link"
        target="_blank"
        href={project.adminLink}
      >
        {(/.*\/part\/([^/]+)/).test(project.adminLink) ? project.adminLink?.split('/').pop() : project.adminLink}
      </Button>
    ),
  })), [customerProjects]);

  return (
    <>
      <Table
        header={(
          <Header
            variant="awsui-h1-sticky"
            actions={(
              <Button onClick={() => setOpenAddProjectModal(true)}>
                Create a Customer Project
              </Button>
            )}
          >
            Customer Projects
          </Header>
      )}
        variant="full-page"
        stickyHeader
        loading={status === 'loading'}
        items={projectRows}
        resizableColumns
        columnDefinitions={[
          {
            id: 'name',
            header: 'Project ID',
            cell: (item) => item.name,
            width: 120,
          },
          {
            id: 'filename',
            header: 'File Name',
            cell: (item) => item.filename,
          },
          {
            id: 'material',
            header: 'Material',
            cell: (item) => item.material,
          },
          {
            id: 'partId',
            header: 'Part ID',
            cell: (item) => item.linkButton,
          },
          {
            id: 'notes',
            header: 'Notes',
            cell: (item) => item.notes,
          },
        ]}
      />
      {openAddProjectModal && (
        <AddProjectModal
          projects={customerProjects}
          open={openAddProjectModal}
          onClose={() => setOpenAddProjectModal(false)}
        />
      )}
    </>
  );
}
