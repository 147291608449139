/* eslint-disable react/no-unstable-nested-components */
import {
  Alert,
  AttributeEditor,
  Box,
  Button,
  Container,
  FormField,
  Header,
  Input,
  Modal,
  Select,
  SpaceBetween,
  Table,
} from '@cloudscape-design/components';
import { useFormikContext } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import toPath from 'lodash/toPath';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as Yup from 'yup';

import { usePricingSchema } from './pricingSchemaProvider';

const BASE_LEADTIMES = [
  {
    id: 'leadtime-standard',
    multiplier: 1,
    leadtimeDays: 10,
    leadtimeText: '10 business days from receipt of PO',
    minQuantity: 5,
    maxQuantity: 100,
  },
  {
    id: 'leadtime-expedited',
    multiplier: 1.25,
    leadtimeDays: 5,
    leadtimeText: '5 business days from receipt of PO',
    minQuantity: 5,
    maxQuantity: 100,
  },
  {
    id: 'leadtime-rush',
    multiplier: 2,
    leadtimeDays: 3,
    leadtimeText: '3 business days from receipt of PO',
    minQuantity: 5,
    maxQuantity: 100,
  },
];

export const leadtimeDisplayMap = {
  'leadtime-standard': 'Standard',
  'leadtime-expedited': 'Expedited',
  'leadtime-rush': 'Rush',
};

function EditLeadtimesModal({ visible = false, close }) {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const { leadtimes: currentLeadtimes } = values;
  const [leadtimes, setLeadtimes] = useState([]);
  useEffect(() => {
    setLeadtimes(cloneDeep(currentLeadtimes));
  }, [currentLeadtimes, visible]);
  const [fieldErrors, setFieldErrors] = useState();
  const [bannerError, setBannerError] = useState();
  useEffect(() => {
    setFieldErrors(leadtimes.map(() => ({})));
    setBannerError(undefined);
  }, [leadtimes]);

  const leadtimeType = useMemo(() => {
    const isDays = leadtimes.some((leadtime) => leadtime.leadtimeDays);
    const isText = leadtimes.some((leadtime) => leadtime.leadtimeText);
    if (isDays && isText) {
      setBannerError('Leadtime type cannot be mixed between "days" and "text"');
      return null;
    }
    if (!isDays && !isText) {
      setBannerError('Leadtime type cannot be empty');
      return null;
    }
    if (isDays) return 'days';
    if (isText) return 'text';
    return 'days';
  }, [leadtimes]);

  return (
    <Modal
      header={(
        <Header
          actions={(
            <Button
              onClick={() => {
                if (leadtimeType === 'days') {
                  // If we're currently in 'days' mode, switch to 'text' mode
                  setLeadtimes((oldLeadtimes) => oldLeadtimes.map((oldLeadtime) => ({
                    ...oldLeadtime,
                    leadtimeDays: undefined,
                    leadtimeText: BASE_LEADTIMES
                      .find((baseLeadtime) => baseLeadtime.id === oldLeadtime.id)
                      .leadtimeText,
                  })));
                } else {
                  // If we're currently in 'text' mode, switch to 'days' mode
                  setLeadtimes((oldLeadtimes) => oldLeadtimes.map((oldLeadtime) => ({
                    ...oldLeadtime,
                    leadtimeDays: BASE_LEADTIMES
                      .find((baseLeadtime) => baseLeadtime.id === oldLeadtime.id)
                      .leadtimeDays,
                    leadtimeText: undefined,
                  })));
                }
              }}
            >
              Toggle Leadtime Type
            </Button>
        )}
        >
          Edit leadtimes
        </Header>
      )}
      visible={visible}
      onDismiss={close}
      size="max"
      footer={(
        <Box float="right">
          <SpaceBetween size="xs" direction="horizontal">
            <Button onClick={close}>
              Cancel
            </Button>
            <Button onClick={() => {
              // Check that at least one leadtime is present
              if (leadtimes.length < 1) {
                setBannerError('At least 1 leadtime is required');
                return;
              }

              // Validate the leadtimes
              try {
                const shape = {
                  id: Yup.string().oneOf(['leadtime-standard', 'leadtime-expedited', 'leadtime-rush']).required(),
                  multiplier: Yup.number().typeError('Multiplier is required').required('Multiplier is required'),
                  minQuantity: Yup.number().typeError('Minimum Quantity is required').required('Minimum Quantity is required').integer('Minimum Quantity must be an integer'),
                  maxQuantity: Yup.number().typeError('Maximum Quantity is required').required('Maximum Quantity is required').integer('Maximum Quantity must be an integer'),
                };
                if (leadtimeType === 'days') {
                  shape.leadtimeDays = Yup
                    .number()
                    .typeError('Leadtime days is required')
                    .required('Leadtime days is required')
                    .min(1, 'Leadtime days must be at least 1')
                    .integer('Leadtime days must be an integer');
                } else {
                  shape.leadtimeText = Yup.string().required();
                }
                const schema = Yup.array().of(Yup.object().shape(shape));
                schema.validateSync(leadtimes, { abortEarly: false });
              } catch ({ inner }) {
                const newErrors = leadtimes.map(() => ({}));
                inner.forEach(({ path, message }) => {
                  const pathArray = toPath(path);
                  newErrors[pathArray[0]][pathArray[1]] = message;
                });
                setFieldErrors(newErrors);
                return;
              }

              // Cleaned leadtimes
              const cleanedLeadtimes = [...leadtimes].sort(
                (a, b) => (
                  BASE_LEADTIMES.findIndex((baseLeadtime) => baseLeadtime.id === a.id)
                  - BASE_LEADTIMES.findIndex((baseLeadtime) => baseLeadtime.id === b.id)),
              ).map((leadtime) => {
                const cleanedLeadtime = {
                  id: leadtime.id,
                  multiplier: leadtime.multiplier,
                  minQuantity: leadtime.minQuantity,
                  maxQuantity: leadtime.maxQuantity,
                };
                if (leadtimeType === 'days') {
                  cleanedLeadtime.leadtimeDays = leadtime.leadtimeDays;
                } else {
                  cleanedLeadtime.leadtimeText = leadtime.leadtimeText;
                }
                return cleanedLeadtime;
              });

              // Update the formik values and close the modal
              setFieldValue('leadtimes', cleanedLeadtimes);
              setFieldTouched('leadtimes', true);
              close();
            }}
            >
              Update
            </Button>
          </SpaceBetween>
        </Box>
      )}
    >
      <SpaceBetween size="xl" direction="vertical">
        {bannerError ? (
          <Alert type="error">{bannerError}</Alert>
        ) : null}
        <AttributeEditor
          onAddButtonClick={() => {
            for (let i = 0; i < BASE_LEADTIMES.length; i += 1) {
              if (!leadtimes.map((leadtime) => leadtime.id).includes(BASE_LEADTIMES[i].id)) {
                const newLeadtime = { ...BASE_LEADTIMES[i] };
                if (leadtimeType === 'days') {
                  newLeadtime.leadtimeText = null;
                } else {
                  newLeadtime.leadtimeDays = null;
                }
                setLeadtimes((oldLeadtimes) => [...oldLeadtimes, newLeadtime]);
                return;
              }
            }
            setBannerError('Only one leadtime of each type is allowed');
          }}
          onRemoveButtonClick={({
            detail: { itemIndex },
          }) => {
            setLeadtimes((oldLeadtimes) => {
              const tmpCurrentLeadtimes = [...oldLeadtimes];
              tmpCurrentLeadtimes.splice(itemIndex, 1);
              return tmpCurrentLeadtimes;
            });
          }}
          items={leadtimes}
          addButtonText="Add new leadtime"
          definition={[
            {
              label: 'Delivery Type',
              control: (item, index) => {
                const options = BASE_LEADTIMES.map(({ id }) => ({
                  id,
                  label: leadtimeDisplayMap[id],
                  value: id,
                  disabled: (
                    leadtimes
                      .map((leadtime) => leadtime.id)
                      .includes(id) && id !== item.id
                  ),
                }));
                const selectedOption = options
                  .find(({ id }) => id === item.id);
                return (
                  // hack because FormField doesn't allow a width prop
                  // and the Select component changes width based on the options
                  <div style={{ width: 160 }}>
                    <FormField errorText={fieldErrors[index]?.id}>
                      <Select
                        selectedOption={selectedOption}
                        onChange={({ detail }) => {
                          setLeadtimes((oldLeadtimes) => {
                            const tmpCurrentLeadtimes = [...oldLeadtimes];
                            tmpCurrentLeadtimes[index].id = detail.selectedOption.id;
                            return tmpCurrentLeadtimes;
                          });
                        }}
                        options={options}
                      />
                    </FormField>
                  </div>
                );
              },
            },
            {
              label: 'Multiplier',
              control: (item, index) => (
                <FormField errorText={fieldErrors[index]?.multiplier}>
                  <Input
                    type="number"
                    inputMode="numeric"
                    value={item.multiplier}
                    onChange={({ detail }) => {
                      setLeadtimes((oldLeadtimes) => {
                        const tmpCurrentLeadtimes = [...oldLeadtimes];
                        tmpCurrentLeadtimes[index].multiplier = detail.value;
                        return tmpCurrentLeadtimes;
                      });
                    }}
                  />
                </FormField>
              ),
            },
            {
              label: leadtimeType === 'days' ? 'Leadtime Days' : 'Leadtime Text',
              control: (item, index) => (
                <FormField
                  errorText={fieldErrors[index]?.leadtimeDays || fieldErrors[index]?.leadtimeText}
                >
                  {leadtimeType === 'days' ? (
                    <Input
                      type="number"
                      inputMode="numeric"
                      value={item.leadtimeDays}
                      onChange={({ detail }) => {
                        setLeadtimes((oldLeadtimes) => {
                          const tmpCurrentLeadtimes = [...oldLeadtimes];
                          tmpCurrentLeadtimes[index].leadtimeDays = detail.value;
                          return tmpCurrentLeadtimes;
                        });
                      }}
                    />
                  ) : (
                    <Input
                      value={item.leadtimeText}
                      onChange={({ detail }) => {
                        setLeadtimes((oldLeadtimes) => {
                          const tmpCurrentLeadtimes = [...oldLeadtimes];
                          tmpCurrentLeadtimes[index].leadtimeText = detail.value;
                          return tmpCurrentLeadtimes;
                        });
                      }}
                    />
                  )}
                </FormField>
              ),
            },
            {
              label: 'Min Quantity',
              control: (item, index) => (
                <FormField errorText={fieldErrors[index]?.minQuantity}>
                  <Input
                    type="number"
                    inputMode="numeric"
                    value={item.minQuantity}
                    onChange={({ detail }) => {
                      setLeadtimes((oldLeadtimes) => {
                        const tmpCurrentLeadtimes = [...oldLeadtimes];
                        tmpCurrentLeadtimes[index].minQuantity = detail.value;
                        return tmpCurrentLeadtimes;
                      });
                    }}
                  />
                </FormField>
              ),
            },
            {
              label: 'Max Quantity',
              control: (item, index) => (
                <FormField errorText={fieldErrors[index]?.maxQuantity}>
                  <Input
                    type="number"
                    inputMode="numeric"
                    value={item.maxQuantity}
                    onChange={({ detail }) => {
                      setLeadtimes((oldLeadtimes) => {
                        const tmpCurrentLeadtimes = [...oldLeadtimes];
                        tmpCurrentLeadtimes[index].maxQuantity = detail.value;
                        return tmpCurrentLeadtimes;
                      });
                    }}
                  />
                </FormField>
              ),
            },
          ]}
        />
      </SpaceBetween>
    </Modal>
  );
}

EditLeadtimesModal.propTypes = {
  visible: PropTypes.bool,
  close: PropTypes.func.isRequired,
};

function LeadtimesTable({ loading = false }) {
  const { values } = useFormikContext();
  const { leadtimes } = values;

  const [editLeadtimesModalOpen, setEditLeadtimesModalOpen] = useState(false);

  const leadtimeType = useMemo(() => (leadtimes[0].leadtimeText ? 'text' : 'days'), [leadtimes]);
  const { updatePricingSchema } = usePricingSchema();
  useEffect(() => {
    const schemaShape = {
      id: Yup.string().required('Required'),
      multiplier: Yup.number().required('Required'),
      minQuantity: Yup.number().required('Required'),
      maxQuantity: Yup.number().required('Required'),
    };
    if (leadtimeType === 'days') {
      schemaShape.leadtimeDays = Yup.number().required('Required');
    }
    if (leadtimeType === 'text') {
      schemaShape.leadtimeText = Yup.string().required('Required');
    }
    updatePricingSchema('leadtimes', Yup.array(Yup.object().shape(schemaShape)));
  }, [leadtimeType, updatePricingSchema]);

  return (
    <>
      <EditLeadtimesModal
        visible={editLeadtimesModalOpen}
        close={() => setEditLeadtimesModalOpen(false)}
      />
      <Container fitHeight>
        <Table
          variant="embedded"
          header={(
            <Header
              variant="h2"
              actions={(
                <Button
                  onClick={() => { setEditLeadtimesModalOpen(true); }}
                >
                  Edit
                </Button>
            )}
            >
              Leadtimes
            </Header>
          )}
          loading={loading}
          loadingText="Loading leadtime info..."
          columnDefinitions={[{
            id: 'id',
            header: 'Delivery Type',
            cell: (item) => leadtimeDisplayMap[item.id],
          },
          {
            id: 'multiplier',
            header: 'Multiplier',
            cell: (item) => item.multiplier,
          },
          {
            id: 'leadtime',
            header: leadtimeType === 'days' ? 'Leadtime Days' : 'Leadtime Text',
            cell: (item) => (leadtimeType === 'days' ? item.leadtimeDays : item.leadtimeText),
          },
          {
            id: 'minQuantity',
            header: 'Min Quantity',
            cell: (item) => item.minQuantity,
          },
          {
            id: 'maxQuantity',
            header: 'Max Quantity',
            cell: (item) => item.maxQuantity,
          },
          ]}
          items={leadtimes}
        />
      </Container>
    </>
  );
}

LeadtimesTable.propTypes = {
  loading: PropTypes.bool,
};

export default LeadtimesTable;

export { EditLeadtimesModal, BASE_LEADTIMES };
