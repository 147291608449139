import {
  Button,
  ContentLayout,
  Header,
} from '@cloudscape-design/components';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ManageOrder } from '../../components/Orders';

export default function CreateOrderPage() {
  const navigate = useNavigate();
  return (
    <ContentLayout
      header={(
        <Header
          actions={<Button variant="normal" onClick={() => { navigate('/orders'); }}>See all orders</Button>}
        >
          Create Order
        </Header>
      )}
    >
      <ManageOrder />
    </ContentLayout>
  );
}
