import './lineItemsTable.css';

import {
  Box,
  Button,
  SpaceBetween,
} from '@cloudscape-design/components';
import PropTypes from 'prop-types';
import React from 'react';

import LineItemContent from './lineItemContent';

export default function LineItemsTable({ shipment, shipmentIndex, onNewLineItem }) {
  const { lineItems: currentLineItems = [] } = shipment;

  return (
    <SpaceBetween direction="vertical" size="s">
      {currentLineItems.map((item, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <LineItemContent key={idx} shipmentIndex={shipmentIndex} lineItemIndex={idx} />
      ))}
      <Box>
        <Button
          formAction="none"
          onClick={onNewLineItem}
        >
          Add new line item
        </Button>
      </Box>
    </SpaceBetween>
  );
}
LineItemsTable.propTypes = {
  shipment: PropTypes.shape({
    lineItems: PropTypes.arrayOf(PropTypes.shape({
      projectId: PropTypes.string,
      quantity: PropTypes.number,
      bonding: PropTypes.string,
      notes: PropTypes.string,
      steps: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string.isRequired,
        goal: PropTypes.number,
        name: PropTypes.string.isRequired,
        note: PropTypes.string,
      })),
    })),
  }).isRequired,
  shipmentIndex: PropTypes.number.isRequired,
  onNewLineItem: PropTypes.func.isRequired,
};
