/* eslint-disable max-len */
const deliveryMultiplier = {
  'leadtime-standard': 1.0,
  'leadtime-expedited': 1.5,
  'leadtime-rush': 2.0,
};

const fluidicLayerBenchmarks = {
  5: 200,
  10: 175,
  20: 150,
  100: 100,
};

const fluidicLayerBenchmarkTiers = [5, 10, 20, 100];

const cappingBenchmarks = {
  5: 100,
  10: 75,
  20: 50,
  100: 50,
};

const cappingBenchmarkTiers = [5, 10, 20, 100];

const connectorBenchmarks = {
  [5 * 4]: 25,
  [10 * 4]: 20,
  [20 * 4]: 15,
  [100 * 4]: 15,
};

const connectorBenchmarkTiers = [5 * 4, 10 * 4, 20 * 4, 100 * 4];

const unitPriceFromQuantity = (basePrice, quantity, benchmarks, tiers, delivery, newDeliveryMultiplier) => {
  let discountRatio;
  for (let i = 0; i < tiers.length - 1; i += 1) {
    if (quantity === tiers[i]) {
      discountRatio = benchmarks[quantity] / benchmarks[tiers[0]];
    }
    if (quantity > tiers[i] && quantity < tiers[i + 1]) {
      const benchmarkPrice = (
        benchmarks[tiers[i]]
            + ((benchmarks[tiers[i + 1]] - benchmarks[tiers[i]]) / (tiers[i + 1] - tiers[i])
            ) * (quantity - tiers[i])
      );
      discountRatio = benchmarkPrice / benchmarks[tiers[0]];
    }
  }
  if (!discountRatio && quantity < tiers[0]) {
    discountRatio = 1.0;
  }
  if (!discountRatio && quantity >= tiers[tiers.length - 1]) {
    discountRatio = benchmarks[tiers[tiers.length - 1]] / benchmarks[tiers[0]];
  }
  if (typeof newDeliveryMultiplier === 'object' && newDeliveryMultiplier !== null && newDeliveryMultiplier[delivery]) {
    return discountRatio * basePrice * newDeliveryMultiplier[delivery];
  }
  return discountRatio * basePrice * deliveryMultiplier[delivery];
};

const calculateUnitPriceV0 = (rates, material, capping, connectorsPerChip, quantity, delivery, newDeliveryMultiplier) => {
  const fluidicLayerSubtotal = unitPriceFromQuantity(
    rates.fluidicLayer[material],
    quantity,
    fluidicLayerBenchmarks,
    fluidicLayerBenchmarkTiers,
    delivery,
    newDeliveryMultiplier,
  );

  const cappingSubtotal = unitPriceFromQuantity(
    rates.capping[capping],
    quantity,
    cappingBenchmarks,
    cappingBenchmarkTiers,
    delivery,
    newDeliveryMultiplier,
  );

  let connectorSubtotal = 0;
  if (connectorsPerChip > 0) {
    connectorSubtotal = unitPriceFromQuantity(
      rates.connectors,
      quantity * connectorsPerChip,
      connectorBenchmarks,
      connectorBenchmarkTiers,
      delivery,
      newDeliveryMultiplier,
    );
  }
  return fluidicLayerSubtotal + cappingSubtotal + connectorSubtotal * connectorsPerChip;
};

module.exports = { calculateUnitPriceV0 };
