import { Popover, SpaceBetween, StatusIndicator } from '@cloudscape-design/components';
import { InspectionValues, ProcessStepMap } from '@parallel-fluidics/constants';
import { useQuery } from '@tanstack/react-query';
import {
  collection,
  documentId,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const InspectionStatusMap = Object.freeze({
  [InspectionValues.NOT_INSPECTED]: 'pending',
  [InspectionValues.PASSED]: 'success',
  [InspectionValues.REJECT_MAJOR]: 'error',
  [InspectionValues.REJECT_COSMETIC]: 'warning',
  [InspectionValues.TUNING]: 'info',
});

function StatusDisplay({ status }) {
  return (
    <StatusIndicator type={InspectionStatusMap[status] || 'info'}>
      {status}
    </StatusIndicator>
  );
}
StatusDisplay.propTypes = {
  status: PropTypes.string.isRequired,
};

export default function TravelerStatus({ travelerIds, process, stepIndex = 0 }) {
  const { data: travelers = [], isFetching } = useQuery({
    queryKey: ['travelers', travelerIds],
    queryFn: async () => {
      const q = query(collection(getFirestore(), 'travelers'), where(documentId(), 'in', travelerIds));
      const snapshot = await getDocs(q);
      return snapshot.docs.map((doc) => doc.data());
    },
    enabled: !!travelerIds && travelerIds.length > 0,
  });

  const inspectedStatusArr = useMemo(() => {
    if (travelers.length === 0) return [];
    if (stepIndex === 0) {
      // find the first step of the process
      return travelers.map((traveler) => {
        const step = traveler.steps.find((s) => s.type === ProcessStepMap[process]);
        return step.inspected;
      });
    }
    return travelers.map((traveler) => {
      const step = traveler.steps[stepIndex];
      return step.inspected;
    });
  }, [travelers, process, stepIndex]);

  if (isFetching) return <div>Loading...</div>;

  if (inspectedStatusArr.length === 0) return null;

  // show the status if there is only one traveler
  if (inspectedStatusArr.length === 1) {
    return (
      <StatusDisplay status={inspectedStatusArr[0]} />
    );
  }

  // show the pass rate if more than one traveler
  const passedCount = inspectedStatusArr.filter((s) => s === InspectionValues.PASSED).length;
  const passRate = Math.round((passedCount / inspectedStatusArr.length) * 100);
  return (
    <Popover
      content={(
        <SpaceBetween direction="horizontal" size="xs">
          {inspectedStatusArr.map((status, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <StatusDisplay key={i} status={status} />
          ))}
        </SpaceBetween>
      )}
      position="top"
    >
      {passRate}
      %
    </Popover>
  );
}

TravelerStatus.propTypes = {
  travelerIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  process: PropTypes.string.isRequired,
  stepIndex: PropTypes.number,
};
