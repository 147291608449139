export const formatCurrency = (value) => {
  if (value === '') return '';
  let numberValue = value;
  if (typeof value === 'string') {
    numberValue = parseFloat(value.replace(/[^0-9.-]+/g, ''));
  }
  if (Number.isNaN(numberValue)) return '';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }).format(numberValue);
};

// Return a date in the format of YYYY-MM-DD
export const formatDate = (date) => {
  const parsedDate = date instanceof Date ? date : new Date(date);
  return parsedDate.toISOString().split('T')[0];
};

export default formatCurrency;
