import {
  Button,
  FormField,
  Input,
  Popover,
  TokenGroup,
} from '@cloudscape-design/components';
import {
  collection,
  documentId,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFirestoreCollectionData } from 'reactfire';

import TravelerScanner from '.';

function TravelerTokens({ travelers, setTravelers }) {
  const { status, data: travelerData } = useFirestoreCollectionData(query(
    collection(getFirestore(), 'travelers'),
    where(documentId(), 'in', travelers),
  ), { idField: 'id' });
  const {
    values: { project = {} }, setFieldError,
  } = useFormikContext();

  // validate existing travelers matches project id
  useEffect(() => {
    if (!project?.value) return;
    const unmatchedTravelers = travelerData?.filter((traveler) => (
      // no need to check lineItemId, as we may have Tuning travelers from other orders
      traveler.project !== project.value
    ));
    if (unmatchedTravelers?.length > 0) {
      const errorMessage = unmatchedTravelers.map((traveler) => `Traveler(${traveler.id}) belongs to project ${traveler.project}, which does not match the selected project ${project.value}.`).join(', ');
      setFieldError('travelers', errorMessage);
    } else {
      setFieldError('travelers', null);
    }
  }, [project, setFieldError, travelerData]);

  const travelerTokens = useMemo(() => {
    if (status === 'loading') {
      return travelers.map((traveler) => ({
        label: traveler,
        dismissLabel: `Remove ${traveler}`,
        description: 'Loading info...',
      }));
    }
    const travelerMap = new Map(travelerData.map((traveler) => [traveler.id, traveler]));
    return travelers.map((traveler) => {
      if (!travelerMap.has(traveler)) {
        return {
          label: traveler,
          dismissLabel: `Remove ${traveler}`,
          description: 'New traveler!',
        };
      }
      const { steps } = travelerMap.get(traveler);
      let description = 'Existing traveler';
      // loop through steps and add to description
      steps.forEach((step) => {
        if (step.runId?.length > 0) {
          description += ` | ${step.name} run${step.runId.length > 1 ? 's' : ''}: ${step.runId.map((run) => `${run.project} - ${run.runID}`).join(', ')}`;
        }
      });
      return {
        label: traveler,
        dismissLabel: `Remove ${traveler}`,
        description,
      };
    });
  }, [status, travelerData, travelers]);

  return (
    <TokenGroup
      onDismiss={({ detail: { itemIndex } }) => {
        if (travelers.length === 1) setFieldError('travelers', null);
        setTravelers([
          ...travelers.slice(0, itemIndex),
          ...travelers.slice(itemIndex + 1),
        ]);
      }}
      items={travelerTokens}
    />
  );
}

TravelerTokens.propTypes = {
  travelers: PropTypes.arrayOf(PropTypes.string).isRequired,
  setTravelers: PropTypes.func.isRequired,
};

function TravelerInput({
  id, travelers = [], errorText = '',
}) {
  const { setFieldValue } = useFormikContext();
  const [travelerInput, setTravelerInput] = useState('');

  const handleSetTravelers = useCallback((newTravelers) => {
    setFieldValue('travelers', newTravelers);
    setFieldValue('numberOfChips', newTravelers.length);
  }, [setFieldValue]);

  const handleQRCodeSuccess = useCallback((qrCode) => {
    // update traveler list
    const travelerId = qrCode.replace('https://admin.parallelfluidics.com/travelers/', '');
    if (!travelers.includes(travelerId)) {
      handleSetTravelers([...travelers, travelerId]);
    }

    // very hacky way to close the popover until this is resolved
    // https://github.com/cloudscape-design/components/issues/1995
    const popoverField = document.querySelector(`[data-popover-id='${id}']`);
    if (!popoverField) {
      return;
    }
    const popoverCloseButton = popoverField.querySelector('[aria-label="Close popover"]');
    if (!popoverCloseButton) {
      return;
    }
    popoverCloseButton.click();
  }, [id, handleSetTravelers, travelers]);

  return (
    <>
      <FormField
        data-popover-id={id}
        label="Traveler(s)"
        secondaryControl={(
          <Popover
            position="bottom"
            size="large"
            triggerType="custom"
            header="Scan traveler"
            fixedWidth
            content={(
              <TravelerScanner
                id={id}
                onQRCodeSuccess={handleQRCodeSuccess}
              />
            )}
          >
            <Button iconName="search" />
          </Popover>
        )}
        errorText={errorText}
      >
        <Input
          onChange={({ detail }) => setTravelerInput(detail.value)}
          value={travelerInput}
          onKeyDown={(e) => {
            if (e?.detail?.key === 'Enter') {
              if (travelerInput.length > 0 && !travelers.includes(travelerInput)) {
                handleSetTravelers([...travelers, travelerInput]);
              }
              setTravelerInput('');
            }
          }}
        />
      </FormField>
      {travelers.length > 0
        ? <TravelerTokens travelers={travelers} setTravelers={handleSetTravelers} />
        : null}
    </>
  );
}

TravelerInput.propTypes = {
  id: PropTypes.string.isRequired,
  travelers: PropTypes.arrayOf(PropTypes.string),
  errorText: PropTypes.string,
};

export default TravelerInput;
