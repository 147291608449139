import {
  Box,
  ColumnLayout,
  Grid,
  Header,
} from '@cloudscape-design/components';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { usePLCValue } from '../../../features/plc/context';
import {
  useHFTSHandle,
  useProgramHandle,
} from '../hooks/context';
import PLCNumberInput from './plcNumberInput';
import PLCToggleButton from './plcToggleButton';
import PLCValue from './plcValue';

const disengagedTempCalculations = {
  Upper: {
    front: (value) => parseFloat((1.5702 * value - 97.4825).toFixed(1)),
    rear: (value) => parseFloat((value + 15).toFixed(1)),
  },
  Lower: {
    front: (value) => parseFloat((1.5000 * value - 42.5).toFixed(1)),
    rear: (value) => value,
  },
  ChipSide: {
    front: (value) => parseFloat((1.5702 * value - 97.4825).toFixed(1)),
    rear: (value) => parseFloat((value + 15).toFixed(1)),
  },
  CapSide: {
    front: (value) => parseFloat((1.5000 * value - 42.5).toFixed(1)),
    rear: (value) => value,
  },
};

function HFTS({ side }) {
  const programHandle = useProgramHandle();
  const hftsHandle = useHFTSHandle(side);

  const frontDisengagedSetpoint = usePLCValue(`${hftsHandle}.fFrontHeaterEngagedSetpoint`);
  const suggestedFrontDisengagedSetpoint = useMemo(
    () => disengagedTempCalculations[side].front(frontDisengagedSetpoint),
    [frontDisengagedSetpoint],
  );

  const rearDisengagedSetpoint = usePLCValue(`${hftsHandle}.fRearHeaterEngagedSetpoint`);
  const suggestedRearDisengagedSetpoint = useMemo(
    () => disengagedTempCalculations[side].rear(rearDisengagedSetpoint),
    [rearDisengagedSetpoint],
  );

  return (
    <ColumnLayout columns={3}>
      <Grid gridDefinition={[
        { colspan: 12 },
        { colspan: 12 },
        { colspan: 12 },
        { colspan: 12 },
      ]}
      >
        <Header variant="h3">Hot Block heater</Header>
        <PLCToggleButton
          handle={`${programHandle}.b${side}HotBlockHeaterSystemOn`}
          label="heater"
          fullWidth
        />
        <PLCNumberInput
          label="Setpoint"
          description={<br />}
          handle={`${hftsHandle}.fHotBlockSetpoint`}
          type="float"
        />
        <div>
          <Box variant="awsui-key-label">Current temp</Box>
          <PLCValue handle={`${programHandle}.${side}HotRTD`} format={(value) => `${value / 10} °C`} />
        </div>
      </Grid>
      <Grid gridDefinition={[
        { colspan: 12 },
        { colspan: 12 },
        { colspan: 12 },
        { colspan: 12 },
        { colspan: 12 },
        { colspan: 6 }, { colspan: 6 },
      ]}
      >
        <Header variant="h3">Front Block heater</Header>
        <PLCToggleButton
          handle={`${programHandle}.b${side}FrontHeaterSystemOn`}
          label="heater"
          fullWidth
        />
        <PLCNumberInput
          label="Disengaged Setpoint"
          description={`Suggested: ${suggestedFrontDisengagedSetpoint}°C`}
          handle={`${hftsHandle}.fFrontHeaterDisengagedSetpoint`}
          type="float"
        />
        <div>
          <Box variant="awsui-key-label">Current temp</Box>
          <PLCValue handle={`${programHandle}.${side}FrontRTD`} format={(value) => `${value / 10} °C`} />
        </div>
        <PLCNumberInput
          label="Tool Setpoint"
          handle={`${hftsHandle}.fFrontHeaterEngagedSetpoint`}
          type="float"
        />
        <PLCNumberInput
          label="Lower Band"
          handle={`${hftsHandle}.fSetpointLowerBand`}
          type="float"
        />
        <PLCNumberInput
          label="Upper Band"
          handle={`${hftsHandle}.fSetpointUpperBand`}
          type="float"
        />
      </Grid>
      <Grid gridDefinition={[
        { colspan: 12 },
        { colspan: 12 },
        { colspan: 12 },
        { colspan: 12 },
        { colspan: 12 },
        { colspan: 6 }, { colspan: 6 },
      ]}
      >
        <Header variant="h3">Rear (Standby) Block heater</Header>
        <PLCToggleButton
          handle={`${programHandle}.b${side}RearHeaterSystemOn`}
          label="heater"
          fullWidth
        />
        <PLCNumberInput
          handle={`${hftsHandle}.fRearHeaterDisengagedSetpoint`}
          label="Disengaged Setpoint"
          description={`Suggested: ${suggestedRearDisengagedSetpoint}°C`}
          type="float"
        />
        <div>
          <Box variant="awsui-key-label">Current temp</Box>
          <PLCValue handle={`${programHandle}.${side}RearRTD`} format={(value) => `${value / 10} °C`} />
        </div>
        <PLCNumberInput
          label="Tool Standby"
          handle={`${hftsHandle}.fRearHeaterEngagedSetpoint`}
          type="float"
        />
        <PLCNumberInput
          label="Lower Band"
          handle={`${hftsHandle}.fStandbyLowerBand`}
          type="float"
        />
        <PLCNumberInput
          label="Upper Band"
          handle={`${hftsHandle}.fStandbyUpperBand`}
          type="float"
        />
      </Grid>
    </ColumnLayout>
  );
}

HFTS.propTypes = {
  side: PropTypes.oneOf(['Upper', 'Lower', 'ChipSide', 'CapSide']).isRequired,
};

export default HFTS;
export { disengagedTempCalculations };
