import { useQuery } from '@tanstack/react-query';
import {
  doc,
  getDoc,
  getFirestore,
} from 'firebase/firestore';

export const useGetOrgById = (orgId) => useQuery({
  queryKey: ['getOrgData', orgId],
  queryFn: async () => {
    const orgDocRef = doc(getFirestore(), 'organizations', orgId);
    const orgDoc = await getDoc(orgDocRef);
    if (orgDoc.exists()) {
      return {
        id: orgDoc.id,
        ...orgDoc.data(),
      };
    }
    throw new Error('Organization not found');
  },
  enabled: !!orgId,
  staleTime: 10 * 60 * 1000,
});

export const useGetUserById = (userId) => useQuery({
  queryKey: ['getUserData', userId],
  queryFn: async () => {
    const userDocRef = doc(getFirestore(), 'users', userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      return {
        id: userDoc.id,
        ...userDoc.data(),
      };
    }
    throw new Error('User not found');
  },
  enabled: !!userId,
  staleTime: 10 * 60 * 1000, // Cache data for 10 minutes
});

export default useGetOrgById;
