import {
  Table,
} from '@cloudscape-design/components';
import { Query } from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useFirestoreCollectionData } from 'reactfire';

import QuoteStatusTag from '../QuoteStatusTag';
import UserEmail from '../UserEmail';

function PartsList({
  partsQuery,
  header = null,
  variant = 'container',
}) {
  const { data: parts, status } = useFirestoreCollectionData(
    partsQuery,
    { idField: 'id', initialData: [] },
  );

  const rowData = useMemo(() => parts.map((part) => {
    const uploadedAt = new Date(part.uploadedAt);
    const dd = String(uploadedAt.getDate()).padStart(2, '0');
    const mm = String(uploadedAt.getMonth() + 1).padStart(2, '0');
    const yyyy = uploadedAt.getFullYear();
    return {
      id: part.id,
      idDisplay: `#${part.id.substring(0, 6).toUpperCase()}`,
      filename: <Link to={`/part/${part.id}`}>{part.filename}</Link>,
      status: <QuoteStatusTag partId={part.id} />,
      user: part.uid ? <UserEmail userID={part.uid} backupEmail={part.email} /> : 'Loading...',
      uploadedAt: `${yyyy}-${mm}-${dd}`,
      viewLiveButton: (
        <Link
          to={`${process.env.REACT_APP_WWW_URL}/parts/view?quote=${part.id}`}
          target="_blank"
        >
          View live
        </Link>
      ),
    };
  }), [parts]);

  return (
    <Table
      header={header}
      variant={variant}
      loading={status === 'loading'}
      items={rowData}
      stickyHeader={variant === 'full-page'}
      columnDefinitions={[
        {
          id: 'idDisplay',
          cell: (item) => item.idDisplay,
          header: 'Quote ID',
        },
        {
          id: 'filename',
          cell: (item) => item.filename,
          header: 'Filename',
          minWidth: '120px',
          maxWidth: '240px',
        },
        {
          id: 'status',
          cell: (item) => item.status,
          header: 'Status',
          minWidth: '120px',
        },
        {
          id: 'uploadedAt',
          cell: (item) => item.uploadedAt,
          header: 'Upload Date',
          minWidth: '120px',
        },
        {
          id: 'user',
          cell: (item) => item.user,
          header: 'User',
          minWidth: '120px',
        },
        {
          id: 'viewLiveButton',
          cell: (item) => item.viewLiveButton,
          header: 'View',
          minWidth: '120px',
        },
      ]}
    />
  );
}

PartsList.propTypes = {
  partsQuery: PropTypes.instanceOf(Query).isRequired,
  header: PropTypes.node,
  variant: PropTypes.string,
};

export default PartsList;
