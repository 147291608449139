const PressOrientation = Object.freeze({
  CapSideDown: 0,
  CapSideUp: 1,
  toString(value) {
    switch (value) {
      case this.CapSideDown:
        return 'Cap Side Down';
      case this.CapSideUp:
        return 'Cap Side Up';
      default:
        return `Unknown value: ${value}`;
    }
  },
});

const PressOrientationItems = Object.freeze([
  {
    id: PressOrientation.CapSideDown,
    text: PressOrientation.toString(PressOrientation.CapSideDown),
  },
  {
    id: PressOrientation.CapSideUp,
    text: PressOrientation.toString(PressOrientation.CapSideUp),
  },
]);

export { PressOrientation, PressOrientationItems };
