import {
  Button,
  ButtonDropdown,
  Container,
  Grid,
  Header,
  Popover,
  SpaceBetween,
  StatusIndicator,
} from '@cloudscape-design/components';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { usePLCValue, usePLCWrite } from '../../../features/plc/context';
import FirebaseSession from '../components/firebaseSession';
import LoadConfigModal from '../components/loadConfig';
import PLCButton from '../components/plcButton';
import StartAutomationModal from '../components/startModal';
import TempControl from '../components/tempControl';
import { useHFTSHandle, useProgramHandle } from '../hooks/context';
import useDownloadConfig from '../hooks/useDownloadConfig';
import LoadingTempModal from './loadingTemp';
import SetupConfigModal from './setupModal';

function EnableAutomationButton({ openStartAutomationModal }) {
  const write = usePLCWrite();
  const programHandle = useProgramHandle();

  const automationEnabledHandle = useMemo(() => `${programHandle}.bProcessAutomationEnabled`, [programHandle]);
  const automationEnabled = usePLCValue(automationEnabledHandle);

  const capSideHotBlockHeaterSystemOn = usePLCValue(`${programHandle}.bCapSideHotBlockHeaterSystemOn`);
  const capSideRearHeaterSystemOn = usePLCValue(`${programHandle}.bCapSideRearHeaterSystemOn`);
  const capSideFrontHeaterSystemOn = usePLCValue(`${programHandle}.bCapSideFrontHeaterSystemOn`);
  const chipSideHotBlockHeaterSystemOn = usePLCValue(`${programHandle}.bChipSideHotBlockHeaterSystemOn`);
  const chipSideRearHeaterSystemOn = usePLCValue(`${programHandle}.bChipSideRearHeaterSystemOn`);
  const chipSideFrontHeaterSystemOn = usePLCValue(`${programHandle}.bChipSideFrontHeaterSystemOn`);

  const allHeatersOn = useMemo(() => [
    capSideHotBlockHeaterSystemOn,
    capSideRearHeaterSystemOn,
    capSideFrontHeaterSystemOn,
    chipSideHotBlockHeaterSystemOn,
    chipSideRearHeaterSystemOn,
    chipSideFrontHeaterSystemOn,
  ].every((heater) => heater), [
    capSideHotBlockHeaterSystemOn,
    capSideRearHeaterSystemOn,
    capSideFrontHeaterSystemOn,
    chipSideHotBlockHeaterSystemOn,
    chipSideRearHeaterSystemOn,
    chipSideFrontHeaterSystemOn,
  ]);

  const airStatus = usePLCValue(`${programHandle}.AirStatus`);
  const chillerStatus = usePLCValue(`${programHandle}.ChillerStatus`);

  const automationButtonsDisabled = useMemo(
    () => !airStatus || !chillerStatus,
    [airStatus, chillerStatus],
  );

  if (automationEnabled) {
    return (
      <Button
        onClick={() => { write({ handle: automationEnabledHandle, value: false }); }}
        variant="primary"
      >
        Disable automation
      </Button>
    );
  }

  if (automationButtonsDisabled || !allHeatersOn) {
    return (
      <Popover
        dismissButton={false}
        size="small"
        triggerType="custom"
        content={(
          <StatusIndicator type="error">
            {automationButtonsDisabled ? 'Air or chiller is off' : 'Not all heaters are on'}
          </StatusIndicator>
      )}
      >
        <Button>Enable automation</Button>
      </Popover>
    );
  }

  return (
    <Button onClick={openStartAutomationModal}>Enable automation</Button>
  );
}

EnableAutomationButton.propTypes = {
  openStartAutomationModal: PropTypes.func.isRequired,
};

function AutomationControls() {
  const write = usePLCWrite();

  const programHandle = useProgramHandle();
  const capSideHFTSHandle = useHFTSHandle('CapSide');
  const chipSideHFTSHandle = useHFTSHandle('ChipSide');
  const airStatus = usePLCValue(`${programHandle}.AirStatus`);
  const chillerStatus = usePLCValue(`${programHandle}.ChillerStatus`);

  const automationButtonsDisabled = useMemo(
    () => !airStatus || !chillerStatus,
    [airStatus, chillerStatus],
  );

  const [isStartModalOpen, setIsStartModalOpen] = useState(false);
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const [isSetupModalOpen, setIsSetupModalOpen] = useState(false);
  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);

  const { configIsLoading, download } = useDownloadConfig({ process: 'bonding' });

  return (
    <Container header={(
      <Header
        actions={(
          <SpaceBetween direction="horizontal" size="xs">
            <ButtonDropdown
              items={[
                {
                  text: 'Initialize configuration',
                  id: 'initialize',
                },
                {
                  text: 'Load previous configuration',
                  id: 'load',
                },
                {
                  text: 'Download configuration',
                  id: 'download',
                  disabled: configIsLoading,
                  disabledReason: 'Configuration info currently loading',
                },
              ]}
              onItemClick={({ detail }) => {
                switch (detail.id) {
                  case 'initialize':
                    setIsSetupModalOpen(true);
                    break;
                  case 'load':
                    setIsConfigModalOpen(true);
                    break;
                  case 'download':
                    download();
                    break;
                  default:
                }
              }}
            >
              Configuration
            </ButtonDropdown>
            {automationButtonsDisabled ? (
              <Popover
                dismissButton={false}
                size="small"
                triggerType="custom"
                content={<StatusIndicator type="error">Air or chiller is off</StatusIndicator>}
              >
                <Button>
                  Go to loading temps
                </Button>
              </Popover>
            ) : (
              <Button onClick={() => { setIsLoadingModalOpen(true); }}>Go to loading temps</Button>
            )}
            <PLCButton
              onClick={() => {
                write({ handle: `${programHandle}.bCapSideHotBlockHeaterSystemOn`, value: false });
                write({ handle: `${programHandle}.bCapSideFrontHeaterSystemOn`, value: false });
                write({ handle: `${programHandle}.bCapSideRearHeaterSystemOn`, value: false });
                write({ handle: `${programHandle}.bChipSideHotBlockHeaterSystemOn`, value: false });
                write({ handle: `${programHandle}.bChipSideFrontHeaterSystemOn`, value: false });
                write({ handle: `${programHandle}.bChipSideRearHeaterSystemOn`, value: false });

                write({ handle: `${capSideHFTSHandle}.eAutomationState`, value: 0 });
                write({ handle: `${chipSideHFTSHandle}.eAutomationState`, value: 0 });
              }}
              kind="danger"
            >
              Turn all heaters off
            </PLCButton>
            <EnableAutomationButton openStartAutomationModal={() => setIsStartModalOpen(true)} />
          </SpaceBetween>
          )}
      >
        Automation
      </Header>
      )}
    >
      <StartAutomationModal
        isOpen={isStartModalOpen}
        close={() => { setIsStartModalOpen(false); }}
        process="bonding"
      />
      <LoadingTempModal
        isOpen={isLoadingModalOpen}
        close={() => { setIsLoadingModalOpen(false); }}
      />
      <LoadConfigModal
        isOpen={isConfigModalOpen}
        close={() => { setIsConfigModalOpen(false); }}
        process="bonding"
      />
      <SetupConfigModal
        isOpen={isSetupModalOpen}
        close={() => { setIsSetupModalOpen(false); }}
      />
      <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
        <TempControl side="ChipSide" />
        <TempControl side="CapSide" />
        <FirebaseSession />
      </Grid>
    </Container>
  );
}

export default AutomationControls;
