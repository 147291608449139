import {
  Button,
  Header,
} from '@cloudscape-design/components';
import {
  collection,
  getFirestore,
  orderBy,
  query,
} from 'firebase/firestore';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  OrderList,
} from '../../components/Orders';
import OrderDetailProvider from '../../components/Orders/orderDetailProvider';

function OrderListPage() {
  const navigate = useNavigate();
  const recentOrdersQuery = useMemo(() => query(
    collection(getFirestore(), 'orders'),
    orderBy('orderDate', 'desc'),
  ), []);

  return (
    <OrderDetailProvider>
      <OrderList
        header={(
          <Header
            variant="awsui-h1-sticky"
            actions={
              <Button onClick={() => { navigate('/orders/create'); }}>Create Order</Button>
            }
          >
            Orders
          </Header>
        )}
        variant="full-page"
        ordersQuery={recentOrdersQuery}
      />
    </OrderDetailProvider>
  );
}

export default OrderListPage;
