import {
  FormField,
  Select,
} from '@cloudscape-design/components';
import {
  collection,
  getFirestore,
  query,
} from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, {
  useMemo,
  useState,
} from 'react';
import { useFirestoreCollectionData } from 'reactfire';

import AddOrganizationModal from './addOrganizationModal';

function OrganizationSelect({
  selectedOrgId = null,
  onOrgChange,
  defaultDomain = '',
  label = 'Organization',
  errorText = '',
  disabled = false,
}) {
  const { data: organizations = [] } = useFirestoreCollectionData(
    query(
      collection(getFirestore(), 'organizations'),
    ),
    { idField: 'id' },
  );

  const [openAddOrgModal, setOpenAddOrgModal] = useState(false);

  const orgOptions = useMemo(() => organizations.map((org) => ({
    value: org.id,
    label: `${org.name}${org.domains?.length > 0 ? ` (${org.domains.join(', ')})` : ''} `,
  })), [organizations]);

  const selectedOrg = useMemo(
    () => orgOptions.find((org) => org.value === selectedOrgId),
    [orgOptions, selectedOrgId],
  );

  return (
    <>
      <FormField
        label={label}
        errorText={errorText}
        constraintText={!disabled && (
          <span>
            {'Don\'t see the organization you\'re looking for? Add organizations '}
            <a href="#!" onClick={(e) => { e.preventDefault(); setOpenAddOrgModal(true); }}>here</a>
          </span>
        )}
        stretch
      >
        <Select
          disabled={disabled}
          filteringType="auto"
          selectedOption={selectedOrg}
          options={orgOptions}
          onChange={({ detail }) => {
            onOrgChange(detail.selectedOption.value);
          }}
        />
      </FormField>
      {openAddOrgModal && (
        <AddOrganizationModal
          organizations={organizations}
          open={openAddOrgModal}
          defaultDomain={defaultDomain}
          onClose={() => setOpenAddOrgModal(false)}
        />
      )}
    </>
  );
}

OrganizationSelect.propTypes = {
  onOrgChange: PropTypes.func.isRequired,
  selectedOrgId: PropTypes.string,
  defaultDomain: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
};

export default OrganizationSelect;
