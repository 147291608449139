import {
  Header,
  SpaceBetween,
} from '@cloudscape-design/components';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { useProgramHandle } from '../hooks/context';
import PLCNumberInput from './plcNumberInput';
import PLCTimeInput from './plcTimeInput';

function PIDValues({
  sideHandle,
  heaterHandle,
  engaged = false,
}) {
  const programHandle = useProgramHandle();
  const handleBase = useMemo(() => `${programHandle}.st${sideHandle}${heaterHandle}Heater${engaged ? 'Engaged' : 'Disengaged'}ParaControllerExternal`, [programHandle, sideHandle, heaterHandle, engaged]);

  return (
    <SpaceBetween size="xs">
      <Header variant="h3">
        {`${sideHandle} ${heaterHandle} PID (when ${engaged ? 'engaged' : 'disengaged'})`}
      </Header>
      <PLCNumberInput
        label="KpHeat"
        handle={`${handleBase}.KpHeat`}
        type="float"
      />
      <PLCTimeInput
        label="TnHeat"
        handle={`${handleBase}.TnHeat`}
      />
      <PLCTimeInput
        label="TvHeat"
        handle={`${handleBase}.TvHeat`}
      />
      <PLCTimeInput
        label="TdHeat"
        handle={`${handleBase}.TdHeat`}
      />
    </SpaceBetween>
  );
}

PIDValues.propTypes = {
  sideHandle: PropTypes.string.isRequired,
  heaterHandle: PropTypes.string.isRequired,
  engaged: PropTypes.bool,
};

export default PIDValues;
