import {
  Button,
  ButtonDropdown,
  Container,
  Grid,
  Header,
  Popover,
  SpaceBetween,
  StatusIndicator,
} from '@cloudscape-design/components';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { usePLCValue, usePLCWrite } from '../../../features/plc/context';
import FirebaseSession from '../components/firebaseSession';
import LoadConfigModal from '../components/loadConfig';
import PLCTimeInput from '../components/plcTimeInput';
import StartAutomationModal from '../components/startModal';
import TempControl from '../components/tempControl';
import { usePressHandle, useProgramHandle } from '../hooks/context';
import useDownloadConfig from '../hooks/useDownloadConfig';

function EnableAutomationButton({ openStartAutomationModal }) {
  const write = usePLCWrite();
  const programHandle = useProgramHandle();
  const pressHandle = usePressHandle();

  const automationEnabledHandle = useMemo(() => `${programHandle}.bProcessAutomationEnabled`, [pressHandle]);
  const automationEnabled = usePLCValue(automationEnabledHandle);

  const lowerHotBlockHeaterSystemOn = usePLCValue(`${programHandle}.bLowerHotBlockHeaterSystemOn`);
  const lowerRearHeaterSystemOn = usePLCValue(`${programHandle}.bLowerRearHeaterSystemOn`);
  const lowerFrontHeaterSystemOn = usePLCValue(`${programHandle}.bLowerFrontHeaterSystemOn`);
  const upperHotBlockHeaterSystemOn = usePLCValue(`${programHandle}.bUpperHotBlockHeaterSystemOn`);
  const upperRearHeaterSystemOn = usePLCValue(`${programHandle}.bUpperRearHeaterSystemOn`);
  const upperFrontHeaterSystemOn = usePLCValue(`${programHandle}.bUpperFrontHeaterSystemOn`);

  const allHeatersOn = useMemo(() => [
    lowerHotBlockHeaterSystemOn,
    lowerRearHeaterSystemOn,
    lowerFrontHeaterSystemOn,
    upperHotBlockHeaterSystemOn,
    upperRearHeaterSystemOn,
    upperFrontHeaterSystemOn,
  ].every((heater) => heater), [
    lowerHotBlockHeaterSystemOn,
    lowerRearHeaterSystemOn,
    lowerFrontHeaterSystemOn,
    upperHotBlockHeaterSystemOn,
    upperRearHeaterSystemOn,
    upperFrontHeaterSystemOn,
  ]);

  const airStatus = usePLCValue(`${programHandle}.AirStatus`);
  const chillerStatus = usePLCValue(`${programHandle}.ChillerStatus`);

  const automationButtonsDisabled = useMemo(
    () => !airStatus || !chillerStatus,
    [airStatus, chillerStatus],
  );

  if (automationEnabled) {
    return (
      <Button
        onClick={() => { write({ handle: automationEnabledHandle, value: false }); }}
        variant="primary"
      >
        Disable automation
      </Button>
    );
  }

  if (automationButtonsDisabled || !allHeatersOn) {
    return (
      <Popover
        dismissButton={false}
        size="small"
        triggerType="custom"
        content={(
          <StatusIndicator type="error">
            {automationButtonsDisabled ? 'Air or chiller is off' : 'Not all heaters are on'}
          </StatusIndicator>
      )}
      >
        <Button>Enable automation</Button>
      </Popover>
    );
  }

  return (
    <Button onClick={openStartAutomationModal}>Enable automation</Button>
  );
}

EnableAutomationButton.propTypes = {
  openStartAutomationModal: PropTypes.func.isRequired,
};

function AutomationControls() {
  const programHandle = useProgramHandle();

  const [isStartModalOpen, setIsStartModalOpen] = useState(false);
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);

  const { configIsLoading, download } = useDownloadConfig({ process: 'bonding' });

  return (
    <Container header={(
      <Header
        actions={(
          <SpaceBetween direction="horizontal" size="xs">
            <ButtonDropdown
              items={[
                {
                  text: 'Load previous configuration',
                  id: 'load',
                },
                {
                  text: 'Download configuration',
                  id: 'download',
                  disabled: configIsLoading,
                  disabledReason: 'Configuration info currently loading',
                },
              ]}
              onItemClick={({ detail }) => {
                switch (detail.id) {
                  case 'load':
                    setIsConfigModalOpen(true);
                    break;
                  case 'download':
                    download();
                    break;
                  default:
                }
              }}
            >
              Configuration
            </ButtonDropdown>
            <EnableAutomationButton openStartAutomationModal={() => setIsStartModalOpen(true)} />
          </SpaceBetween>
        )}
      >
        Automation
      </Header>
    )}
    >
      <StartAutomationModal
        isOpen={isStartModalOpen}
        close={() => { setIsStartModalOpen(false); }}
        process="forming"
      />
      <LoadConfigModal
        isOpen={isConfigModalOpen}
        close={() => { setIsConfigModalOpen(false); }}
        process="forming"
      />
      <Grid gridDefinition={[{ colspan: 3 }, { colspan: 3 }, { colspan: 3 }, { colspan: 3 }]}>
        <TempControl side="Upper" />
        <TempControl side="Lower" />
        <SpaceBetween direction="horizontal" size="xs">
          <PLCTimeInput
            label="Presoak Time"
            handle={`${programHandle}.tPresoakDelayTime`}
          />
          <PLCTimeInput
            label="Coolsoak Time"
            handle={`${programHandle}.tCoolsoakDelayTime`}
          />
        </SpaceBetween>
        <FirebaseSession />
      </Grid>
    </Container>
  );
}

export default AutomationControls;
