import {
  Container,
  ContentLayout,
  Header,
  SpaceBetween,
  StatusIndicator,
} from '@cloudscape-design/components';
import React from 'react';

import ProcessGraph from '../../components/ProcessData/graph';
import Overview from '../../components/ProcessData/overview';
import { useProcessData } from '../../components/ProcessData/processDataProvider';

export default function ProcessDataPage() {
  const { session, status, sessionId } = useProcessData();

  if (status === 'loading') {
    return (
      <ContentLayout header={<Header>Process data</Header>}>
        <Container>
          <StatusIndicator type="loading">Loading...</StatusIndicator>
        </Container>
      </ContentLayout>
    );
  }

  if (!sessionId || !session) {
    return (
      <ContentLayout header={<Header>Process data</Header>}>
        <Container>
          <StatusIndicator type="error">Invalid session ID</StatusIndicator>
        </Container>
      </ContentLayout>
    );
  }

  return (
    <ContentLayout header={<Header>Process data</Header>}>
      <SpaceBetween direction="vertical" size="l">
        <Overview />
        <ProcessGraph />
      </SpaceBetween>
    </ContentLayout>
  );
}
