const calculate = ({ quote, options }) => {
  const {
    cappingStyle,
    quantity,
    delivery,
  } = options;

  const dynamicPrice = ({
    minPrice, maxPrice, minQty, maxQty, qty,
  }) => {
    const slope = (minPrice - maxPrice) / (maxQty - minQty);
    return maxPrice + (qty - minQty) * slope;
  };

  const multiple = (quote.pricingV2.multiple || {
    'leadtime-standard': 1,
    'leadtime-expedited': 1.25,
    'leadtime-rush': 2,
  })[delivery] || 1;

  const leadtimeDays = (quote.pricingV2.leadtimeDays || {
    'leadtime-standard': 10,
    'leadtime-expedited': 5,
    'leadtime-rush': 3,
  })[delivery];

  let leadtimeText;
  if (quote.pricingV2.leadtimeText) {
    leadtimeText = quote.pricingV2.leadtimeText[delivery];
  }

  const isCapped = !(cappingStyle === 'uncapped');
  const setupPrice = (isCapped
    ? quote.pricingV2.setupPrice.capped * multiple
    : quote.pricingV2.setupPrice.uncapped * multiple
  );

  // make sure pricing tiers are sorted
  const pricingLevels = [...quote.pricingV2.pricingLevels].sort((a, b) => a.quantity - b.quantity);
  let baseUnitPrice;
  for (let i = 0; i < pricingLevels.length - 1; i += 1) {
    const lowerBound = pricingLevels[i];
    const upperBound = pricingLevels[i + 1];

    if (quantity >= lowerBound.quantity && quantity <= upperBound.quantity) {
      baseUnitPrice = dynamicPrice({
        maxPrice: isCapped ? lowerBound.price.capped : lowerBound.price.uncapped,
        minPrice: isCapped ? upperBound.price.capped : upperBound.price.uncapped,
        minQty: lowerBound.quantity,
        maxQty: upperBound.quantity,
        qty: quantity,
      });
    }
  }

  const unitPrice = multiple * baseUnitPrice;

  // find any apply any discounts
  let discount = 0;
  const optionalFeedback = quote.optionalFeedback || [];
  const feedbackResponses = quote.feedbackResponses || {};
  for (let i = 0; i < optionalFeedback.length; i += 1) {
    const feedback = optionalFeedback[i];
    const response = feedbackResponses[feedback.title];
    if (response) {
      const option = feedback.options.find((o) => o.value === response);
      if (option) {
        discount += option.discount || 0;
      }
    }
  }
  discount *= multiple;

  if (leadtimeText) {
    return {
      leadtimeText, setupPrice, unitPrice, discount,
    };
  }
  return {
    leadtimeDays, setupPrice, unitPrice, discount,
  };
};

module.exports = { calculate };
