import {
  Button,
  ContentLayout,
  Header,
  SpaceBetween,
} from '@cloudscape-design/components';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import ActiveOrderStatus, { useShippedProjects } from '../../components/ActiveOrders';

function ShippedOrdersPage() {
  const shippedOrders = useShippedProjects();
  const navigate = useNavigate();
  return (
    <ContentLayout header={(
      <Header
        actions={<Button variant="normal" onClick={() => { navigate('/orders/active'); }}>See active orders</Button>}
      >
        Past Orders
      </Header>
    )}
    >
      <SpaceBetween direction="vertical" size="l">
        {shippedOrders.map((order) => (
          <ActiveOrderStatus
            key={order.lineItemId || order.projectNumber}
            lineItemId={order.lineItemId || order.projectNumber}
            organization={order.organization}
            projectNumber={order.projectNumber}
            shipDate={order.shipDate}
            shipDateActual={order.shipDateActual}
            steps={order.steps}
          />
        ))}
      </SpaceBetween>
    </ContentLayout>
  );
}

export default ShippedOrdersPage;
