import {
  FormField,
  Input,
} from '@cloudscape-design/components';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { useGetUserById } from '../../../hooks/use-basicQueries';

export default function UIDInput({ isEditing = false, setIsInvalidUID }) {
  const {
    values: { uid }, setFieldValue, errors, setFieldError,
  } = useFormikContext();
  const { data: user, isFetching, isError } = useGetUserById(uid);

  useEffect(() => {
    setFieldError('uid', isError ? 'User not found' : '');
    setIsInvalidUID(isError || isFetching);
  }, [isError, isFetching, setFieldError, setIsInvalidUID]);

  return (
    <FormField
      label="Order Requested By (User ID)"
      errorText={errors.uid}
      constraintText={isFetching ? 'Loading user...' : user?.email}
    >
      <Input
        name="uid"
        disabled={isEditing && !isError}
        value={uid}
        onChange={({ detail }) => { setFieldValue('uid', detail.value); }}
      />
    </FormField>
  );
}

UIDInput.propTypes = {
  isEditing: PropTypes.bool,
  setIsInvalidUID: PropTypes.func.isRequired,
};
