import { useCallback, useMemo } from 'react';

import { PressOrientation } from '../../../constants/pressOrientation';
import { usePLCValue, usePLCValues } from '../../../features/plc/context';
import parseTime from '../components/parseTime';
import { usePressHandle, useProgramHandle } from './context';
import useCurrentConfig from './useCurrentConfig';

function useDownloadConfig({ process }) {
  const pressHandle = usePressHandle();
  const programHandle = useProgramHandle();

  const { config, configIsLoading } = useCurrentConfig(process);
  const plcHandles = useMemo(() => {
    if (!config.handles) {
      return [];
    }
    return config.handles?.reduce((acc, { handle }) => ([
      ...acc, `${programHandle}.${handle}`]), []);
  }, [config, programHandle]);
  const plcValues = usePLCValues(plcHandles);
  const pressOrientation = usePLCValue(`MAIN.${pressHandle}_Bonding_Orientation`);

  const download = useCallback(() => {
    const values = [];
    config.rows.forEach((row) => {
      values.push({ label: '', value: '' });
      values.push({ label: row.header, value: '' });
      row.values.forEach(({ handle, label, type }) => {
        if (type === 'TIME') {
          values.push({ label, value: parseTime(plcValues[`${programHandle}.${handle}`]) });
        } else {
          values.push({ label, value: plcValues[`${programHandle}.${handle}`] });
        }
      });
    });

    if (process === 'bonding') {
      values.push({ label: 'Press Orientation', value: PressOrientation.toString(pressOrientation) });
    }

    const csv = values.map(({ label, value }) => `${label},${value}`).join('\n');
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'config.csv';
    a.click();
    URL.revokeObjectURL(url);
  }, [config, plcValues]);

  return { configIsLoading, download };
}

export default useDownloadConfig;
