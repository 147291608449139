import React from 'react';

import ManageOrder from './manageOrder';
import ManageOrderProvider from './manageOrderProvider';

export default function Manage(props) {
  return (
    <ManageOrderProvider>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <ManageOrder {...props} />
    </ManageOrderProvider>
  );
}
