import {
  Flashbar,
  SpaceBetween,
} from '@cloudscape-design/components';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import QRCodeScanner from './qrCodeScanner';

function TravelerScanner({
  id,
  onQRCodeSuccess: handleQRCodeSuccess,
}) {
  const [error, setError] = useState(null);
  const onQRCodeSuccess = useCallback((qrCode) => {
    setError(null);
    const url = new URL(qrCode);
    if (url.origin !== 'https://admin.parallelfluidics.com') {
      setError(`Invalid QR code: ${qrCode}`);
      return;
    }
    const path = url.pathname.split('/');
    if (path.length !== 3 || path[1] !== 'travelers') {
      setError(`Invalid QR code: ${qrCode}`);
      return;
    }
    handleQRCodeSuccess(qrCode);
  }, [handleQRCodeSuccess, setError]);

  return (
    <SpaceBetween size="m">
      {error ? (
        <Flashbar
          items={[{
            header: 'Error scanning traveler QR code',
            content: error,
            type: 'error',
          }]}
        />
      ) : null}
      <QRCodeScanner
        id={id}
        qrCodeSuccessCallback={onQRCodeSuccess}
        qrbox={200}
        rememberLastUsedCamera
      />
    </SpaceBetween>
  );
}

TravelerScanner.propTypes = {
  id: PropTypes.string.isRequired,
  onQRCodeSuccess: PropTypes.func.isRequired,
};

export default TravelerScanner;
