import { useMemo } from 'react';

import { usePLCValue } from '../../../features/plc/context';
import { handlify, useProcessHandle, useProgramHandle } from './context';

const useHFTSTarget = (side) => {
  const programHandle = useProgramHandle();
  const processHandle = useProcessHandle();
  const sideHandle = useMemo(() => handlify(side), [side]);

  const hftsHandle = useMemo(() => `${programHandle}.fb${sideHandle}${processHandle}HFTS`, [sideHandle, processHandle]);

  const automationState = usePLCValue(`${hftsHandle}.eAutomationState`);
  const setpointTemp = usePLCValue(`${hftsHandle}.fFrontHeaterEngagedSetpoint`);
  const standbyTemp = usePLCValue(`${hftsHandle}.fRearHeaterEngagedSetpoint`);

  const target = useMemo(() => {
    switch (automationState) {
      case 0: // eOff
        return 'N/A';
      case 1: // eHeatingToStandby
      case 2: // eAtStandby
      case 5: // eCoolingToStandby
        return standbyTemp;
      case 3: // eHeatingToSetpoint
      case 4: // eAtSetpoint
      case 6: // eCoolingToSetpoint
        return setpointTemp;
      default:
        return 'Unknown';
    }
  }, [automationState, setpointTemp, standbyTemp]);

  return target;
};

export default useHFTSTarget;
