import {
  Box,
  ColumnLayout,
  Header,
  Link,
  Modal,
  SpaceBetween,
  StatusIndicator,
  Table,
} from '@cloudscape-design/components';
import {
  collection,
  doc,
  getFirestore,
  limit,
  query,
  where,
} from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, {
  useMemo,
} from 'react';
import {
  useFirestoreCollectionData,
  useFirestoreDocData,
} from 'reactfire';

import OrgName from '../Organization/orgName';

function QuoteIdCell({ projectId }) {
  const { status, data: value } = useFirestoreCollectionData(
    query(collection(getFirestore(), 'projects'), where('name', '==', projectId), limit(1)),
  );
  if (status === 'loading') {
    return 'Loading...';
  }
  if (!value || value.length === 0) {
    return 'Not Found';
  }

  // The quote ID is the first 6 characters of the part ID (pulled from the admin link)
  const partId = value[0].adminLink.match(/part\/(.+?)(\/|$)/)[1];
  return partId.substring(0, 6).toUpperCase();
}

QuoteIdCell.propTypes = {
  projectId: PropTypes.string.isRequired,
};

function FilenameCell({ projectId }) {
  const { status, data: value } = useFirestoreCollectionData(
    query(collection(getFirestore(), 'projects'), where('name', '==', projectId), limit(1)),
  );
  if (status === 'loading') {
    return 'Loading...';
  }
  if (!value || value.length === 0) {
    return 'Not Found';
  }
  return (
    <Link href={value[0].adminLink}>
      {value[0].filename}
    </Link>
  );
}

FilenameCell.propTypes = {
  projectId: PropTypes.string.isRequired,
};

function MaterialCell({ projectId }) {
  const { status, data: value } = useFirestoreCollectionData(
    query(collection(getFirestore(), 'projects'), where('name', '==', projectId), limit(1)),
  );
  if (status === 'loading') {
    return 'Loading...';
  }
  if (!value || value.length === 0) {
    return 'Not Found';
  }
  return value[0].material;
}

MaterialCell.propTypes = {
  projectId: PropTypes.string.isRequired,
};

function LineItems({
  lineItems = [],
}) {
  const headerData = [
    {
      id: 'filename',
      header: 'Filename',
      cell: (item) => item.filename,
    },
    {
      id: 'quoteId',
      header: 'Quote ID',
      cell: (item) => item.quoteId,
    },
    {
      id: 'projectId',
      header: 'Project ID',
      cell: (item) => item.projectId,
    },
    {
      id: 'quantity',
      header: 'Quantity',
      cell: (item) => item.quantity,
    },
    {
      id: 'material',
      header: 'Material',
      cell: (item) => item.material,
    },
    {
      id: 'bonding',
      header: 'Is Capped',
      cell: (item) => item.bonding,
    },
  ];

  const rowData = useMemo(() => {
    const rows = [];
    if (!lineItems) return rows;
    lineItems.forEach((lineItem) => {
      rows.push({
        id: lineItem.projectId + lineItem.bonding + lineItem.quantity,
        filename: <FilenameCell projectId={lineItem.projectId} />,
        quoteId: <QuoteIdCell projectId={lineItem.projectId} />,
        projectId: lineItem.projectId,
        quantity: lineItem.quantity,
        material: <MaterialCell projectId={lineItem.projectId} />,
        bonding: lineItem.bonding,
      });
    });
    return rows;
  }, [lineItems]);

  return (
    <Table
      columnDefinitions={headerData}
      items={rowData}
      variant="embedded"
    />
  );
}

LineItems.propTypes = {
  lineItems: PropTypes.arrayOf(PropTypes.shape({
    adminLink: PropTypes.string,
    filename: PropTypes.string,
    projectId: PropTypes.string,
    quantity: PropTypes.number,
    bonding: PropTypes.string,
  })),
};

function OrderDetail({ orderId = null, setOrderId }) {
  const { status, data: order } = useFirestoreDocData(
    doc(getFirestore(), 'orders', orderId || 'nonexistant'),
    { idField: 'id', initialData: {} },
  );
  const isOpen = useMemo(() => !!orderId, [orderId]);

  if (!orderId) {
    return null;
  }

  return (
    <Modal
      size="large"
      visible={isOpen}
      header={(
        <Header variant="h2">
          Order Details
        </Header>
      )}
      onDismiss={() => { setOrderId(undefined); }}
    >
      {status === 'loading' ? (
        <StatusIndicator variant="loading">
          Loading...
        </StatusIndicator>
      ) : (
        <SpaceBetween size="s">
          <ColumnLayout columns={2}>
            <div>
              <Box variant="awsui-key-label">Organization</Box>
              {!!order && (
                <div><OrgName orgId={order.organization} /></div>
              )}
            </div>
            <div>
              <Box variant="awsui-key-label">Order Date</Box>
              <div>{order?.orderDate || 'Loading...'}</div>
            </div>
          </ColumnLayout>
          {(order?.shipments || []).map((shipment) => (
            <React.Fragment key={shipment.name}>
              <hr style={{ margin: 0 }} />
              <SpaceBetween size="s">
                <ColumnLayout columns={2}>
                  <SpaceBetween size="s">
                    <div>
                      <Box variant="awsui-key-label">Target Ship Date</Box>
                      <div>{shipment.targetShipDate}</div>
                    </div>
                    <div>
                      <Box variant="awsui-key-label">Shipping Method</Box>
                      <div>{shipment.shippingMethod}</div>
                    </div>
                  </SpaceBetween>
                  <div>
                    <Box variant="awsui-key-label">Shipping Address</Box>
                    <div>{shipment.address?.split('\n').map((line) => <Box key={line}>{line}</Box>)}</div>
                  </div>
                </ColumnLayout>
                <LineItems lineItems={shipment.lineItems} />
              </SpaceBetween>
            </React.Fragment>
          ))}
        </SpaceBetween>
      )}
    </Modal>
  );
}
OrderDetail.propTypes = {
  orderId: PropTypes.string,
  setOrderId: PropTypes.func.isRequired,
};

export default OrderDetail;
