import {
  Box, Button, Modal, SpaceBetween,
} from '@cloudscape-design/components';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

export default function UnsavedAlert({ dirty }) {
  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) => dirty
    && currentLocation.pathname !== nextLocation.pathname,
    [dirty],
  );
  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = ''; // Required for modern browsers
    };

    if (dirty) {
      window.addEventListener('beforeunload', handleBeforeUnload, { capture: true });
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload, { capture: true });
    };
  }, [dirty]);

  return (
    <Modal
      onDismiss={() => blocker.reset?.()}
      visible={blocker.state === 'blocked'}
      footer={(
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="primary" onClick={() => blocker.reset?.()}>Cancel</Button>
            <Button variant="link" onClick={() => blocker.proceed?.()}>Ok</Button>
          </SpaceBetween>
        </Box>
      )}
      header="Are you sure?"
    >
      Change you made will be lost. Are you sure you want to leave?
    </Modal>
  );
}

UnsavedAlert.propTypes = {
  dirty: PropTypes.bool.isRequired,
};
