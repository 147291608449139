import {
  ColumnLayout,
  Header,
  SpaceBetween,
} from '@cloudscape-design/components';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { HFTSStateItems } from '../../../constants/hftsState';
import { usePLCValue } from '../../../features/plc/context';
import {
  handlify,
  titleify,
  useHFTSHandle,
  useProgramHandle,
} from '../hooks/context';
import PLCButton from './plcButton';
import PLCSelect from './plcSelect';
import PLCToggleButton from './plcToggleButton';

function OverrideControls({ side1, side2 }) {
  const programHandle = useProgramHandle();
  const side1HFTSHandle = useHFTSHandle(side1);
  const side1Handle = useMemo(() => handlify(side1), [side1]);
  const side1Title = useMemo(() => titleify(side1), [side1]);
  const side2HFTSHandle = useHFTSHandle(side2);
  const side2Handle = useMemo(() => handlify(side2), [side2]);
  const side2Title = useMemo(() => titleify(side2), [side2]);

  const lowerTooHot = usePLCValue(`${side1HFTSHandle}.bTooHot`);
  const upperTooHot = usePLCValue(`${side2HFTSHandle}.bTooHot`);

  const isAutocooling = usePLCValue(`${programHandle}.bAutoCool`);

  const hftsOptions = useMemo(() => HFTSStateItems.map((item) => ({
    label: item.text,
    value: `${item.id}`,
  })), []);

  return (
    <ColumnLayout columns={3}>
      <SpaceBetween size="m">
        <Header>Overrides</Header>
        <PLCToggleButton
          handle={`${programHandle}.bOverrideCylinders`}
          label="Cylinder Override"
          fullWidth
        />
        <PLCButton
          onClick={(write) => {
            [
              `${side1HFTSHandle}.bHP1TooHot`,
              `${side1HFTSHandle}.bHP2TooHot`,
              `${side1HFTSHandle}.bHP3TooHot`,
              `${side1HFTSHandle}.bHP4TooHot`,
              `${side1HFTSHandle}.bHP5TooHot`,
              `${side1HFTSHandle}.bHP6TooHot`,
              `${side1HFTSHandle}.bToolTooHot`,
              `${side1HFTSHandle}.bHotBlockTooHot`,
              `${side1HFTSHandle}.bFrontBlockTooHot`,
              `${side1HFTSHandle}.bRearBlockTooHot`,
              `${side2HFTSHandle}.bHP1TooHot`,
              `${side2HFTSHandle}.bHP2TooHot`,
              `${side2HFTSHandle}.bHP3TooHot`,
              `${side2HFTSHandle}.bHP4TooHot`,
              `${side2HFTSHandle}.bHP5TooHot`,
              `${side2HFTSHandle}.bHP6TooHot`,
              `${side2HFTSHandle}.bToolTooHot`,
              `${side2HFTSHandle}.bHotBlockTooHot`,
              `${side2HFTSHandle}.bFrontBlockTooHot`,
              `${side2HFTSHandle}.bRearBlockTooHot`,

              `${side1HFTSHandle}.bTooHot`,
              `${side2HFTSHandle}.bTooHot`,
            ].forEach((handle) => {
              write({ handle, value: false });
            });
          }}
          disabled={!lowerTooHot && !upperTooHot}
          fullWidth
        >
          Reset heater check
        </PLCButton>
        <PLCButton
          onClick={(write) => {
            [
              `${side1HFTSHandle}.eAutomationState`,
              `${side2HFTSHandle}.eAutomationState`,
            ].forEach((handle) => {
              write({ handle, value: 2 });
            });
          }}
          fullWidth
        >
          Go to standby
        </PLCButton>
        <PLCSelect
          label={`${side1Title} HFTS State`}
          handle={`${side1HFTSHandle}.eAutomationState`}
          options={hftsOptions}
        />
        <PLCSelect
          label={`${side2Title} HFTS State`}
          handle={`${side2HFTSHandle}.eAutomationState`}
          options={hftsOptions}
        />
        {isAutocooling ? (
          <PLCButton
            onClick={(write) => {
              write({ handle: `${programHandle}.bAutoCool`, value: false });
            }}
            fullWidth
          >
            Stop autocool shutdown
          </PLCButton>
        ) : (
          <PLCButton
            onClick={(write) => {
              [
                `${side1HFTSHandle}.eAutomationState`,
                `${side2HFTSHandle}.eAutomationState`,
              ].forEach((handle) => {
                write({ handle, value: 0, type: 'INT' });
              });
              write({ handle: `${programHandle}.bAutoCool`, value: true, type: 'BOOL' });
            }}
            fullWidth
          >
            Start autocool shutdown
          </PLCButton>
        )}
      </SpaceBetween>
      <SpaceBetween size="m">
        <Header>{`${side1Title} HFTS`}</Header>
        <PLCToggleButton
          handle={`${programHandle}.${side1Handle}HotCylinder`}
          label={`${side1Title} Hot Cylinder`}
          fullWidth
        />
        <PLCToggleButton
          handle={`${programHandle}.${side1Handle}ColdCylinder`}
          label={`${side1Title} Cold Cylinder`}
          fullWidth
        />
        <PLCToggleButton
          handle={`${programHandle}.${side1Handle}FrontCylinder`}
          label={`${side1Title} Front Cylinder`}
          fullWidth
        />
        <PLCToggleButton
          handle={`${programHandle}.${side1Handle}RearCylinder`}
          label={`${side1Title} Rear Cylinder`}
          fullWidth
        />
      </SpaceBetween>
      <SpaceBetween size="m">
        <Header>{`${side2Title} HFTS`}</Header>
        <PLCToggleButton
          handle={`${programHandle}.${side2Handle}HotCylinder`}
          label={`${side2Title} Hot Cylinder`}
          fullWidth
        />
        <PLCToggleButton
          handle={`${programHandle}.${side2Handle}ColdCylinder`}
          label={`${side2Title} Cold Cylinder`}
          fullWidth
        />
        <PLCToggleButton
          handle={`${programHandle}.${side2Handle}FrontCylinder`}
          label={`${side2Title} Front Cylinder`}
          fullWidth
        />
        <PLCToggleButton
          handle={`${programHandle}.${side2Handle}RearCylinder`}
          label={`${side2Title} Rear Cylinder`}
          fullWidth
        />
      </SpaceBetween>
    </ColumnLayout>
  );
}

OverrideControls.propTypes = {
  side1: PropTypes.string.isRequired,
  side2: PropTypes.string.isRequired,
};

export default OverrideControls;
