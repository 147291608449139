import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  useLocation,
} from 'react-router-dom';

const NotificationsContext = createContext();

function NotificationsProvider({ children }) {
  const location = useLocation();

  const [notifications, setNotifications] = useState([]);
  const removeNotification = useCallback((id) => setNotifications(
    (prevNotifications) => prevNotifications.filter((n) => n.id !== id),
  ), []);
  const addNotification = useCallback((notification) => {
    const newId = notification.id || `message_${Math.random().toString(36)}`;
    const newNotification = {
      ...notification,
      id: newId,
      onDismiss: () => { removeNotification(newId); },
      location: location.pathname,
    };
    setNotifications((prevNotifications) => [...prevNotifications, newNotification]);
  }, [location.pathname, removeNotification]);

  const value = useMemo(() => ({
    notifications,
    addNotification,
    removeNotification,
  }), [addNotification, notifications, removeNotification]);

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
}

NotificationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationsProvider;

export const useNotifications = () => {
  const context = useContext(NotificationsContext);
  if (context === undefined) {
    throw new Error('useNotifications must be used within a NotificationsProvider');
  }
  return context;
};
