import React from 'react';

import PricingSchemaProvider from '../../components/Pricing/pricingSchemaProvider';
import Page from './page';

function EditPricingPage() {
  return (
    <PricingSchemaProvider>
      <Page />
    </PricingSchemaProvider>
  );
}

export default EditPricingPage;
