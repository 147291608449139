import {
  doc,
  getFirestore,
} from 'firebase/firestore';
import { useEffect } from 'react';
import { useFirestoreDocData } from 'reactfire';

import { useNotifications } from '../../features/notifications';

const currentVersion = process.env.REACT_APP_VERSION;

export default function VersionCheck() {
  const { data } = useFirestoreDocData(doc(getFirestore(), 'memstore', 'admin_site_version'));
  const { addNotification, removeNotification } = useNotifications();

  useEffect(() => {
    if (data?.version && currentVersion && currentVersion !== data.version) {
      addNotification({
        type: 'info',
        dismissible: true,
        header: 'New version detected',
        content: 'A new version is available. Please refresh to get the latest version.',
        id: 'VersionCheck',
      });
    } else {
      removeNotification('VersionCheck');
    }
  }, [addNotification, data, removeNotification]);
}
