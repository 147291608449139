import { useEffect } from 'react';

import { useNotifications } from '../../../features/notifications';
import { usePLCError } from '../../../features/plc/context';

function PLCError() {
  const error = usePLCError();
  const { addNotification, removeNotification } = useNotifications();

  useEffect(() => {
    if (!error) {
      removeNotification('PLCError');
      return;
    }
    addNotification({
      type: 'error',
      dismissible: false,
      header: 'Error connecting to PLC',
      content: error,
      id: 'PLCError',
    });
  }, [addNotification, error, removeNotification]);

  return null;
}

export default PLCError;
