import { Badge } from '@cloudscape-design/components';
import PropTypes from 'prop-types';
import React from 'react';

import { usePartAndQuote } from '../../features/firebase';

function QuoteStatus({ partId }) {
  const { part, quote, status: queryStatus } = usePartAndQuote(partId);
  const { hasBeenOrdered } = part;
  const { status } = quote;

  let tagText = 'Unknown';
  if (hasBeenOrdered) {
    tagText = 'Ordered';
  } else {
    tagText = {
      quoted: 'Quoted',
      pending: 'Pending',
      no_quote: 'No quote',
    }[status] || 'Unknown';
  }

  if (queryStatus === 'loading') {
    tagText = 'Loading...';
  }

  return (
    <Badge
      color={{
        Quoted: 'green',
        Pending: 'grey',
        Ordered: 'green',
        'No quote': 'red',
        Unknown: 'grey',
        'Loading...': 'grey',
      }[tagText] || 'grey'}
      style={{ marginLeft: 0 }}
    >
      {tagText}
    </Badge>
  );
}

QuoteStatus.propTypes = {
  partId: PropTypes.string.isRequired,
};

export default QuoteStatus;
