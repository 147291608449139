import {
  FormField,
  Select,
} from '@cloudscape-design/components';
import PropTypes from 'prop-types';
import React from 'react';

function MaterialSelector({
  setMaterial,
  value = null,
  error = null,
}) {
  const materials = [
    { label: 'COP', value: 'cop' },
    { label: 'PC', value: 'pc' },
    { label: 'PMMA', value: 'pmma' },
    { label: 'Other...', value: 'other' },
  ];

  return (
    <FormField
      label="Material"
      stretch
      errorText={error}
    >
      <Select
        selectedOption={value}
        onChange={({ detail }) => setMaterial(detail.selectedOption)}
        options={materials}
        placeholder="Choose a material"
      />
    </FormField>
  );
}

MaterialSelector.propTypes = {
  setMaterial: PropTypes.func.isRequired,
  value: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  error: PropTypes.string,
};

export default MaterialSelector;
