import {
  Box,
  Button,
  ColumnLayout,
  Flashbar,
  FormField,
  Header,
  Input,
  Modal,
  SpaceBetween,
  TextContent,
} from '@cloudscape-design/components';
import {
  collection,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';

import { usePLCWrite } from '../../../features/plc/context';
import { useProgramHandle } from '../hooks/context';
import useCurrentConfig from '../hooks/useCurrentConfig';
import parseTime from './parseTime';
import ProjectSelector from './projectSelector';

function LoadConfigModal({
  isOpen,
  close,
  process,
}) {
  const write = usePLCWrite();
  const programHandle = useProgramHandle();
  const { config, configIsLoading, configError } = useCurrentConfig(process);

  const handles = useMemo(() => config.rows.reduce((acc, row) => [
    ...acc,
    ...row.values.map((v) => v.handle)], []), [config]);

  const initialValues = useMemo(() => ({
    project: null,
    notFound: false,
    ...handles.reduce((acc, handle) => (
      { ...acc, [handle]: 0 }), {}),
  }), [handles]);

  const enableHeaters = useCallback(() => {
    if (process !== 'forming') {
      return;
    }
    write({ handle: `${programHandle}.bUpperHotBlockHeaterSystemOn`, value: true });
    write({ handle: `${programHandle}.bUpperFrontHeaterSystemOn`, value: true });
    write({ handle: `${programHandle}.bUpperRearHeaterSystemOn`, value: true });
    write({ handle: `${programHandle}.bLowerHotBlockHeaterSystemOn`, value: true });
    write({ handle: `${programHandle}.bLowerFrontHeaterSystemOn`, value: true });
    write({ handle: `${programHandle}.bLowerRearHeaterSystemOn`, value: true });
  }, [process, programHandle, write]);

  if (typeof document === 'undefined') {
    return null;
  }

  if (configIsLoading) {
    return ReactDOM.createPortal(
      <Modal
        size="lg"
        open={isOpen}
        modalHeading="Loading config..."
        alert
        onRequestClose={() => { close(); }}
      />,
      document.body,
    );
  }

  if (configError) {
    return ReactDOM.createPortal(
      <Modal
        visible={isOpen}
        onDismiss={close}
        header="Error loading config"
        onRequestClose={() => { close(); }}
        footer={(
          <Box float="right">
            <Button variant="link" onClick={close}>Cancel</Button>
          </Box>
        )}
      >
        <Flashbar
          items={[
            {
              header: 'Error',
              type: 'error',
              content: configError.message,
              dismissible: false,
              id: 'config_error',
            },
          ]}
        />
      </Modal>,
      document.body,
    );
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values) => {
        for (let i = 0; i < config.rows.length; i += 1) {
          const row = config.rows[i];
          for (let j = 0; j < row.values.length; j += 1) {
            const { handle } = row.values[j];
            write({ handle: `${programHandle}.${handle}`, value: values[handle] });
          }
        }
        close();
      }}
    >
      {({
        values, handleSubmit, setFieldValue, errors,
      }) => (
        <Modal
          size="max"
          visible={isOpen}
          onDismiss={close}
          header="Load config"
          footer={(
            <Box float="right">
              <SpaceBetween direction="horizontal" size="m">
                <Button variant="link" onClick={close}>Cancel</Button>
                {process === 'forming' && !(values.notFound || values.project === 'placeholder-item') ? (
                  <Button
                    variant="secondary"
                    onClick={() => {
                      handleSubmit();
                      enableHeaters();
                    }}
                  >
                    Load and enable heaters
                  </Button>
                ) : null}
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={values.notFound || values.project === 'placeholder-item'}
                >
                  Load
                </Button>
              </SpaceBetween>
            </Box>
          )}
          primaryButtonText="Load"
          onRequestSubmit={handleSubmit}
          onRequestClose={() => { close(); }}
          primaryButtonDisabled={values.notFound || values.project === 'placeholder-item'}
          secondaryButtons={process === 'forming' && !(values.notFound || values.project === 'placeholder-item') ? [
            { buttonText: 'Cancel', onClick: () => { close(); } },
            {
              buttonText: 'Load and enable heaters',
              onClick: () => {
                handleSubmit();
                enableHeaters();
              },
            },
          ] : [
            { buttonText: 'Cancel', onClick: () => { close(); } },
          ]}
        >
          <SpaceBetween size="m">
            <TextContent>
              Select a project below to load the most recent production run for this project.
              Check the values before clicking &quot;Load.&quot;
            </TextContent>
            <ProjectSelector
              enablePastProjectSearch
              value={values.project}
              setProject={(newProject) => {
                for (let i = 0; i < handles.length; i += 1) {
                  const key = handles[i];
                  setFieldValue(`['${key}']`, 'Loading...');
                }

                // find the configuration values
                if (newProject?.value !== 'other') {
                  getDocs(query(
                    collection(getFirestore(), 'process_logs'),
                    where('project', '==', newProject.value),
                    where('process', '==', process),
                    where('production', '==', true),
                    orderBy('start', 'desc'),
                    limit(1),
                  )).then((querySnapshot) => {
                    for (let i = 0; i < handles.length; i += 1) {
                      const key = handles[i];
                      setFieldValue(`['${key}']`, 0);
                    }

                    if (querySnapshot.empty) {
                      setFieldValue('notFound', true);
                      return;
                    }
                    querySnapshot.forEach((doc) => {
                      if ((doc.data().plcConfig?.version) !== config.version) {
                        setFieldValue('notFound', true);
                        return;
                      }
                      setFieldValue('notFound', false);
                      if (doc.data().plcConfig) {
                        const backups = {
                          fChipSideFinalTemp: 'fChipSideLoadingTemp',
                          fChipSideFinalTempDisengaged: 'fChipSideLoadingTempDisengaged',
                          fCapSideFinalTemp: 'fCapSideLoadingTemp',
                          fCapSideFinalTempDisengaged: 'fCapSideLoadingTempDisengaged',
                        };
                        for (let i = 0; i < handles.length; i += 1) {
                          const key = handles[i];
                          if (doc.data().plcConfig[key]) {
                            setFieldValue(`['${key}']`, doc.data().plcConfig[key]);
                          } else if (doc.data().plcConfig[backups[key]]) {
                            setFieldValue(`['${key}']`, doc.data().plcConfig[backups[key]]);
                          }
                        }
                      }
                    });
                  }).catch((err) => {
                    setFieldValue('notFound', true);
                    console.error(err);
                  });
                }
                setFieldValue('project', newProject);
              }}
              error={errors.project}
            />
            { values.notFound ? (
              <Flashbar
                items={[{
                  header: 'No configuration found for this project.',
                  content: 'This means (1) there have been no production runs for this project or (2) the PLC program has updated since the last run and is not backward-compatible.',
                  type: 'error',
                  dismissible: false,
                  id: 'config_not_found',
                }]}
              />
            ) : null}
            {config.rows.map((row) => (
              <React.Fragment key={row.header}>
                <Header variant="h3">
                  {row.header}
                </Header>
                <ColumnLayout columns={4}>
                  {row.values.map((value) => (
                    <FormField
                      label={value.label}
                      key={value.handle}
                    >
                      <Input
                        value={value.type === 'TIME' ? parseTime(values[value.handle]) : values[value.handle]}
                        readOnly
                      />
                    </FormField>
                  ))}
                </ColumnLayout>
              </React.Fragment>
            ))}
          </SpaceBetween>
        </Modal>
      )}
    </Formik>
  );
}

LoadConfigModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  process: PropTypes.string.isRequired,
};

export default LoadConfigModal;
