import {
  Header,
  Table,
} from '@cloudscape-design/components';
import React from 'react';

import useInternalProjects from '../../hooks/use-internalProjects';

export default function InternalProjectsList() {
  const { internalProjects, isLoading } = useInternalProjects();

  return (
    <Table
      header={(
        <Header
          variant="awsui-h1-sticky"
        >
          Internal Projects
        </Header>
      )}
      variant="full-page"
      stickyHeader
      loading={isLoading}
      items={internalProjects}
      resizableColumns
      columnDefinitions={[
        {
          id: 'name',
          header: 'Project ID',
          cell: (item) => item.value,
          width: 240,
        },
        {
          id: 'filename',
          header: 'Goal',
          cell: (item) => item.title,
        },
      ]}
    />
  );
}
