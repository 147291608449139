import PropTypes from 'prop-types';
import React from 'react';

import { useGetUserById } from '../../hooks/use-basicQueries';

function UserEmail({ userID = null, backupEmail = null }) {
  const {
    data: user, isFetching, isError, error,
  } = useGetUserById(userID);

  if (isFetching) {
    return <span>{`${userID} (loading email...)`}</span>;
  }

  if (isError) {
    return <span>{`${userID} (Error: ${error.message})`}</span>;
  }

  if (user?.email) {
    return <span>{user.email}</span>;
  }

  return (
    <span>
      Anonymous User
      {backupEmail ? ` (${backupEmail})` : ''}
    </span>
  );
}

UserEmail.propTypes = {
  userID: PropTypes.string,
  backupEmail: PropTypes.string,
};

export default UserEmail;
