/* eslint-disable react/jsx-props-no-spreading */
import '@google/model-viewer';

import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { EdgesGeometry, LineBasicMaterial, LineSegments } from 'three';

function CADViewer({
  src, style = {}, children = null, ...props
}) {
  const ref = useCallback((node) => {
    if (node) {
      node.addEventListener('load', () => {
        // find the three.js object that is the model
        const modelSymbols = Object.getOwnPropertySymbols(node);
        let sceneSymbol;
        for (let i = 0; i < modelSymbols.length; i += 1) {
          if (modelSymbols[i].description === 'scene') {
            sceneSymbol = modelSymbols[i];
          }
        }
        if (!sceneSymbol) {
          throw new Error('Could not find scene symbol');
        }
        const scene = node[sceneSymbol];

        // create edge geometry
        scene.traverse((object) => {
          if (object.type === 'Mesh') {
            const edge = new EdgesGeometry(object.geometry, 20);
            const line = new LineSegments(
              edge,
              new LineBasicMaterial({ color: 0x000000 }),
            );
            object.parent.add(line);
          }
        });
      });
    }
  }, []);

  return (
    <model-viewer ref={ref} src={src} camera-controls enable-pan style={{ '--poster-color': 'rgba(0,0,0,0)', cornerRadius: 8, ...style }} {...props}>
      {children}
    </model-viewer>
  );
}

CADViewer.propTypes = {
  src: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default CADViewer;
