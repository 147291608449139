import {
  Button,
  Container,
  ContentLayout,
  Header,
} from '@cloudscape-design/components';
import {
  doc,
  getFirestore,
} from 'firebase/firestore';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';

import { ManageOrder } from '../../components/Orders';

export default function UpdateOrderPage() {
  const { orderId } = useParams();
  const navigate = useNavigate();

  // fetch order based on orderId
  const { status, data: order } = useFirestoreDocData(
    doc(getFirestore(), 'orders', orderId),
    { idField: 'id' },
  );

  if (!orderId || status === 'loading' || !order) {
    return (
      <ContentLayout
        header={(
          <Header
            actions={<Button variant="normal" onClick={() => { navigate('/orders'); }}>See all orders</Button>}
          >
            Update an Order
          </Header>
        )}
      >
        <Container>{status === 'loading' ? 'Loading' : 'Invalid order ID or something went wrong'}</Container>
      </ContentLayout>
    );
  }

  return (
    <ContentLayout
      header={(
        <Header
          actions={<Button variant="normal" onClick={() => { navigate('/orders'); }}>See all orders</Button>}
        >
          Update an Order
        </Header>
        )}
    >
      <ManageOrder initData={order} isEditing />
    </ContentLayout>
  );
}
