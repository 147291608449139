import {
  Alert,
  Box,
  Button,
  FormField,
  Input,
  Modal,
  SpaceBetween,
  Textarea,
  TokenGroup,
} from '@cloudscape-design/components';
import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from 'firebase/firestore';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

export default function AddOrganizationModal({
  open, onClose, organizations = [], defaultDomain = '',
}) {
  const [debouncedDomains, setDebouncedDomains] = useState([]);
  const [foundDuplicate, setFoundDuplicate] = useState(null);
  const [domainInput, setDomainInput] = useState('');

  const initialValues = useMemo(() => ({
    name: '',
    domains: defaultDomain ? [defaultDomain] : [],
    notes: '',
  }), [defaultDomain]);

  const {
    values, errors, handleSubmit, setFieldValue, isSubmitting, setFieldError,
  } = useFormik({
    initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validate: (validateValues) => {
      const validateErrors = {};
      if (!validateValues.name) {
        validateErrors.name = 'Required';
      } else {
        const orgExists = organizations.some(
          (org) => org.name.trim().toLowerCase() === validateValues.name.trim().toLowerCase(),
        );
        if (orgExists) validateErrors.name = 'Organization already exists';
      }
      // validate domains
      if (validateValues.domains?.length && !validateValues.notes && foundDuplicate) {
        validateErrors.notes = 'Found a organization with the same domain. Add a note to indicate why a organization is required.';
      }
      return validateErrors;
    },
    onSubmit: async (submitValues) => {
      try {
        await addDoc(collection(getFirestore(), 'organizations'), {
          name: submitValues.name,
          domains: submitValues.domains,
          ...(submitValues.notes && { notes: submitValues.notes }),
          createDate: serverTimestamp(),
        });
        onClose();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error adding organization:', error);
      }
    },
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedDomains(values.domains);
    }, 500);

    return () => clearTimeout(handler);
  }, [values.domains]);

  useEffect(() => {
    if (!debouncedDomains.length) {
      setFoundDuplicate(null);
      return;
    }
    const founds = organizations
      .filter((org) => org.domains?.some((domain) => debouncedDomains.includes(domain)));

    if (founds?.length > 0) {
      setFoundDuplicate(founds.map((org) => org.name));
    } else {
      setFoundDuplicate(null);
    }
  }, [debouncedDomains, organizations]);

  return (
    <Modal
      onDismiss={onClose}
      visible={open}
      header="Create an organization"
    >
      <form onSubmit={handleSubmit}>
        <SpaceBetween direction="vertical" size="s">
          {foundDuplicate && (
            <Alert statusIconAriaLabel="Warning" type="warning">
              Found organizations with the same domain:
              {' '}
              {foundDuplicate.join(', ')}
            </Alert>
          )}
          <FormField
            label="Organization Name"
            errorText={errors.name}
          >
            <Input
              value={values.name}
              onChange={({ detail }) => { setFieldValue('name', detail.value); }}
            />
          </FormField>
          <FormField
            label="Domains"
            errorText={errors.domains}
          >
            <Input
              placeholder='Enter new valid domain and press "Enter"'
              value={domainInput}
              onChange={({ detail }) => setDomainInput(detail.value)}
              onKeyDown={(e) => {
                if (e?.detail?.key === 'Enter') {
                  e.preventDefault(); // disable form submission
                  if (domainInput.length > 0) {
                    if (/^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}$/.test(domainInput)) {
                      setFieldValue('domains', [...values.domains, domainInput.trim()]);
                      setDomainInput('');
                    } else {
                      setFieldError('domains', 'Please enter a valid domain');
                    }
                  }
                } else if (errors.domains) {
                  setFieldError('domains', '');
                }
              }}
            />
          </FormField>
          <TokenGroup
            onDismiss={({ detail: { itemIndex } }) => {
              const newDomains = [...values.domains];
              newDomains.splice(itemIndex, 1);
              setFieldValue('domains', newDomains);
            }}
            items={values.domains.map((domain) => ({ label: domain }))}
          />
          <FormField
            label="Notes"
            errorText={errors.notes}
          >
            <Textarea
              value={values.notes}
              onChange={({ detail }) => { setFieldValue('notes', detail.value); }}
            />
          </FormField>

          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={onClose}>Cancel</Button>
              <Button variant="primary" type="submit" loading={isSubmitting}>Create</Button>
            </SpaceBetween>
          </Box>
        </SpaceBetween>
      </form>
    </Modal>
  );
}

AddOrganizationModal.propTypes = {
  organizations: PropTypes.arrayOf(PropTypes.shape({})),
  defaultDomain: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
