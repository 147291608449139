import React from 'react';
import { useParams } from 'react-router-dom';

import { FeedbackWizard } from '../../components/Feedback';

function EditFeedbackPage() {
  const { partId } = useParams();
  return <FeedbackWizard partId={partId} />;
}

export default EditFeedbackPage;
