const HFTSState = Object.freeze({
  Off: 0,
  HeatingToStandby: 1,
  AtStandby: 2,
  HeatingToSetpoint: 3,
  AtSetpoint: 4,
  CoolingToStandby: 5,
  CoolingToSetpoint: 6,
  toString(value) {
    switch (value) {
      case this.Off:
        return 'Off';
      case this.HeatingToStandby:
        return 'Heating to Standby';
      case this.AtStandby:
        return 'At Standby';
      case this.HeatingToSetpoint:
        return 'Heating to Setpoint';
      case this.AtSetpoint:
        return 'At Setpoint';
      case this.CoolingToStandby:
        return 'Cooling to Standby';
      case this.CoolingToSetpoint:
        return 'Cooling to Setpoint';
      default:
        return `Unknown value: ${value}`;
    }
  },
});

const HFTSStateItems = Object.freeze([
  {
    id: HFTSState.Off,
    text: HFTSState.toString(HFTSState.Off),
  },
  {
    id: HFTSState.HeatingToStandby,
    text: HFTSState.toString(HFTSState.HeatingToStandby),
  },
  {
    id: HFTSState.AtStandby,
    text: HFTSState.toString(HFTSState.AtStandby),
  },
  {
    id: HFTSState.HeatingToSetpoint,
    text: HFTSState.toString(HFTSState.HeatingToSetpoint),
  },
  {
    id: HFTSState.AtSetpoint,
    text: HFTSState.toString(HFTSState.AtSetpoint),
  },
  {
    id: HFTSState.CoolingToStandby,
    text: HFTSState.toString(HFTSState.CoolingToStandby),
  },
  {
    id: HFTSState.CoolingToSetpoint,
    text: HFTSState.toString(HFTSState.CoolingToSetpoint),
  },
]);

export { HFTSState, HFTSStateItems };
