const parseTime = (time) => {
  if (time === 0) {
    return '0m 0s';
  }

  const sek = Math.floor((time / 1000) % 60);
  const min = Math.floor((time / 60000) % 60);
  const hour = Math.floor((time / 3600000) % 24);
  const day = Math.floor((time / 86400000) % 365);
  if (day > 0 || hour > 0) {
    return `${day}d ${hour}h ${min}m ${sek}s`;
  }
  return `${min}m ${sek}s`;
};

export default parseTime;
