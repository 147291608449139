import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { shallowEqualObjects } from 'shallow-equal';
import { useSyncExternalStore } from 'use-sync-external-store/shim';

import TcAdsWebService from './TcAdsWebService';

const NETID = ''; // Empty string for local machine;
const PORT = '851'; // PLC Runtime

const typeMap = {
  BYTE: { size: 1, read: 'readBYTE' },
  BOOL: { size: 1, read: 'readBOOL' },
  WORD: { size: 2, read: 'readWORD' },
  DWORD: { size: 4, read: 'readDWORD' },
  SINT: { size: 1, read: 'readSINT' },
  INT: { size: 2, read: 'readINT' },
  DINT: { size: 4, read: 'readDINT' },
  REAL: { size: 4, read: 'readREAL' },
  LREAL: { size: 8, read: 'readLREAL' },
  STRING: { read: 'readString' },
};

const PLCContext = createContext({ get: () => {}, subscribe: () => {} });

function usePLCData({ url, handles: handlesVarNames }) {
  const state = useRef({ ready: false, values: {}, error: null });
  const listeners = useRef(new Set());

  const handlesByName = useMemo(
    () => (handlesVarNames.reduce((acc, handle) => ({ ...acc, [handle.name]: handle }), {})),
    [handlesVarNames],
  );

  const client = useRef(null);
  const readLoopID = useRef(null);
  const handles = useRef({});

  useEffect(
    () => {
      (async () => {
        try {
          client.current = new TcAdsWebService.Client(url, null, null);

          const generalTimeout = 500;
          const readLoopDelay = 500;

          await new Promise((resolve, reject) => {
            // Create sumcommando for reading twincat symbol handles by symbol name;
            const handleswriter = new TcAdsWebService.DataWriter();

            // Write general information for each symbol handle
            // to the TcAdsWebService.DataWriter object
            for (let i = 0; i < handlesVarNames.length; i += 1) {
              handleswriter.writeDINT(TcAdsWebService.TcAdsReservedIndexGroups.SymbolHandleByName);
              handleswriter.writeDINT(0);
              handleswriter.writeDINT(4); // Expected size; A handle has a size of 4 byte;
              // The length of the symbol name string
              handleswriter.writeDINT(handlesVarNames[i].name.length);
            }

            // Write symbol names after the general information
            // to the TcAdsWebService.DataWriter object
            for (let i = 0; i < handlesVarNames.length; i += 1) {
              handleswriter.writeString(handlesVarNames[i].name);
            }

            const readLoop = (readSymbolValuesData) => () => {
              // calculate the length of requested data
              let dataLength = 0;
              for (let i = 0; i < handlesVarNames.length; i += 1) {
                if (handlesVarNames[i].type === 'STRING') {
                  dataLength += handlesVarNames[i].size || 255;
                } else {
                  dataLength += typeMap[handlesVarNames[i].type].size;
                }
              }

              // Send the read-read-write command to the TcAdsWebService
              // by use of the readwrite function of the TcAdsWebService.Client object;
              client.current.readwrite(
                NETID,
                PORT,
                0xF080, // 0xF080 = Read command;
                handlesVarNames.length, // IndexOffset = Variables count;
                // Length of requested data + 4 byte errorcode per variable;
                dataLength + (handlesVarNames.length * 4),
                readSymbolValuesData,
                (e) => { // ReadCallback
                  if (e && e.isBusy) {
                    // HANDLE PROGRESS TASKS HERE;
                    // Exit callback function because request is still busy;
                    return;
                  }

                  if (e && !e.hasError) {
                    const { reader } = e;

                    // Read error codes from begin of TcAdsWebService.DataReader object;
                    for (let i = 0; i < handlesVarNames.length; i += 1) {
                      const err = reader.readDWORD();
                      if (err !== 0) {
                        reject(new Error('Symbol error!'));
                        return;
                      }
                    }

                    // read values
                    for (let i = 0; i < handlesVarNames.length; i += 1) {
                      let value;
                      if (handlesVarNames[i].type === 'STRING') {
                        value = reader.readString(handlesVarNames[i].size || 255);
                      } else {
                        value = reader[typeMap[handlesVarNames[i].type].read]();
                      }
                      state.current.values[handlesVarNames[i].name] = value;
                    }
                    state.current.ready = true;
                    listeners.current.forEach((listener) => listener());
                  } else if (e.error.getTypeString() === 'TcAdsWebService.ResquestError') {
                    // HANDLE TcAdsWebService.ResquestError HERE;
                    reject(new Error(`Error: StatusText = ${e.error.statusText} Status: ${e.error.status}`));
                  } else if (e.error.getTypeString() === 'TcAdsWebService.Error') {
                    // HANDLE TcAdsWebService.Error HERE;
                    reject(new Error(`Error: ErrorMessage = ${e.error.errorMessage} ErrorCode: ${e.error.errorCode}`));
                  }
                },
                null,
                generalTimeout,
                () => { reject(new Error('Read timeout!')); }, // ReadTimeoutCallback
                true,
              );
            };

            // Occurs if the readwrite for the sumcommando has finished;
            const requestHandlesCallback = (e) => {
              if (e && e.isBusy) {
                // Exit callback function because request is still busy;
                return;
              }

              if (e && !e.hasError) {
                // Get TcAdsWebService.DataReader object from TcAdsWebService.Response object;
                const { reader } = e;

                // Read error code and length for each handle;
                for (let i = 0; i < handlesVarNames.length; i += 1) {
                  const err = reader.readDWORD();
                  reader.readDWORD(); // originally assigned to a variable ('len'), but not used

                  if (err !== 0) {
                    reject(new Error('Handle error!'));
                    return;
                  }
                }

                // Read handles from TcAdsWebService.DataReader object;
                const handleValues = [];
                for (let i = 0; i < handlesVarNames.length; i += 1) {
                  handleValues.push(reader.readDWORD());
                }

                // Create sum commando to read symbol values based on the handle
                const readSymbolValuesWriter = new TcAdsWebService.DataWriter();

                for (let i = 0; i < handleValues.length; i += 1) {
                  readSymbolValuesWriter.writeDINT(
                    TcAdsWebService.TcAdsReservedIndexGroups.SymbolValueByHandle, // IndexGroup
                  );
                  // IndexOffset = The target handle
                  readSymbolValuesWriter.writeDINT(handleValues[i]);
                  if (handlesVarNames[i].type === 'STRING') {
                    readSymbolValuesWriter.writeDINT(
                      handlesVarNames[i].size || 255,
                    ); // Length of the string
                  } else {
                    readSymbolValuesWriter.writeDINT(
                      typeMap[handlesVarNames[i].type].size, // size to read
                    );
                  }
                }

                // Get Base64 encoded data from TcAdsWebService.DataWriter;
                const readSymbolValuesData = readSymbolValuesWriter.getBase64EncodedData();
                readLoopID.current = window.setInterval(
                  readLoop(readSymbolValuesData),
                  readLoopDelay,
                );

                for (let i = 0; i < handlesVarNames.length; i += 1) {
                  handles.current[handlesVarNames[i].name] = handleValues[i];
                }
                resolve();
              } else if (e.error.getTypeString() === 'TcAdsWebService.ResquestError') {
                // HANDLE TcAdsWebService.ResquestError HERE;
                reject(new Error(`Error: StatusText = ${e.error.statusText} Status: ${e.error.status}`));
              } else if (e.error.getTypeString() === 'TcAdsWebService.Error') {
                // HANDLE TcAdsWebService.Error HERE;
                reject(new Error(`Error: ErrorMessage = ${e.error.errorMessage} ErrorCode: ${e.error.errorCode}`));
              }
            };

            client.current.readwrite(
              NETID,
              PORT,
              // IndexGroup = ADS list-read-write command;
              // Used to request handles for twincat symbols;
              0xF082,
              handlesVarNames.length, // IndexOffset = Count of requested symbol handles;
              // Length of requested data + 4 byte errorcode and 4 byte length per twincat symbol;
              (handlesVarNames.length * 4) + (handlesVarNames.length * 8),
              handleswriter.getBase64EncodedData(),
              requestHandlesCallback,
              null,
              generalTimeout,
              () => { reject(new Error('Request handles timeout!')); }, // RequestHandlesTimeoutCallback
              true,
            );
          });
        } catch (err) {
          state.current.error = err.message;
          listeners.current.forEach((listener) => listener());
        }
      })();
      return () => {
        if (readLoopID.current) {
          window.clearInterval(readLoopID.current);
        }
      };
    },
    [url, handlesVarNames],
  );

  const write = useCallback(async ({ handle, value }) => {
    try {
      await new Promise((resolve, reject) => {
        if (handlesByName[handle] === undefined) {
          reject(new Error(`Unable to write variable: handle ${handle} not found`));
        }
        const { type } = handlesByName[handle];

        const generalTimeout = 500;

        // Create TcAdsWebService.DataWriter for write-read-write command.
        const writer = new TcAdsWebService.DataWriter();

        // Write general write-read-write commando information
        // to TcAdsWebService.DataWriter object;
        let { size } = typeMap[type];
        if (type === 'STRING') {
          size = value.length;
        }
        writer.writeDINT(TcAdsWebService.TcAdsReservedIndexGroups.SymbolValueByHandle);
        writer.writeDINT(handles.current[handle]);
        writer.writeDINT(size);

        // Write values to TcAdsWebService.DataWrite object;
        if (type === 'STRING') {
          writer.writeString(value);
        } else {
          writer[`write${type}`](value);
        }

        client.current.readwrite(
          NETID,
          PORT,
          0xF081, // 0xF081 = Call Write SumCommando
          1, // IndexOffset = Count of requested variables.
          size + 4, // Length of requested data + 4 byte errorcode per variable.
          writer.getBase64EncodedData(),
          (e) => {
            if (e && e.isBusy) {
              // Exit callback function because request is still busy;
              return;
            }

            if (e && !e.hasError) {
              // Exit callback function because request has no error;
              console.log('write success', { handle, value });
              resolve();
            } else if (e.error.getTypeString() === 'TcAdsWebService.ResquestError') {
            // HANDLE TcAdsWebService.ResquestError HERE;
              reject(new Error(`Error: StatusText = ${e.error.statusText} Status: ${e.error.status}`));
            } else if (e.error.getTypeString() === 'TcAdsWebService.Error') {
            // HANDLE TcAdsWebService.Error HERE;
              reject(new Error(`Error: ErrorMessage = ${e.error.errorMessage} ErrorCode: ${e.error.errorCode}`));
            }
          },
          null,
          generalTimeout,
          ((err) => { reject(err.message); }),
          true,
        );
      });
    } catch (err) {
      state.current.error = err.message;
      listeners.current.forEach((listener) => listener());
    }
  }, [url, handlesVarNames]);

  const get = useCallback(() => state.current, []);
  const subscribe = useCallback((listener) => {
    listeners.current.add(listener);
    return () => {
      listeners.current.delete(listener);
    };
  }, []);

  return { get, subscribe, write };
}

const useTestPLCData = ({ handles }) => {
  const listeners = useRef(new Set());
  const handleNames = useRef(new Set());
  useEffect(() => {
    window.plcData = {
      ready: true,
      values: { ...window.plcData?.values },
      error: null,
    };
    handles.forEach((handle) => {
      handleNames.current.add(handle.name);
      if (window.plcData.values[handle.name] === undefined) {
        window.plcData.values[handle.name] = {
          BYTE: 0,
          BOOL: false,
          WORD: 0,
          DWORD: 0,
          SINT: 0,
          INT: 0,
          DINT: 0,
          REAL: 0,
          LREAL: 0,
          STRING: '',
        }[handle.type];
      }
    });
    window.setInterval(() => {
      listeners.current.forEach((listener) => listener());
    }, 500);
  }, [handles]);

  const write = useCallback(({ handle, value }) => {
    if (!handleNames.current.has(handle)) {
      window.plcData.error = `Unable to write variable: handle ${handle} not found`;
    }
    window.plcData.values[handle] = value;
    listeners.current.forEach((listener) => listener());
  }, []);

  const get = useCallback(() => window.plcData || {}, []);
  const subscribe = useCallback((listener) => {
    listeners.current.add(listener);
    return () => {
      listeners.current.delete(listener);
    };
  }, []);

  return { get, subscribe, write };
};

function PLCContextProvider({
  url,
  handles,
  children,
  test = false,
}) {
  if (test) {
    return (
      <PLCContext.Provider value={useTestPLCData({ handles })}>
        {children}
      </PLCContext.Provider>
    );
  }
  return (
    <PLCContext.Provider value={usePLCData({ url, handles })}>
      {children}
    </PLCContext.Provider>
  );
}

PLCContextProvider.propTypes = {
  url: PropTypes.string.isRequired,
  handles: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    size: PropTypes.number,
  })).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  test: PropTypes.bool,
};

const usePLC = (selector) => {
  const store = useContext(PLCContext);

  return useSyncExternalStore(
    store.subscribe,
    () => selector(store.get()),
  );
};

const usePLCReady = () => usePLC((state) => state.ready);

const usePLCError = () => usePLC((state) => state.error);

const usePLCWrite = () => {
  const { write } = useContext(PLCContext);
  return write;
};

const usePLCValue = (handle) => {
  const selector = useCallback((state) => {
    if (state?.values) {
      return state.values[handle];
    }
    return null;
  }, [handle]);

  return usePLC(selector);
};

const usePLCValues = (handles) => {
  const cache = useRef({});

  const selector = useCallback((state) => {
    if (state?.values) {
      const newValues = handles.reduce(
        (acc, handle) => (
          { ...acc, [handle]: state.values[handle] }),
        {},
      );
      if (!shallowEqualObjects(newValues, cache.current)) {
        cache.current = newValues;
      }
      return cache.current;
    }
    return {};
  }, [handles]);

  return usePLC(selector);
};

export default PLCContextProvider;
export {
  usePLC,
  usePLCReady,
  usePLCError,
  usePLCWrite,
  usePLCValue,
  usePLCValues,
};
