import PropTypes from 'prop-types';
import React from 'react';

import { useGetOrgById } from '../../hooks/use-basicQueries';

export default function OrgName({ orgId }) {
  const { data: org = {}, isFetching, isError } = useGetOrgById(orgId);

  if (isFetching) return 'Loading...';
  if (isError || !orgId) return '(Org missing)';

  return (
    <span title={org.name}>{org.name}</span>
  );
}

OrgName.propTypes = {
  orgId: PropTypes.string.isRequired,
};
