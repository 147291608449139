import PropTypes from 'prop-types';
import React, {
  createContext, useContext, useMemo, useState,
} from 'react';

import OrderDetail from './detail';

const OrderDetailContext = createContext({});

export default function OrderDetailProvider({ children }) {
  const [orderId, setOrderId] = useState();

  const orderContextValue = useMemo(() => ({
    orderId,
    setOrderId,
  }), [
    orderId,
  ]);
  return (
    <OrderDetailContext.Provider value={orderContextValue}>
      <OrderDetail orderId={orderId} setOrderId={setOrderId} />
      {children}
    </OrderDetailContext.Provider>
  );
}
OrderDetailProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useOrderDetail = () => {
  const context = useContext(OrderDetailContext);
  return context;
};
