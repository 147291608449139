import PropTypes from 'prop-types';
import React from 'react';

import SummaryTable from '../../components/ProcessData';

export default function SummaryPage({ processType }) {
  return <SummaryTable processType={processType} pageSize={25} />;
}

SummaryPage.propTypes = {
  processType: PropTypes.string.isRequired,
};
