import {
  Header,
  SpaceBetween,
} from '@cloudscape-design/components';
import React, { useMemo } from 'react';

import { usePLCValue } from '../../../features/plc/context';
import parseTime from '../components/parseTime';
import PLCButton from '../components/plcButton';
import {
  usePressHandle, usePressTitle,
  useProgramHandle,
} from '../hooks/context';

function AutomationStage() {
  const pressTitle = usePressTitle();
  const programHandle = useProgramHandle();

  const pressHandle = usePressHandle();
  const pressModeHandle = useMemo(() => `MAIN.${pressHandle}_Mode`, [pressHandle]);

  const automationEnabled = usePLCValue(`${programHandle}.bProcessAutomationEnabled`);
  const automationState = usePLCValue(`${programHandle}.iProcessState`);
  const presoakTimer = usePLCValue(`${programHandle}.tPresoakDelayTimer.ET`);
  const coolsoakTimer = usePLCValue(`${programHandle}.tCoolsoakDelayTimer.ET`);

  const stageDescription = useMemo(() => ({
    0: 'Waiting for Instron to start test',
    10: 'Wait for initial signal from Instron',
    20: 'Acknowledge message by toggling strain',
    30: 'Wait for sound to indicate that heating can start',
    40: 'Heating to setpoint',
    41: `Presoaking... ${parseTime(presoakTimer)}`,
    50: 'Wait for the Instron to have applied force',
    60: 'Forming...',
    70: 'Cooling to setpoint',
    71: `Coolsoaking... ${parseTime(coolsoakTimer)}`,
    100: 'Done!',
  }[automationState || 0] || 'Unknown'), [automationState, presoakTimer, coolsoakTimer]);

  return (
    <Header
      variant="h1"
      actions={(
        <SpaceBetween direction="horizontal" size="xs">
          <PLCButton variant="primary" onClick={(write) => { write({ handle: pressModeHandle, value: 0 }); }}>
            Disable forming mode
          </PLCButton>
        </SpaceBetween>
        )}
    >
      {`${pressTitle} - ${automationEnabled ? stageDescription : 'Automation Disabled'}`}
    </Header>
  );
}

export default AutomationStage;
