import {
  Header,
  SpaceBetween,
} from '@cloudscape-design/components';
import React, { useMemo } from 'react';

import { PressOrientationItems } from '../../../constants/pressOrientation';
import { usePLCValue } from '../../../features/plc/context';
import parseTime from '../components/parseTime';
import PLCButton from '../components/plcButton';
import PLCSelect from '../components/plcSelect';
import { usePressHandle, usePressTitle, useProgramHandle } from '../hooks/context';

function AutomationStage() {
  const pressTitle = usePressTitle();
  const programHandle = useProgramHandle();
  const pressHandle = usePressHandle();
  const pressModeHandle = useMemo(() => `MAIN.${pressHandle}_Mode`, [pressHandle]);

  const automationEnabled = usePLCValue(`${programHandle}.bProcessAutomationEnabled`);
  const automationState = usePLCValue(`${programHandle}.iProcessState`);
  const presoakTimer = usePLCValue(`${programHandle}.fbPresoakTimer.ET`);
  const bondTimer = usePLCValue(`${programHandle}.fbBondTimer.ET`);
  const superheatTimer = usePLCValue(`${programHandle}.fbSuperheatWaitTimer.ET`);
  const annealTimer = usePLCValue(`${programHandle}.fbAnnealingWaitTimer.ET`);

  const stageDescription = useMemo(() => ({
    0: 'Waiting for Instron to start test',
    10: 'Wait for the Instron to be at ready height',
    11: 'Acknowledge message by toggling strain',
    12: 'Wait for the Instron to be at bond height',
    20: 'Start ramping heaters',
    21: 'Wait for heaters to be at temp',
    22: 'Wait until Instron is at presoak height',
    23: `Wait for presoak time to finish (${parseTime(presoakTimer)})`,
    24: 'Wait for Instron to be ready at bond force',
    30: `Wait for bond time to finish (${parseTime(bondTimer)})`,
    31: 'Wait for press to reach annealing force',
    40: 'Change to superheat temp',
    41: 'Ramping to superheat',
    42: `Superheating (${parseTime(superheatTimer)})...`,
    44: 'Ramping to anneal temp',
    45: `Annealing (${parseTime(annealTimer)})...`,
    50: 'Cooling...',
    51: 'Wait for heaters to be at temp',
  }[automationState || 0] || 'Unknown'), [automationState, presoakTimer, bondTimer, superheatTimer, annealTimer]);

  const pressOrientationOptions = useMemo(() => PressOrientationItems.map((item) => ({
    value: `${item.id}`,
    label: item.text,
  })), []);

  return (
    <Header
      variant="h1"
      actions={(
        <SpaceBetween direction="horizontal" size="xs">
          <PLCSelect
            handle={`MAIN.${pressHandle}_Bonding_Orientation`}
            options={pressOrientationOptions}
          />
          <PLCButton variant="primary" onClick={(write) => { write({ handle: pressModeHandle, value: 0 }); }}>
            Disable bonding mode
          </PLCButton>
        </SpaceBetween>
      )}
    >
      {`${pressTitle} - ${automationEnabled ? stageDescription : 'Automation Disabled'}`}
    </Header>
  );
}

export default AutomationStage;
