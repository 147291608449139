import { calculateUnitPrice } from '@parallel-fluidics/pricing';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import * as Yup from 'yup';

import { useNotifications } from '../../features/notifications';

function QuoteValidation({ clonedQuote, validationSchema }) {
  const { values, dirty } = useFormikContext();
  const { addNotification, removeNotification } = useNotifications();

  const validatePriceIncreasing = useCallback(({
    availableCappingStyles,
    delivery,
    minQuantity,
    maxQuantity,
    updatedQuote,
  }) => {
    // Iterate over each capping style
    for (let i = 0; i < availableCappingStyles.length; i += 1) {
      const cappingStyle = availableCappingStyles[i];
      // Reset the lastTotalPrice for each new cappingStyle and delivery
      let lastTotalPrice = null;
      // Iterate over the quantities from min to max
      for (let quantity = minQuantity; quantity <= maxQuantity; quantity += 1) {
        const {
          discount = 0,
          setupPrice,
          unitPrice,
        } = calculateUnitPrice({
          quote: updatedQuote,
          options: { cappingStyle, quantity, delivery },
        });

        // Calculate total price
        const totalPrice = setupPrice - discount + unitPrice * quantity;

        // Check if the current total price is lower than the last one
        if (lastTotalPrice !== null && totalPrice < lastTotalPrice) {
          // Price decreased, return false
          return {
            isValid: false,
            cappingStyle,
            quantity,
          };
        }

        // Update the lastTotalPrice for the next iteration
        lastTotalPrice = totalPrice;
      }
    }
    return { isValid: true };
  }, []);

  useEffect(() => {
    if (!dirty
      || !values?.calculatedValues?.cappedUnitPrice
       || !values?.calculatedValues?.uncappedUnitPrice
    ) return;

    const castValues = validationSchema.cast(values);

    // Create a shallow copy of clonedQuote
    const updatedQuote = {
      ...clonedQuote,
      pricingV3: {
        ...clonedQuote.pricingV3,
        values: { ...clonedQuote.pricingV3.values, ...castValues },
      },
    };

    // We only need to check if the part is uncapped or (if available) capped.
    const availableCappingStyles = ['uncapped'];
    if (castValues.materials.some((material) => material.cappingStyle.length > 0)) {
      availableCappingStyles.push('capped');
    }

    // Since different delivery options only apply a multiplier on the total price,
    // it won't impact the price increasing, so we can just use the first one
    const delivery = castValues.leadtimes[0].id;
    // get min and max quantity
    const minQuantity = Math.min(...castValues.breakpoints.map((b) => b.quantity));
    const maxQuantity = Math.max(...castValues.breakpoints.map((b) => b.quantity));
    const {
      isValid, cappingStyle, quantity,
    } = validatePriceIncreasing({
      availableCappingStyles,
      delivery,
      minQuantity,
      maxQuantity,
      updatedQuote,
    });

    if (!isValid) {
      addNotification({
        type: 'error',
        dismissible: false,
        content: `Quote validation failed: Price decreased for ${cappingStyle} between quantities ${quantity - 1} and ${quantity}`,
        id: 'quoteValidationError',
      });
    } else {
      removeNotification('quoteValidationError');
    }
  }, [
    clonedQuote,
    dirty,
    validatePriceIncreasing,
    validationSchema,
    values,
    addNotification,
    removeNotification,
  ]);

  return null;
}

QuoteValidation.propTypes = {
  clonedQuote: PropTypes.shape({
    id: PropTypes.string,
    pricingVersion: PropTypes.string,
    pricingV3: PropTypes.shape({
      values: PropTypes.shape({}),
    }),
  }).isRequired,
  validationSchema: PropTypes.instanceOf(Yup.ObjectSchema).isRequired,
};

export default QuoteValidation;
