import {
  Alert,
  Box,
  Button,
  ButtonDropdown,
  ColumnLayout,
  Container,
  ContentLayout,
  Grid,
  Header,
  SpaceBetween,
  StatusIndicator,
  Table,
} from '@cloudscape-design/components';
import { useQuery } from '@tanstack/react-query';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import {
  getBlob,
  getDownloadURL,
  getMetadata,
  listAll,
  ref,
} from 'firebase/storage';
import React, { useCallback, useEffect, useState } from 'react';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useStorage } from 'reactfire';

import CADViewer from '../../components/CADPreview/viewer';
import FeedbackSummaryTable from '../../components/Feedback';
import OrganizationSelect from '../../components/Organization/organizationSelect';
import PricingSummaryTable from '../../components/Pricing';
import { leadtimeDisplayMap } from '../../components/Pricing/editLeadtimes';
import QuoteStatus from '../../components/QuoteStatus';
import QuoteStatusTag from '../../components/QuoteStatusTag';
import TransferPart from '../../components/TransferPart';
import { usePartAndQuote } from '../../features/firebase';
import { useNotifications } from '../../features/notifications';

function Part() {
  const { partId } = useParams();
  const { part, partUser, quote } = usePartAndQuote(partId);
  const { addNotification, removeNotification } = useNotifications();
  const storage = useStorage();
  const {
    material,
    filename,
    cappingStyle,
    cappingOptions,
    quantity,
    optionalNotes,
  } = part;
  const {
    feedback,
    optionalFeedback,
    feedbackChecklistReviewed,
    status,
  } = quote;

  const showFeedback = feedbackChecklistReviewed || status !== 'pending';

  const { data: cadPreviewSrc, status: cadPreviewStatus } = useQuery({
    queryKey: ['cadPreview', part.uid, part.fileID],
    queryFn: async () => {
      const storageRef = ref(storage, `${part.uid}/cad/${part.fileID}.glb`);
      const url = await getDownloadURL(storageRef);
      return url;
    },
    enabled: !!part.uid && !!part.fileID,
  });

  const { data: latestEditUrl } = useQuery({
    queryKey: ['getLatestEditFile', part.uid, part.fileID],
    queryFn: async () => {
      const folderRef = ref(storage, `/user-uploads/cad/${part.uid}/${part.fileID}`);
      const { items: editFiles } = await listAll(folderRef);

      if (editFiles.length === 0) {
        return null;
      }
      // Get metadata of all files
      const filePromises = editFiles.map((item) => getMetadata(item));
      const files = await Promise.all(filePromises);

      // Sort by creation time and get the latest file
      const latestFile = files.sort((a, b) => b.timeCreated.localeCompare(a.timeCreated))[0];
      const downloadURL = await getDownloadURL(ref(storage, latestFile.fullPath));
      return downloadURL;
    },
    enabled: !!part.uid && !!part.fileID,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (quote.pricingVersion !== 'v3' || (!quote.feedback?.length && !quote?.optionalFeedback?.length)) return;
    // check if feedback or optionalFeedback contains category = "Core Format"
    const coreFormatFeedback = (quote.feedback || []).concat(quote.optionalFeedback || []).find((f) => f.category === 'Core Format');
    if (!coreFormatFeedback) return;
    // check leadtimes matches expectedLeadtimes
    const expectedLeadtimes = [
      { id: 'leadtime-standard', multiplier: 1, leadtimeDays: 5 },
      { id: 'leadtime-rush', multiplier: 2, leadtimeDays: 3 },
    ];
    const leadtimes = quote.pricingV3?.values?.leadtimes;
    const warningId = 'leadtimes-mismatch';
    const showWarningNotification = () => {
      addNotification({
        id: warningId,
        type: 'warning',
        dismissible: false,
        header: 'Leadtimes do not match Core Format expected values, please fix the leadtime table to match the following:',
        content: (
          <Box margin={{ top: 'xxs' }}>
            <Table
              ariaLabels={{
                tableLabel: 'tableOnWarning',
              }}
              variant="embedded"
              items={expectedLeadtimes}
              columnDefinitions={[
                { id: 'id', header: 'Delivery Type', cell: (item) => leadtimeDisplayMap[item.id] },
                { id: 'multiplier', header: 'Multiplier', cell: (item) => item.multiplier },
                { id: 'leadtimeDays', header: 'Leadtime Days', cell: (item) => item.leadtimeDays },
              ]}
            />
          </Box>
        ),
      });
    };
    // check if leadtimes size is correct
    if (!leadtimes || leadtimes.length !== 2) {
      showWarningNotification();
      return;
    }
    // check if leadtimes data are correct
    const leadtimesMatch = expectedLeadtimes.every((expectedLeadtime) => {
      const leadtime = leadtimes.find((l) => l.id === expectedLeadtime.id);
      return leadtime
        && leadtime.multiplier === expectedLeadtime.multiplier
        && leadtime.leadtimeDays === expectedLeadtime.leadtimeDays;
    });
    if (!leadtimesMatch) {
      showWarningNotification();
    } else {
      removeNotification(warningId);
    }
  }, [quote, addNotification, removeNotification]);

  const [cadDownloadStatus, setCadDownloadStatus] = useState('idle');

  const handleDownloadAndRenameFile = useCallback(async () => {
    try {
      setCadDownloadStatus('loading');
      const fileExtension = part.filename.split('.').pop();
      const storageRef = ref(storage, `/user-uploads/cad/${part.uid}/${part.fileID}.${fileExtension}`);
      const blob = await getBlob(storageRef);

      // Create a link element and set the download attribute to rename the file
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${part.id.substring(0, 6).toUpperCase()}.${fileExtension}`; // Set the desired file name

      // Programmatically trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      setCadDownloadStatus('success');
    } catch (error) {
      setCadDownloadStatus('error');
      addNotification({
        type: 'error',
        dismissible: true,
        content: `Error downloading the file, please contact dev team. ${error.message}`,
      });
      // eslint-disable-next-line no-console
      console.error('Error downloading the file:', error);
    }
  }, [addNotification, part.fileID, part.filename, part.id, part.uid, storage]);

  const handleUserOrgChange = useCallback((orgId) => {
    // update user's organization
    updateDoc(doc(getFirestore(), 'users', partUser.id), { organization: orgId });
  }, [partUser.id]);

  if (!part) {
    return (
      <ContentLayout header={<Header variant="h1">Loading...</Header>} />
    );
  }

  return (
    <ContentLayout
      header={(
        <SpaceBetween size="m">
          <Header
            variant="h1"
            description={<QuoteStatusTag partId={partId} />}
            actions={(
              <SpaceBetween direction="horizontal" size="xs">
                <QuoteStatus partId={partId} />
                <Button
                  iconName="external"
                  variant="normal"
                  href={`${process.env.REACT_APP_WWW_URL}/parts/view?quote=${partId}`}
                  target="_blank"
                >
                  View live quote
                </Button>
                <TransferPart partId={partId} />
              </SpaceBetween>
            )}
          >
            {`Quote #${partId.substring(0, 6).toUpperCase()}`}
          </Header>
          {part.email ? (
            <Alert statusIconAriaLabel="Info">
              {`The user submitted this RFQ without an account and indicated that their email is ${part.email}. When Parascope sends an email to this user, it will contain an account creation link where they will be prompted to set a password. If they need a new link, you can generate one on their account page (click their email in the breadcrumb menu above).`}
            </Alert>
          ) : null}
        </SpaceBetween>
        )}
    >
      <Grid gridDefinition={[
        { colspan: { default: 12, s: 8 } }, { colspan: { default: 12, s: 4 } },
        { colspan: 12 },
        { colspan: 12 },
        { colspan: 12 },
      ]}
      >
        {/* Original RFQ Info */}
        <Container header={<Header variant="h2">Original RFQ Info</Header>} fitHeight>
          <SpaceBetween size="s">
            <ColumnLayout columns={2} variant="text-grid">
              <div>
                <Box variant="awsui-key-label">Filename</Box>
                <div>{filename}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Material</Box>
                <div>{material}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Capping style</Box>
                <div>
                  {`${cappingStyle} / ${cappingOptions}`}
                </div>
              </div>
              <div>
                <Box variant="awsui-key-label">Quantity</Box>
                <div>{quantity}</div>
              </div>
            </ColumnLayout>
            {optionalNotes && optionalNotes.length > 0 ? (
              <Box>
                <span style={{ fontWeight: 800 }}>Notes: </span>
                {optionalNotes}
              </Box>
            ) : null}
          </SpaceBetween>
        </Container>

        {/* CAD Preview */}
        <Container
          header={(
            <Header
              variant="h2"
              actions={(
                <SpaceBetween direction="horizontal" size="xs">
                  <Button onClick={() => { navigate(`/part/${partId}/cad`); }}>
                    Edit
                  </Button>
                  <ButtonDropdown
                    variant="primary"
                    items={[
                      { id: 'download', text: 'Download Original File', disabled: cadDownloadStatus === 'error' },
                      {
                        id: 'edit', href: latestEditUrl, text: 'Download Latest Revision', disabled: !latestEditUrl,
                      },
                    ]}
                    onItemClick={({ detail }) => {
                      switch (detail.id) {
                        case 'download':
                          handleDownloadAndRenameFile();
                          break;
                        default:
                      }
                    }}
                    expandToViewport
                    loading={cadDownloadStatus === 'loading'}
                  >
                    Download
                  </ButtonDropdown>
                </SpaceBetween>
            )}
            >
              CAD Preview
            </Header>
          )}
          fitHeight
          disableContentPaddings
        >
          {cadPreviewStatus === 'pending' ? (
            <Box textAlign="center" margin="m">
              <StatusIndicator type="loading">Loading...</StatusIndicator>
            </Box>
          ) : null}
          {cadPreviewStatus === 'error' ? (
            <Box textAlign="center" margin="m">
              <StatusIndicator type="error">Error loading CAD preview</StatusIndicator>
            </Box>
          ) : null}
          {cadPreviewStatus === 'success' && cadPreviewSrc ? (
            <CADViewer src={cadPreviewSrc} style={{ height: 260, width: '100%' }} />
          ) : null}
        </Container>

        {/* Organization */}
        <Container
          header={<Header variant="h2">Organization</Header>}
        >
          {partUser.id && (
            <OrganizationSelect
              defaultDomain={partUser.email.split('@')[1]}
              label={`Select an Organization for ${partUser.email}`}
              selectedOrgId={partUser.organization}
              onOrgChange={handleUserOrgChange}
            />
          )}
        </Container>

        {/* Feedback */}
        <FeedbackSummaryTable
          header={(
            <Header
              variant="h2"
              actions={showFeedback ? (
                <Button variant="normal" onClick={() => { navigate(`/part/${partId}/feedback`); }}>Edit</Button>
              ) : null}
            >
              Feedback
            </Header>
          )}
          feedback={feedback}
          optionalFeedback={optionalFeedback}
          forceEmpty={!showFeedback}
          empty={(
            <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
              <SpaceBetween size="m">
                <b>Design needs review</b>
                <Button onClick={() => { navigate('./feedback'); }}>Review</Button>
              </SpaceBetween>
            </Box>
          )}
        />

        {/* Pricing */}
        <PricingSummaryTable partId={partId} />
      </Grid>
    </ContentLayout>
  );
}

export default Part;
